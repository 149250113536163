import { Table, Space, Tag, Form, Input, Tooltip, Button, Row, Col, Select, Divider, AutoComplete, Checkbox, Radio, message, Spin, Typography } from 'antd';
import { SearchOutlined, ReadOutlined, MailOutlined, FilterOutlined, CloseOutlined, ReloadOutlined, FileExcelOutlined, ShareAltOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
import React, { useEffect, useState } from "react";
import { fetchAdminApi,fetchApi } from "../../../services/api";
import { useHistory,useLocation } from "react-router";
import moment from "moment";
import { COOKIE, getCookie } from '../../../services/cookie';
import { DOB } from '../../../globalFunctions/GlobalFunctions';
import VideoComponent from '../VideoComponent/VideoComponant';
const { Option } = Select;
const ClientList = (props) => {
	let location = useLocation();
	const [form] = Form.useForm();
	const [tableData, setTableData] = useState([]);
	const [filterTable, setFilterTable] = useState([]);
	const [clientListType, setClientListType] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const history = useHistory();
	let loginType = getCookie(COOKIE.LoginType);
	let clientId = getCookie(COOKIE.ClientId);
	const [emailId, setEmailId] = useState("");
	const [listCountries, setCountries] = useState([]);
	const [tempState, setTempState] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [stateKey, setStateKey] = useState("");
	const [listCities, setlistCities] = useState([]);
	const [isAdvanceFilterOpen, setIsAdvancetFilterOpen] = useState(false);
	const [cityOptions, setCityOptions] = useState([]);
	const [dateOf, setDateOf] = useState(1);
	const [date, setDate] = useState({
		startDate:{
			date: null,
			month: null,
			year: null
		},
		endDate:{
			date: null,
			month: null,
			year: null
		}
	});
	const [filteredSearchCriteria, setfilteredSearchCriteria] = useState("");
	const [userStatus, setUserStatus] = useState([]);
	const [sortPayload, setSortPayload] = useState({});
	const [advanceFilterPayload, setAdvanceFilterPayload] = useState({});
	const [loader, setloader] = useState(false);
	const [listAssociates, setlistAssociates] = useState([]);
	const [pagination, setPagination] = useState(constants.paginationConfig);
	useEffect(() => {
		getEmailId();
		getCitiesFromDb();
		fetchCountries();
		const filterValue = JSON.parse(sessionStorage.getItem('filterValue'));
		if (filterValue && filterValue.is_associate) {
			getAssociateList(filterValue);
		} else {
			fetchClientList();
			getAssociateList(filterValue);
		}
		return () => {
			sessionStorage.clear();
		}
	}, [location]);
	const getEmailId = () => {
		fetchApi(`/client-profile/${clientId}`, "get").then((res) => {
			if (res && res.data && res.code === 200) {
				setEmailId(res?.data?.email);
			} else {
				setEmailId("");
			};
		});
	};
	const getAssociateList = (filterValue) => {
		fetchAdminApi(`/view-associates`, "post").then((res) => {
			if (res && res.data && res.code === 200) {
				let associate_list = res?.data;
				associate_list.sort((a,b)=> a.firstname.localeCompare(b.firstname));
				setlistAssociates(associate_list);
				if (filterValue && filterValue?.is_associate) {
					// setIsAdvancetFilterOpen(!isAdvanceFilterOpen);
					let payload = { referred_to: filterValue?.client_id, status: filterValue?.is_active,
				}
					fetchClientList(null, payload);
					let sc = `Status: ${(filterValue?.status, associate_list, "Active")}, ${constants.AssignedTo}: ${getNamebyId(filterValue?.client_id, associate_list, "assosiate")}`;
					if (sc.length >= 1) {
						setfilteredSearchCriteria(sc + ".");
					}
					form.setFieldsValue({ asignto: filterValue?.client_id, status: filterValue?.is_active, });
				}
			}else {
				setlistAssociates([]);
			};
		});

	};
	//fetching List of clients
	const fetchClientList = (tablePayload = null,filterPayload = null) => {
		setloader(true);
		let loginID = getCookie(COOKIE.ClientId);
		setSearchValue("");
		if (loginType === "1" || loginType === "2") {
			let client_list_type; 
			let payload = {};
			if (location.pathname === "/client-list-all") {
				client_list_type = "";
				setClientListType("All");
				if (tablePayload === null || filterPayload === null) {
					filterReset(["Active", "Refresher", "Closed"]);
					setDate((prevEnquiryDate) => ({
						...prevEnquiryDate,
						startDate: {
							...prevEnquiryDate.startDate,
							date: null,
							month: null,
							year: null
						},
						endDate: {
							...prevEnquiryDate.endDate,
							date: null,
							month: null,
							year: null
						}
					}));
				}
			}
			else if (location.pathname === "/client-list-open") {
				client_list_type="1, 3";
				setClientListType("Open");
				if(tablePayload === null || filterPayload === null){
					filterReset(["Active", "Refresher"]);
					setDate((prevEnquiryDate) => ({
						...prevEnquiryDate,
						startDate: {
							...prevEnquiryDate.startDate,
							date: null,
							month: null,
							year: null
						},
						endDate: {
							...prevEnquiryDate.endDate,
							date: null,
							month: null,
							year: null
						}
					}));
				}
			}
			else if (location.pathname === "/client-list-closed"){
				client_list_type="2";
				setClientListType("Closed");
				if(tablePayload === null || filterPayload === null){
					filterReset(["Closed"]);
					setDate((prevEnquiryDate) => ({
						...prevEnquiryDate,
						startDate: {
							...prevEnquiryDate.startDate,
							date: null,
							month: null,
							year: null
						},
						endDate: {
							...prevEnquiryDate.endDate,
							date: null,
							month: null,
							year: null
						}
					}));
				}
			}
			else if (location.pathname === "/demo-client-list"){
				payload.demo = "1";
				client_list_type = "0";
				setClientListType(constants.DemoClient);
			}
			payload.type = client_list_type;
			if(loginType === "2"){
				payload.role_id = 2;
				payload.login_id = loginID;
			};
			if (tablePayload !== null){
				let mergepayload = {...payload,...tablePayload}
				payload = mergepayload;
			};
			if (filterPayload !== null){
				let mergepayload = {...payload,...tablePayload,...filterPayload}
				payload = mergepayload;
			};
			if (location.pathname === "/client-list-open" && (!filterPayload?.status)) {
				payload.type = "1,3";
			} else if (location.pathname === "/client-list-closed" && (!filterPayload?.status)) {
				payload.type = "2";
			} else if (location.pathname === "/client-list-all" && (!filterPayload?.status)) {
				payload.type = "";
			}
			fetchAdminApi(`/dashboard`, "post",payload).then((res) => {
				if (res && res.data) {
					setloader(false);
					setTableData(res.data);
					setFilterTable(res.data);
				}
				else {
					setTableData([]);
					setloader(false);
				}
			});
		} else if (loginType === "3") {
			history.push("/image-list");
		} else {
			history.push("/dashboard");
		}
	};
	const routeToEditClient = (client_id,enable = null) => {
		let payload = {};
		if(enable){
			payload.client_id = client_id;
			payload.enable = true;
		}else{
			payload.client_id = client_id;
		}
		history.push({
			pathname: "/edit-client",
			state: payload
		});
	};
	const getNameInitail = (fullName) => {
		let namesArray = fullName?.split(' ');
		let initialFN = namesArray?.[0]?.charAt(0);
		let initialLN = namesArray?.[1]?.charAt(0);
		let name={
			firstname:initialFN,
			lastname:initialLN
		}
		return name;
	};
	const columns = [
		{
			title: '#',
			align:"center",
			dataIndex: 'serial_no',
			key: 'serial_no',
			render:(serial_no)=>{
				return <span className="text-right w-6 block m-auto">{serial_no}</span>;
			}
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'firstname',
			sorter:location.pathname === "/demo-client-list" ? false:true,
			render: (firstname, record) => {
				let loginType = getCookie(COOKIE.LoginType);
				let associateFullName = record?.associate_name;
				let sharedWithFullName = record?.shared_name;
				let getAssosiateNameInitial=getNameInitail(associateFullName);
				let getSharedWithNameInitial=getNameInitail(sharedWithFullName);
				return firstname ? <Space>
					<a onClick={() => routeToEditClient(record?.client_id)}>
						<Space>
							{loginType === "2" ? sharedWithFullName ? <ShareAltOutlined /> : <SearchOutlined/>:<SearchOutlined/>  }{firstname}
							</Space>
					</a>
					<>
						{record?.status === "Active" && record?.section_id >= 1 ?
							<a onClick={() => history.push({
								pathname: "/dashboard",
								state: {
									isAdmin: true,
									clientId: record?.client_id,
									client_name: firstname,
									client_serial_number: record?.serial_no 
								}
							})}><ReadOutlined/>
							</a>
							: ""
						}
						{loginType === "1" && associateFullName  && record?.associate_role !== "1" && (<Tooltip title={ constants.AssignedTo + " " + associateFullName }><span>{"->" + " " +getAssosiateNameInitial.firstname+getAssosiateNameInitial.lastname}</span></Tooltip>)}
						{sharedWithFullName && (<Tooltip title={ constants.SharedWith + " " + sharedWithFullName }><span>{">>" + " " +getSharedWithNameInitial.firstname+getSharedWithNameInitial.lastname}</span></Tooltip>)}
					</>
				</Space> : "";
			}
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			align:"center",
			width:"5%",
			render: (email) => {
				return <Tooltip title={email}><MailOutlined onClick={ ()=>copyToClipboard(email)}/></Tooltip>;
			}
		},
		{
			title: 'Contact No.',
			dataIndex: 'contact_no',
			key: 'contact_no',
			render: (contact_no) => {
				if(contact_no === "00000"){
					return <span>-</span>
				}else{
					return <span>{contact_no}</span>
				}
			}
		},
		{
			title: 'Country',
			dataIndex: 'country',
			key: 'country',
			sorter:location.pathname === "/demo-client-list" ? false:true,
		},
		{
			title: 'State',
			dataIndex: 'state',
			key: 'state',
			sorter:location.pathname === "/demo-client-list" ? false:true,
		},
		{
			title: 'City',
			dataIndex: 'city',
			key: 'city',
			sorter:location.pathname === "/demo-client-list" ? false:true,
		},
		{
			title: 'Section',
			key: 'sections',
			sorter:location.pathname === "/demo-client-list" ? false:true,
			render: (record) => {
				if(record?.is_enabled === "N" && record?.status !== "Closed" ){
					return <a onClick={() => routeToEditClient(record?.client_id)}>Click to Enable</a>;
				}else if(record?.status == "Closed"){
					return <span></span>
				}else{
					if (record?.section_status !== null && record?.section_id !== null) {
						let section_status = "";
						if (record?.section_status === "1" && record?.enable_status === 1) {
							section_status = "(Enabled)";
						}
						else if (record?.section_status === "1" && record?.enable_status === 0) {
							section_status = "(In Progress)";
						}
						else if (record?.section_status === "2") {
							section_status = "(Submitted)";
						}
						else if (record?.section_status === "3") {
							section_status = "(Completed)";
						}
						else if (record?.section_status === "4") {
							section_status = "(Discussion In Progress)";
						}
						else if (record?.section_status === "5") {
							section_status = "(Submitted Edited)";
						}
						else if (record?.section_status === "6") {
							section_status = "(Completed Edited)";
						}
						return location.pathname === "/client-list-closed" && record.completed_section === "7" || location.pathname === "/client-list-all" && record.completed_section === "7" ? <span>{constants.PlanCompleted}</span> : <span>{record?.status === "Refresher" ? constants.RefresherWorkSheet : record?.section_id} {section_status}</span>;
					}
				}
			},
		},
		{
			title: 'For',
			dataIndex: 'is_minor',
			key: 'is_minor',
			width:"5%",
			render:(is_minor) => {
				return <span>{is_minor === "0"? <Tooltip title="Adult"><span>A</span></Tooltip>: <Tooltip title="Minor"><span>M</span></Tooltip>}</span>;
			},
		},
		//Can be used in the future.

		// {
		// 	title: 'Status',
		// 	dataIndex: 'status',
		// 	key: 'status',
		// 	sorter:location.pathname === "/demo-client-list" ? false:true,
		// 	render: (record) => {
		// 		switch (record) {
		// 		case "Active":
		// 			return <Tag color={'blue'}>Active</Tag>;
		// 		case "Closed":
		// 			return <Tag color={'default'}>Closed</Tag>;
		// 		case "Refresher":
		// 			return <Tag color={'green'}>Refresher</Tag>;
		// 		}
		// 	},
		// },
		{
			title: 'Date of Admission',
			dataIndex: 'dt_created',
			key: 'adddate',
			width: "8%",
			render: (record) => {
				return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
			},
		},
		{
			title: 'Last updated',
			dataIndex: 'date_updated',
			key: 'date',
			width: "8%",
			render: (record) => {
				return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
			},
		},
	];
	const copyToClipboard = (email_id) => {
		const el = document.createElement('textarea');
		el.value = email_id;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};
	const onSearch = (event) => {
		let search = event.target.value;
		setSearchValue(search);
		if (search !== null || search !== "") {
			let tempData = filterTable.length > 0 ? filterTable.filter((searchField) =>
				searchField && searchField.name && searchField.name.toLowerCase().includes(search.toLowerCase()) ||
				searchField && searchField.email && searchField.email.toLowerCase().includes(search.toLowerCase()) ||
				searchField && searchField.contact_no && searchField.contact_no.toLowerCase().includes(search.toLowerCase())
			) : [];
			setTableData(tempData);
		}
	};
	const getRecordMessage = () => {
		let record_message;
		if(tableData.length > 1){
			record_message = tableData.length + " " + "records found.";
		}else if (tableData.length === 0){
			record_message = "No records found.";
		}else{
			record_message = tableData.length + " " + "record found.";
		}
		return record_message;
	};
	const onclickHere = (type) => {
		if(type === "1"){
			window.open(constants.BaseUrl + "/wb-contents");
		}else{
			window.open(constants.BaseUrl + "/client-answers");
		}
	};
	const onChangeTable = (pagination, filters, sorter)=>{
		setPagination(pagination);
		let payload = {};
		let sortorder = null;
		let column_key = null;
		let sort_column = {};
		if(sorter){
			//set variables
			sortorder = sorter.order;
			column_key = sorter.columnKey;
			//name
			if (sortorder === "descend" && column_key === "firstname"){
				payload.sort_column = "concat(firstname, ' ', lastname)";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "firstname"){
				payload.sort_column = "concat(firstname, ' ', lastname)";
				payload.sort_type = "ASC";
			};
			//country
			if (sortorder === "descend" && column_key === "country"){
				payload.sort_column = "c.country_name";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "country"){
				payload.sort_column = "c.country_name";
				payload.sort_type = "ASC";
			};
			//state
			if (sortorder === "descend" && column_key === "state"){
				payload.sort_column = "gr.region_name";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "state"){
				payload.sort_column = "gr.region_name";
				payload.sort_type = "ASC";
			};
			//city
			if (sortorder === "descend" && column_key === "city"){
				payload.sort_column = "clients.city";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "city"){
				payload.sort_column = "clients.city";
				payload.sort_type = "ASC";
			};
			//section
			if (sortorder === "descend" && column_key === "sections"){
				payload.sort_column = "cs.section_id";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "sections"){
				payload.sort_column = "cs.section_id";
				payload.sort_type = "ASC";
			};
			//status
			if (sortorder === "descend" && column_key === "status"){
				payload.sort_column = "is_active";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "status"){
				payload.sort_column = "is_active";
				payload.sort_type = "ASC";
			};
		};
		setSortPayload(payload);
		fetchClientList(payload,advanceFilterPayload);
	};
	//filter code.
	const advancedFilter = () => {
		setIsAdvancetFilterOpen(!isAdvanceFilterOpen);
	};
	const onFinishFilterSearch = (values,associate_list = null) => {
		setIsAdvancetFilterOpen(!isAdvanceFilterOpen);
		let payload={};
		let searchCriteriaMessage = [];
		if(values.country_id){
			payload.country_id = values.country_id;
			searchCriteriaMessage.push(`Country: ${getNamebyId(values.country_id,listCountries,"country")}`);
		};
		if(values.region_id){
			payload.region_id = values.region_id;
			searchCriteriaMessage.push(`State: ${getNamebyId(values.region_id,listStates,"state")}`);
		};
		if(values.city){
			payload.city = values.city;
			searchCriteriaMessage.push(`City: ${values.city}`);
		};
		if(values.Section?.length>0){
			if(values.Section !== "none-of-the-above"){
				payload.section = values.Section?.toString();
				searchCriteriaMessage.push(`Section: ${values.Section}`);
			}
		};
		if(values.is_minor){
			let resultString = values.is_minor.join(', ');
			if(values.is_minor.length > 0 && values.is_minor.length <= 1){
				payload.is_minor = values.is_minor[0] === "Adult" ?"0":"1";
				searchCriteriaMessage.push(`For: ${resultString}`);
			}else if (values.is_minor.length > 1){
				searchCriteriaMessage.push(`For: ${resultString}`);
			}
		};
		if(clientListType){
			if(values?.status?.length > 0){
				let resultString = values?.status.join(', ');
				payload.status = values?.status;
				searchCriteriaMessage.push(`Status: ${resultString}`);
			};
			let types = [];
			if (values?.status?.includes("Active")) {
				types.push(1);
			}
			if (values?.status?.includes("Refresher")) {
				types.push(3);
			}
			if (values?.status?.includes("Closed")) {
				if (values?.status?.length > 1) {
					payload.type = "";
				} else {
					payload.type = "2";
				}
			} else {
				payload.type = types.join(', ');
			}
		};
		if(values.date_type){
			payload.date_type = values.date_type;
		};
		if(date.startDate.date && date.startDate.month && date.startDate.year){
			payload.start_date = `${date.startDate.year}-${date.startDate.month}-${date.startDate.date}`;
		};
		if(date.endDate.date && date.endDate.month && date.endDate.year){
			payload.end_date = `${date.endDate.year}-${date.endDate.month}-${date.endDate.date}`;
		};
		if(values.asignto){
			payload.referred_to = values.asignto;
			searchCriteriaMessage.push(`${constants.AssignedTo}: ${getNamebyId(values.asignto,associate_list !== null ? associate_list : listAssociates,"assosiate")}`);
		};
		setAdvanceFilterPayload(payload);
		if(date.startDate.date ||date.startDate.month || date.startDate.year || date.endDate.date || date.endDate.month || date.endDate.year ){
			if(date.startDate.date && date.startDate.month && date.startDate.year || date.endDate.date && date.endDate.month && date.endDate.year){
				fetchClientList(sortPayload,payload);
				if((date.startDate.date && date.startDate.month && date.startDate.year) && (date.endDate.date && date.endDate.month && date.endDate.year)){
					searchCriteriaMessage.push(`${values.date_type === 1 ? "Admission: " : "Validity: "}${moment(`${date.startDate.year}-${date.startDate.month}-${date.startDate.date}`).format(constants.dateformat)} to ${moment(`${date.endDate.year}-${date.endDate.month}-${date.endDate.date}`).format(constants.dateformat)}`);
				}else{
					if (date.startDate.date && date.startDate.month && date.startDate.year) {
						searchCriteriaMessage.push(`${values.date_type === 1 ? "Admission: " : "Validity: "}${moment(`${date.startDate.year}-${date.startDate.month}-${date.startDate.date}`).format(constants.dateformat)}`);
					}
					if (date.endDate.date && date.endDate.month && date.endDate.year) {
						searchCriteriaMessage.push(`${values.date_type === 1 ? "Admission: " : "Validity: "}${moment(`${date.endDate.year}-${date.endDate.month}-${date.endDate.date}`).format(constants.dateformat)}`);
					}
				}
				if(searchCriteriaMessage.length>=1){
					setfilteredSearchCriteria( searchCriteriaMessage.join(". ")+".");
				}else{
					setfilteredSearchCriteria( searchCriteriaMessage.join(". "));
				};
			}else{
				message.error("Please fill proper date.")
			}
		}else{
			fetchClientList(sortPayload,payload);
			if(searchCriteriaMessage.length>=1){
				setfilteredSearchCriteria( searchCriteriaMessage.join(". ")+".");
			}else{
				setfilteredSearchCriteria( searchCriteriaMessage.join(". "));
			};
		}
	};
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
			} else {
				setCountries([]);
			}
		});
	};
	const getNamebyId = (id,res,type) => {
		// Return the name if the object is found, otherwise return a default value
		let foundItem; 
		if(type === "country"){
			foundItem = res.find(item => item.country_id === id);
			return foundItem ? foundItem.country_name : '';
		}
		else if(type === "assosiate"){
			foundItem = res.find(item => item.client_id === id);
			return foundItem ? foundItem.firstname + " " + foundItem.lastname : '';
		}
		else{
			res=res[stateKey].states;
			foundItem = res.find(item => item.region_id === id);
			return foundItem ? foundItem.region_name : '';
		};
	};
	const onChangeCountry = (option,val) => {
		fetchStates(val.key);
		setStateKey(val.key);
		form.setFieldsValue({ state: null });
	};
	const fetchStates = (country_code) => {
		fetchApi(`/states/${country_code}`, "get").then(
			(res) => {
				if (res?.data) {
					setTempState(res?.data);
					setListStates(res.data);
				} else {
					setListStates(null);
					setTempState(null);
				}
			}
		);
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	const handleSearchCity = (value) => {
		setCityOptions(
      !value
        ? []
				: listCities,
    );
	};
	const getCitiesFromDb = () => {
		fetchApi(`/cities`, "get").then(
			(res) => {
				if (res?.data) {
					setlistCities(res?.data);
				} else {
					setlistCities([]);
				}
			}
		);
	};
	const onChangeStatus = (checkedValues) => {
		setUserStatus(checkedValues);
	};
	const onChangeDate = (e) => {
		setDateOf(e.target.value);
	};
	//from date onchange
	const handleDayChangeFD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate: {
				...prevEnquiryDate.startDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeFD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate:{
				...prevEnquiryDate.startDate,
				month:value,
			},
		}));
	};
	const handleYearChangeFD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate:{
				...prevEnquiryDate.startDate,
				year:value,
			},
		}));
	};
	//to date onchange
	const handleDayChangeTD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			endDate: {
				...prevEnquiryDate.endDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeTD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			endDate:{
				...prevEnquiryDate.endDate,
				month:value,
			},
		}));
	};
	const handleYearChangeTD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			endDate:{
				...prevEnquiryDate.endDate,
				year:value,
			},
		}));
	};
	const filterReset = (statuses = null,reset=null) => {
		setfilteredSearchCriteria("");
		form.resetFields();
		if(reset){
			setDate((prevEnquiryDate) => ({
				...prevEnquiryDate,
				startDate: {
					...prevEnquiryDate.startDate,
					date: null,
					month: null,
					year: null
				},
				endDate: {
					...prevEnquiryDate.endDate,
					date: null,
					month: null,
					year: null
				}
			}));
			setUserStatus([]);
			setAdvanceFilterPayload(null);
			fetchClientList(sortPayload);
		};
		form.setFieldsValue({ 
		 date_type: 1,
		 status: statuses === "All" || statuses === constants.DemoClient ? [] : statuses		
		});
	};
	
	const streamVideoPage = (video_id) => {
		sessionStorage.setItem('video_id',video_id);
		window.open(constants.BaseUrl + `/video`);
	};
	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ClientList} ({clientListType})</span>
			</div>
			<div className='container mx-auto'>
				{
					location.pathname !== "/demo-client-list" ?
						<div className='mt-2'>
							<Space>
								<Form.Item label="Search" className="mb-0">
									<Input className='w-full lg:w-52' size="middle" onChange={onSearch} value={searchValue} />
								</Form.Item>
								<span className='font-semibold text-base'>{getRecordMessage()}</span>
								<Tooltip title={constants.Filter}><Button className="bg-sky-800 text-white" onClick={advancedFilter} icon={<FilterOutlined />}></Button></Tooltip>
								{loginType === "1" && emailId === "demoa5267@gmail.com" || loginType === "1" && emailId === "admin@mailinator.com" || loginType === "1" && emailId === "roy@mailinator.com" || loginType === "1" && emailId === "Roy@mailinator.com" ? <a style={{ visibility: "hidden" }} onClick={()=>onclickHere("1")}>{constants.WbContents}</a> : null}
								{loginType === "1" && emailId === "demoa5267@gmail.com" || loginType === "1" && emailId === "admin@mailinator.com" || loginType === "1" && emailId === "roy@mailinator.com" || loginType === "1" && emailId === "Roy@mailinator.com" ? <a style={{ visibility: "hidden" }} onClick={()=>onclickHere("2")}>{constants.ClientAnswers}</a> : null}
							</Space>
							{(location.pathname === "/client-list-open" || location.pathname === "/client-list-closed") && loginType === "1" && (<Tooltip title={`${constants.ClientList} (${constants.All})`}><Button className="float-right bg-sky-800 text-white" onClick={() => { window.open(`${constants.BaseUrl}/client-list-all`, '_blank'); }}>{constants.ClientList} ({constants.All})</Button></Tooltip>)}
							<Tooltip title={constants.ExportToWord}><Button style={{ visibility:"hidden" }} className="float-right bg-sky-800 text-white" icon={<FileExcelOutlined />}>{constants.ExportToWord}</Button></Tooltip>
							{filteredSearchCriteria ? <div className='text-lg font-bold mt-2.5 text-sm'>Search Criteria: {filteredSearchCriteria}</div> : null}
						</div> :
						null
				} 
				{
					isAdvanceFilterOpen ?
						<div className='mt-2 p-4 bg-white client-details'>
							<Form className='border-solid label-fs-xs form-mb-0' layout="vertical" form={form} onFinish={onFinishFilterSearch}>
								<Row gutter={[48, 8]}>
									<Col span={8}>
										<Form.Item
											label={constants.country}
											name="country_id"
										>
											<Select
												onChange={onChangeCountry}
												placeholder={"Country"}
												className="w-100"
												showSearch
												allowClear
												filterOption={selectFilterOption}>
												{listCountries &&
													listCountries.map((listCountry) => (
														<Option key={listCountry.country_code} value={listCountry.country_id}>
															{listCountry.country_name}
														</Option>
													))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={constants.state}
											name="region_id"
										>
											<Select
												placeholder={constants.state}
												allowClear
												showSearch
												filterOption={selectFilterOption}
											>
												{tempState ?
													Object.entries(tempState).map(([key, value]) => (
														<>
															{value &&
																value.states &&
																value.states.length > 0 &&
																value.states.map((state) => (
																	<Option
																		key={state.region_id}
																		value={state.region_id}
																		region={state.region_id}
																	>
																		{state.region_name}
																	</Option>
																))}
														</>
													)) :
													listStates &&
													Object.entries(tempState ? tempState : listStates).map(([key, value]) => (
														<>
															{value &&
																value.states &&
																value.states.length > 0 &&
																value.states.map((state) => (
																	<Option
																		key={state.region_id}
																		value={state.region_id}
																		region={state.region_id}
																	>
																		{state.region_name}
																	</Option>
																))}
														</>
													))
												}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={constants.city} name="city" >
											<AutoComplete
												options={cityOptions}
												onSearch={handleSearchCity}
												filterOption={(inputValue, option) =>
													option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1 && option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) < 1
												}
											>
												<Input
													placeholder= {constants.city}
												/>
											</AutoComplete>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={constants.sec}
											name={constants.sec}
										>
											<Select
												placeholder={constants.sec}
												className="w-100"
												showSearch
												allowClear
												mode="multiple"
												filterOption={selectFilterOption}>
												{constants.SectionOptions &&
													constants.SectionOptions.map((sectionList) => (
														<Option key={sectionList.value} value={sectionList.value}>
															{sectionList.label}
														</Option>
													))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={"For"}
											name={"is_minor"}
										>
											<Checkbox.Group /* onChange={onChangeIsMinor} */ >
												<Checkbox className='!items-start' value={"Adult"}>Adult</Checkbox>
												<Checkbox className='!items-start' value={"Minor"}>Minor</Checkbox>
											</Checkbox.Group>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={"Status"}
											name={"status"}
										>
											<Checkbox.Group onChange={onChangeStatus} value={userStatus}>
												<Space size={'large'}>
													<Checkbox className='!items-start' disabled={clientListType === "All" || clientListType === constants.DemoClient ? false : clientListType === "Open" ? false : true} value="Active">Active</Checkbox>
													<Checkbox className='!items-start' disabled={clientListType === "All" || clientListType === constants.DemoClient ? false : clientListType === "Closed" ? false : true} value="Closed">Closed</Checkbox>
													<Checkbox className='!items-start' disabled={clientListType === "All" || clientListType === constants.DemoClient ? false : clientListType === "Open" ? false : true} value="Refresher">Refresher</Checkbox>
												</Space>
											</Checkbox.Group>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={"Date of"}
											name={"date_type"}
										>
											<Radio.Group onChange={onChangeDate} value={dateOf}>
												<Radio value={1}>Admission</Radio>
												<Radio value={2}>Validity</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									<Col span={8}>
										{DOB(date.startDate.month,handleMonthChangeFD,date.startDate.date,handleDayChangeFD,date.startDate.year,handleYearChangeFD,"From",null)}
									</Col>
									<Col span={8}>
										{DOB(date.endDate.month,handleMonthChangeTD,date.endDate.date,handleDayChangeTD,date.endDate.year,handleYearChangeTD,"To",null)}
									</Col>
									<Col span={8}>
										<Form.Item
											label={constants.AssignedTo}
											name="asignto"
										>
											<Select
												placeholder={constants.AssignedTo}
												// value={clientRegistrationTypeDetails?.asign_to}
												// onChange={onChangeAsignTo}
												showSearch
												optionFilterProp="children"
												filterOption={selectFilterOption}
											>
												{listAssociates &&
													listAssociates.map((associate,key) => (
														<Option key={key} value={associate.client_id}>
															{associate.firstname + " " + associate.lastname}
														</Option>
													))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Divider className="my-3" />
								<div className="text-right">
									<Space >
										<Form.Item className="mb-1">
											<Button htmlType="submit" className='bg-sky-800 text-white' icon={<SearchOutlined />}>Search</Button>
										</Form.Item>
										<Form.Item className="mb-1">
											<Button onClick={() => { filterReset(clientListType === "Open" ? ["Active","Refresher"] : clientListType,"Reset"); }} icon={<ReloadOutlined />}>Reset</Button>
										</Form.Item>
										<Form.Item className="mb-1">
											<Button className='bg-red-600 text-white' onClick={() => { setIsAdvancetFilterOpen(!isAdvanceFilterOpen); }} icon={<CloseOutlined />}>Close</Button>
										</Form.Item>
									</Space>
								</div>
							</Form>
						</div> :
						null
				}
				<Spin spinning={loader} size="large">
					{
						location.pathname === "/demo-client-list" ?
							<Table
								pagination={false}
								dataSource={tableData}
								columns={columns}
								bordered
								scroll={{ x: 900 }}
								locale={{ emptyText: 'No Records Found' }}
								className='mt-2'
								size="small"
							/> :
							<Table
								pagination={pagination}
								dataSource={tableData}
								columns={columns}
								bordered
								scroll={{ x: 900 }}
								locale={{ emptyText: 'No Records Found' }}
								onChange={onChangeTable}
								className='mt-2'
								size="small"
							/>
					}
				</Spin>
			</div>
			{
				location.pathname === "/demo-client-list" ?
					<div className='mt-3 text-center'>
						<h1 className='text-lg font-bold'>Take a video tour</h1>
						<Space size={'large'} className='mt-3' split={<Divider type="vertical" />}>
							{[...Array(8)].map((e, i) => {return <Typography.Link className='text-base' onClick={()=>streamVideoPage(i+1)} key={i}>{constants.sec+ " "+(i+1)}</Typography.Link>})}
						</Space>
					</div> :
					null
			}
		</div>
	);
};
export default ClientList;
