import React,{ useEffect, useState } from 'react'
import { fetchApi } from '../../../services/api';

const EnquiryJsonInfo = () => {
  const [jsonData, setJsonData] = useState([]);
  useEffect(() => {
		fetchJsonData();
	}, []);
  const fetchJsonData = () =>{
		fetchApi(`/get-enquiry-logs`, "get").then(
			(res) => {
        if(res){
          setJsonData(JSON.stringify(res));
        }else{
          setJsonData(null);
        }
			}
		);
	};
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html:  JSON.stringify(jsonData).replace(/\\r\\n|\\n|\\r/gm,"<br/>") }}/>
    </>
  )
}

export default EnquiryJsonInfo