import React, { useEffect, useState } from 'react';
import { Button, Table, InputNumber, Space, Select, Popconfirm, message, Spin } from 'antd';
import { fetchApi } from "../../services/api";
import { useHistory } from "react-router";
import { COOKIE, getCookie, } from "../../services/cookie";
import constants from '../../constants/constants';
const { Option } = Select;
const WbContentAndClientAns = (props) => {
	const history = useHistory();
	const [tableData, setTableData] = useState([]);
	const [searchVal, setSearchVal] = useState({
		item_id: props?.location?.state?.serachparam?.item_id ? props?.location?.state?.serachparam?.item_id : null,
		section_id: props?.location?.state?.serachparam?.section_id ? props?.location?.state?.serachparam?.section_id : null,
		part_id: props?.location?.state?.serachparam?.part_id ? props?.location?.state?.serachparam?.part_id : null,
		client_id: props?.location?.state?.serachparam?.client_id ? props?.location?.state?.serachparam?.client_id : null,
	});
	const [searchType, setSearchType] = useState(props?.location?.state?.searchType ? props?.location?.state?.searchType : "item_id");
	const [emailId, setEmailId] = useState("");
	let clientId = getCookie(COOKIE.ClientId);
	let logintype = getCookie(COOKIE.LoginType);
	let queryString = window.location.href;
  let url = queryString.substring(queryString.lastIndexOf('/') + 1);
  const isClientAns =  url === "client-answers" ? true : false;
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (props?.location?.state?.serachparam) {
			getWbContentAndClientAnswer(props?.location?.state?.serachparam?.item_id, props?.location?.state?.serachparam?.part_id, props?.location?.state?.serachparam?.section_id, props?.location?.state?.serachparam?.client_id);
		};
		getEmailId();
	}, []);
	useEffect(() => {
		if (logintype !== "1") {
			history.push("/");
		}
	}, [history]);
	const getEmailId = () => {
		fetchApi(`/client-profile/${clientId}`, "get").then((res) => {
			if (res && res.data && res.code === 200) {
				setEmailId(res?.data?.email);
			} else {
				setEmailId("");
			};
		});
	};
	const getWbContentAndClientAnswer = (item_id = null, part_id = null, section_id = null, client_id = null) => {
		setLoading(true);
		let payload = {};
		if (item_id !== null) {
			payload.item_id = item_id
		}
		if (part_id !== null) {
			payload.part_id = part_id
		}
		if (section_id !== null) {
			payload.section_id = section_id
		}
		if (client_id !== null) {
			payload.client_id = client_id
		}
		let url
		if(isClientAns){
			url = "/get-answers"
		}else{
			url = "/get-content"
		}
		if(item_id !== null || part_id !== null ||section_id !== null || client_id !== null ){
			fetchApi(url, 'post', payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setLoading(false);
					setTableData(res?.data);
				} else {
					setLoading(false);
					setTableData([]);
				}
			});
		}
	};
	const editandDeleteWb = (val, type) => {
		let payload,pathname,state,apiurl;
		if(isClientAns){
			pathname = "/edit-wb-client-ans";
			payload={
				answer_id: val?.answer_id,
				client_id:val?.client_id,
				is_client_ans:true
			}
			state={
				serachparam: payload,
			}
		}else{
			pathname = "/edit-wb-content";
			payload={
				item_id: val?.item_id,
				section_id: searchVal?.section_id,
				part_id: searchVal?.part_id,
			};
			state={
				serachparam: payload,
			}
		}
		if (type === "edit") {
			history.push({
				pathname: pathname,
				state: state
			});
		}else{
			let id;
			if(isClientAns){
				apiurl = "/delete-answer";
				id = val?.answer_id;
			}else{
				apiurl = "/delete-content";
				id = val?.item_id;
			}
			fetchApi(`${apiurl}/${id}`, 'delete', payload).then((res) => {
				if (res && res.code && res.code === 200) {
					message.success(res?.message);
					if(isClientAns){
						getWbContentAndClientAnswer(searchVal?.item_id,searchVal?.part_id,searchVal?.section_id,searchVal?.client_id)
					}else{
						getWbContentAndClientAnswer(searchVal?.item_id,searchVal?.part_id,searchVal?.section_id)
					}
				} else {
					message.error(res?.message);
				}
			});
		}
	};
	const clientAnsColumn = [
		{
			title: 'action',
			render: (val) => {
				return <Space>
					<a onClick={() => editandDeleteWb(val, "edit")}>edit</a>
					<Popconfirm title="Sure to delete?" onConfirm={() => editandDeleteWb(val, "delete")}>
						<a>delete</a>
          </Popconfirm>
				</Space>;
			}
		},
		{
			title: 'answer_id',
			dataIndex: 'answer_id',
		},
		{
			title: 'client_id',
			dataIndex: 'client_id',
		},
		{
			title: 'item_id',
			dataIndex: 'item_id',
		},
		{
			title: 'json_data',
			dataIndex: 'json_data',
		},
		{
			title: 'dt_updated',
			dataIndex: 'dt_updated',
		},
		{
			title: 'option_data',
			dataIndex: 'option_data',
		},
		{
			title: 'text_data',
			dataIndex: 'text_data',
		},
		{
			title: 'dt_last_edited ',
			dataIndex: 'dt_last_edited ',
		},
		{
			title: 'section_id',
			dataIndex: 'section_id',
		},
		{
			title: 'dt_created',
			dataIndex: 'dt_created',
		},
		{
			title: 'dt_checked',
			dataIndex: 'dt_checked',
		},
	];
	const wbContentColumns = [
		{
			title: 'action',
			render: (val) => {
				return <Space>
					<a onClick={() => editandDeleteWb(val, "edit")}>edit</a>
					<Popconfirm title="Sure to delete?" onConfirm={() => editandDeleteWb(val, "delete")}>
						<a>delete</a>
          </Popconfirm>
				</Space>;
			}
		},
		{
			title: 'item_id',
			dataIndex: 'item_id',
		},
		{
			title: 'section_id',
			dataIndex: 'section_id',
		},
		{
			title: 'section_type',
			dataIndex: 'section_type',
		},
		{
			title: 'part_id',
			dataIndex: 'part_id',
		},
		{
			title: 'display_order',
			dataIndex: 'display_order',
		},
		{
			title: 'title',
			dataIndex: 'title',
		},
		{
			title: 'content_left',
			dataIndex: 'content_left',
		},
		{
			title: 'type_left',
			dataIndex: 'type_left',
		},
		{
			title: 'content_right',
			dataIndex: 'content_right',
		},
		{
			title: 'type_right',
			dataIndex: 'type_right',
		},
		{
			title: 'template_id',
			dataIndex: 'template_id',
		},
		{
			title: 'is_paused',
			dataIndex: 'is_paused',
			render: (text) => text === null ? 'null' : text,
		}
	];
	const onChangeUserValue = (val, search_type) => {
		if (search_type === "item_id") {
			setSearchVal({
				...searchVal,
				item_id: val,
			});
		} else if (search_type === "section_id") {
			setSearchVal({
				...searchVal,
				section_id: val,
			});
		} else if (search_type === "part_id") {
			setSearchVal({
				...searchVal,
				part_id: val,
			});
		}
		else if (search_type === "client_id") {
			setSearchVal({
				...searchVal,
				client_id: val,
			});
		};
	};
	const onSubmit = () => {
		if (logintype === "1" && emailId === "demoa5267@gmail.com" || logintype == "1" && emailId === "admin@mailinator.com" || logintype === "1" && emailId === "roy@mailinator.com" || logintype === "1" && emailId === "Roy@mailinator.com") {
			getWbContentAndClientAnswer(searchVal?.item_id, searchVal?.part_id, searchVal?.section_id,searchVal?.client_id);
		}else{
			history.push("/");
		}
	};
	const handleChange = (value) => {
		setSearchVal({
			...searchVal,
			part_id: null,
			item_id: null,
			section_id: null
		});
		setSearchType(value);
	};
	const onClear = () => {
		setSearchVal({
			...searchVal,
			part_id: null,
			item_id: null,
			section_id: null,
			client_id: null
		});
		setTableData([]);
	};
	const onAdd = () => {
		history.push({
			pathname: "/add-wb-content",
		});
	};
	return (
		<div className='container mx-auto'>
			<div className='mx-2 sm:mx-6 mt-8'>
				{
					isClientAns ?
						<Space>
							<InputNumber
								min={1}
								onChange={(val) => onChangeUserValue(val, "client_id")}
								placeholder={"Client Id"}
								value={searchVal.client_id}
							/>
							<InputNumber
								min={1}
								onChange={(val) => onChangeUserValue(val, "item_id")}
								placeholder={"Item Id"}
								value={searchVal.item_id}
							/>
							<Select
								placeholder={constants.sec}
								className="w-32"
								showSearch
								allowClear
								onChange={(val)=>onChangeUserValue(val, "section_id")}
								value={searchVal.section_id}
								// filterOption={selectFilterOption}
							>
								{constants.SectionOptions &&
									constants.SectionOptions.map((sectionList) => (
										<Option key={sectionList.value} value={sectionList.value}>
											{sectionList.label}
										</Option>
									))}
							</Select>
							<Button className='bg-sky-800 text-white uppercase' disabled={loading} onClick={onSubmit}>Submit</Button>
							<Button className='bg-sky-800 text-white uppercase' onClick={onClear}>Clear</Button>
							<strong>{constants.ClientAnswers}</strong>
						</Space>
						:
						<Space className='flex flex-wrap'>
							<Select
								value={searchType}
								className='w-full'
								onChange={handleChange}
								options={[
									{
										value: 'section_id',
										label: 'Section Id',
									},
									{
										value: 'item_id',
										label: 'Item Id',
									},
								]}
							/>
							{searchType === "section_id" ?
								<>
									<InputNumber
										placeholder='Section Id'
										min={0}
										onChange={(val) => onChangeUserValue(val, "section_id")}
										value={searchVal.section_id}
									/> <InputNumber
										min={1}
										placeholder='Part Id'
										onChange={(val) => onChangeUserValue(val, "part_id")}
										value={searchVal.part_id}
									/>
								</>
								: <InputNumber
									min={1}
									onChange={(val) => onChangeUserValue(val, "item_id")}
									placeholder={"Item Id"}
									value={searchVal.item_id}
								/>}
							<Button className='bg-sky-800 text-white uppercase' onClick={onAdd}>Add</Button>
							<Button className='bg-sky-800 text-white uppercase' disabled={loading} onClick={onSubmit}>Submit</Button>
							<Button className='bg-sky-800 text-white uppercase' onClick={onClear}>Clear</Button>
							<strong>{constants.WbContents}</strong>
						</Space>
				}
				<Spin spinning={loading} size="large">
					<Table
						className='mt-5'
						dataSource={tableData.length > 0 ? tableData : []}
						columns={isClientAns ? clientAnsColumn : wbContentColumns}
						pagination={false}
						bordered
						scroll={{ x: 'max-content' }}
						locale={{ emptyText: 'No Records Found' }}
					/>
				</Spin>
			</div>
		</div>
	)
};

export default WbContentAndClientAns;