import React, { useEffect, useState } from 'react';
import { fetchAdminApi, fetchApi } from '../../../services/api';
import constants from '../../../constants/constants';
import { Button, Table, Tooltip, Modal, Spin, Form, Input, message, Space, Checkbox, Row, Col, Divider, Radio } from 'antd';
import { MailOutlined, SearchOutlined, FilterOutlined, ReloadOutlined, CloseOutlined, SignatureOutlined, SendOutlined } from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router";
import moment from "moment";
import { DOB, uploadFile, welcomeEmailPopup } from '../../../globalFunctions/GlobalFunctions';
import { COOKIE, getCookie } from '../../../services/cookie';
const AssociateListing = () => {
	const [form] = Form.useForm();
	const [emailchangeForm] = Form.useForm();
	const [checkEmailPsdChange, setCheckEmailPsdChange] = useState({
		email: false,
		password: false,
	});
	const [formValues, setformValues] = useState({});
	const [isEmailChangeModalOpen, setisEmailChangeModalOpen] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loader,setLoader] = useState(false);
	const [emailValidateStatus, setEmailValidateStatus] = useState("");
	const [emailIdValue, setEmailIdValue] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [editAssociateid, setEditAssociateid] = useState("");
	const [pagination, setPagination] = useState(constants.paginationConfig);
	const [fileList, setFileList] = useState([]);
	const [showUploadSignature, setShowUploadSignature] = useState(false);
	const history = useHistory();
	const layout2 = {
		labelCol: { span: 6 },
		wrapperCol: { span: 18 },
	};
	const showModal = () => {
		form.resetFields();
		if(isEdit === false){
			setShowUploadSignature(false);
		}
		setEmailValidateStatus("");
		const currentDate = new Date();
		setStartDate({
			date: currentDate.getDate(),
			month: (currentDate.getMonth() + 1).toString().padStart(2, '0'),
			year: currentDate.getFullYear()
		});
		setEndDate({ date: null, month: null, year: null }); 
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = (e, type) => {
		if (type === "emailchangepopup") {
			setisEmailChangeModalOpen(false);
		} else {
			setIsModalOpen(false);
			setIsEdit(false);
		}
	};
	useEffect(() => {
		getAssociateList();
	}, []);
	const getAssociateList = () => {
		setLoader(true);
		let payload = {};
		fetchAdminApi(`/view-associates`, "post", payload).then((res) => {
			if (res?.code === 200) {
				setLoader(false);
				let removeRoy = res?.data?.filter((item)=>item.client_id !== "1");
				setTableData(removeRoy);
			}else{
				setTableData([]);
				setLoader(false);
			}
		});
	};
	const copyToClipboard = (email_id) => {
		const el = document.createElement('textarea');
		el.value = email_id;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};
	const [startDate, setStartDate] = useState({
		date: null,
		month: null,
		year: null
	});
	const [endDate, setEndDate] = useState({
		date: null,
		month: null,
		year: null
	});
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const getRecordMessage = () => {
		let record_message;
		if (tableData.length > 1) {
			record_message = tableData.length + " " + "records found.";
		} else if (tableData.length === 0) {
			record_message = "No records found.";
		} else {
			record_message = tableData.length + " " + "record found.";
		}
		return record_message;
	};
	const advancedFilter = () => {
		setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
	};
	const [radioValue, setRadioValue] = useState([]);
	const onChangeRadio = (e) => {
		setRadioValue(e.target.value);
	};
	const [filteredSearchCriteria, setFilteredSearchCriteria] = useState("");
	const filterReset = (reset = null) => {
		setFilteredSearchCriteria("");
		form.resetFields();
		if (reset) {
			setRadioValue([]);
		};
	};
	const [filteredTableData, setFilteredTableData] = useState([]);
	const originalTableData = tableData;
	const onFinishFilterSearch = (values) => {
		setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
		let searchCriteriaMessage = [];
		let filteredRecords = [];
		if (!values.isactive) {
			setFilteredTableData(originalTableData);
			return;
		}
		const dataToFilter = values.isactive === 'All' ? originalTableData : tableData;
		for (const record of dataToFilter) {
			const totalClientsString = record.total_clients;
			const [activeClients] = totalClientsString.split('/');

			switch (values.isactive) {
				case 'All':
					filteredRecords.push(record);
					break;

				case 'Active':
					if (parseInt(activeClients) > 0) {
						filteredRecords.push(record);
					}
					break;

				case 'Inactive':
					if (parseInt(activeClients) === 0) {
						filteredRecords.push(record);
					}
					break;

				default:
					break;
			}
		}
		if (values.isactive) {
			searchCriteriaMessage.push(`Status: ${values.isactive}`);
		}
		setFilteredTableData(filteredRecords);
		setFilteredSearchCriteria(searchCriteriaMessage.join(', '));
	};
	const handleMonthChange = (value) => {
		setEndDate({ ...endDate, month: value });
	};
	const handleDayChange = (value) => {
		setEndDate({ ...endDate, date: value });
	};
	const handleEndYearChange = (value) => {
		setEndDate({ ...endDate, year: value });
	};
	const editAssociate = (associate_data) => {
		setEditAssociateid(associate_data?.client_id);
		showModal();
		if (associate_data?.dt_created) {
			let startDateValue = associate_data?.dt_created;
			let startDateParts = startDateValue?.split("-");
			setStartDate({
				date: parseInt(startDateParts[2]),
				year: parseInt(startDateParts[0]),
				month: startDateParts[1],
			});
		} else {
			setStartDate({ date: null, month: null, year: null });
		}
		if (associate_data?.valid_till) {
			let endDateValue = associate_data?.valid_till;
			let endDateParts = endDateValue?.split("-");
			setEndDate({
				...endDate,
				date: parseInt(endDateParts[2]),
				year: parseInt(endDateParts[0]),
				month: endDateParts[1],
			});
		} else {
			setEndDate({ date: null, month: null, year: null });
		}
		form.setFieldsValue({
			firstname:associate_data?.firstname,
			lastname:associate_data?.lastname,
			email:associate_data?.email,
			contactno:associate_data?.contact_no,
			startdt:associate_data?.dt_created,
			enddt:associate_data?.valid_till,
		});
		if(associate_data?.signature !== null){
			setShowUploadSignature(false);
		}else{
			setShowUploadSignature(true);
		}
		setIsEdit(true);
	}
	const columns = [
		{
			title: '#',
			align: "center",
			dataIndex: 'serial_no',
			key: 'serial_no',
			width: "5%",
			render: (text, object, index) => { return <span className="text-right w-6 block m-auto">{index + 1}</span>; }
		},
		{
			title: 'Associate Name',
			key: 'firstname',
			sorter: (a, b) => a.firstname.localeCompare(b.firstname),
			render: (record) => {
				return <a onClick={()=>editAssociate(record)}>
						<Space>
							<SearchOutlined/>
							{record?.firstname}{record?.lastname}
							{record?.signature !== null ?<SignatureOutlined style={{ fontSize:"18px" }}/>:null}
						</Space>
				</a>;
			}
		},
		{
			title: 'Assigned Clients',
			key: 'assigned_client',
			align: "center",
			width: "5%",
			render: (record) => {
				const totalClientsString = record?.total_clients;
				const [activeClients, totalClients] = totalClientsString.split('/');
				if (parseInt(activeClients) === 0 && parseInt(totalClients) === 0) {
					return null;
				}
				return (
					<Tooltip title={
						<>
							{`${activeClients} Active `} <br />{`${totalClients} Total`}
						</>
					}>
						<Space onClick={() => {
							let filterValue = { client_id: record?.client_id, is_associate: true, is_active: ["Active"] };
							sessionStorage.setItem("filterValue", JSON.stringify(filterValue));
							window.open(constants.BaseUrl + `/client-list-open`, '_blank');
							sessionStorage.removeItem('filterValue'); 
						}}>
							{totalClientsString}
						</Space>
					</Tooltip>
				);
			}
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: "5%",
			align: "center",
			render: (email) => {
				return <Tooltip title={email}><MailOutlined onClick={() => copyToClipboard(email)}/></Tooltip>;
			}
		},
		{
			title: 'Contact No.',
			dataIndex: 'contact_no',
			key: 'contact_no',
		},
		{
			title: 'Start Date',
			dataIndex: 'dt_created',
			key: 'dt_created',
			sorter: (a, b) => moment(a.dt_created).unix() - moment(b.dt_created).unix(),
			render: (record) => {
				return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
			},
		},
		{
			title: 'End Date',
			dataIndex: 'valid_till',
			key: 'valid_till',
			sorter: (a, b) => moment(a.valid_till).unix() - moment(b.valid_till).unix(),
			render: (record) => {
				return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
			},
		},
	];
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};
	const tailLayout = { wrapperCol: { span: 24 } };
	const handleResend = (client_id) => {
		setLoader(true);
		let payload = {
			client_id: client_id,
		};

		fetchAdminApi(`/add-associate`, "post", payload)
			.then((res) => {
				if (res && res.code === 200) {
					message.success(res.message);
					setLoader(false);
				} else {
					message.error(res.message);
					setLoader(false);
				}
			})
			.catch((error) => {
				message.error('Failed to resend email.');
				setLoader(false);
			});
	};		
	const handleValidateName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:!"\/\[\]\|{}()=+\\]/;
				if (nameValidate.test(value) === true || value.length < 2) {
					throw new Error(constants.NameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateLastName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:!"\/\[\]\|{}()=+\\]/;
				if (nameValidate.test(value) === true || value.length < 2) {
					throw new Error(constants.LastNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleCheckEmail = () => {
		let payload = {};
		if (emailIdValue) {
			payload.email = emailIdValue;
		}
		fetchApi("/client/register/check-emails", "post", payload).then((res) => {
			if (res?.code === 200) {
				setEmailValidateStatus("success");
			} else {
				setEmailValidateStatus("error");
				message.error(res.message?.email);
			}
		});
	};
	const tailFormItemLayout = {
		wrapperCol: {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 16,
				offset: 8,
			},
		},
	};
	const validateEndDate = (endDate, startDate) => {
		const end = new Date(`${endDate.year}-${endDate.month}-${endDate.date}`);
		const start = new Date(`${startDate.year}-${startDate.month}-${startDate.date}`);
		if (end < start) {
			return 'error';
		}
		return '';
	};
	const onFinishForm = (value) => {
		let nopopup = value?.email.includes("@opspl.com") || value?.email.includes("@mailinator.com");
		if (nopopup) {
			saveAssociateDetails(value);
			setisEmailChangeModalOpen(false);
		} else {
			emailchangeForm.resetFields();
			setCheckEmailPsdChange({
				...checkEmailPsdChange,
				email: false,
				password: false
			});
			setformValues(value);
			setisEmailChangeModalOpen(true);
		}
	};
	const saveAssociateDetails = (value, popupvalue = null) => {
		setLoader(true);
		const endDateValidationResult = validateEndDate(endDate, startDate);
		if (endDateValidationResult === 'error') {
			message.error(constants.endDateValidationError);
			setLoader(false);
			return;
		}
		let url;
		let method;
		let payload;
		if (isEdit) {
			url = `/client/${editAssociateid}`;
			method = "put";
		} else {
			url = "/add-associate";
			method = "post";
		}
		payload = {
			firstname: value?.firstname,
			lastname: value?.lastname,
			email: value?.email,
			contact_no: value?.contactno,
			// is_visible: value?.isvissible,
			valid_till: endDate?.date !== null && endDate?.month !== null && endDate?.year ? `${endDate.year}-${endDate.month}-${endDate?.date}` : null,
			dt_created: `${startDate.year}-${startDate.month}-${startDate?.date}`,
		};
		if (popupvalue !== null) {
			payload.login_password = popupvalue.password
		}
		fetchAdminApi(url, method, payload).then((res) => {
			if (res && res.code && res.code === 200) {
				if (showUploadSignature && fileList.length > 0) {
					let upload_sign = handleUpload();
					upload_sign.then((upload_status) => {
						if (upload_status.code === 200) {
							message.success(res?.message);
							setIsModalOpen(false);
							getAssociateList();
							setLoader(false);
							setIsEdit(false);
							setFileList([]);
						} else {
							message.error(upload_status.message);
							setLoader(false);
						}
					});
				} else {
					message.success(res?.message);
					setIsModalOpen(false);
					getAssociateList();
					setLoader(false);
					setIsEdit(false);
					setFileList([]);
				}
				if (popupvalue !== null) {
					setisEmailChangeModalOpen(false);
				}
			} else {
				if (popupvalue !== null) {
					setisEmailChangeModalOpen(true);
				}
				message.error(res?.message);
				setLoader(false);
			}
		})
	};
	const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
        formData.append('sign', file.originFileObj);
    });
    formData.append('associate_id', editAssociateid);
    try {
        const response = await fetch(`${constants.ApiUrl}/save-associate-signature`, {
            method: 'POST',
            body: formData,
            headers: { Authorization: `Bearer ${getCookie(COOKIE.Token)}` },
        });
        if (!response.ok) {
            // Handle error response
            throw new Error('Network response was not ok');
        }
        // Get JSON data from response
        const responseData = await response.json();
        return responseData; // Return the JSON data
    } catch (error) {
        console.error('Error:', error.message);
        return null; // Return null or handle error appropriately
    }
};
	const onChangeTable = (paginate, sort, filter) => {
		setPagination(paginate);
	};
	const handleFileChange = ({ fileList }) => {
		setFileList(fileList);
	};
	const handleRemove = (file) => {
		// remove the file from the fileList
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};
	function beforeUpload(file) {
		const isJpgOrPng =
    file.name.includes(".jpg") || file.name.includes(".JPG") ||
    file.name.includes(".png") || file.name.includes(".PNG") ||
		file.name.includes(".jpeg")|| file.name.includes(".JPEG");
		if (!isJpgOrPng) {
			message.error("You can only upload JPEG/PNG/JPG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Max file size accepted 2MB!");
		}
		return isLt2M;
	};

	const onFinishEmailChange = (value) => {
		setLoader(true);
		saveAssociateDetails(formValues, value);
	};

	const onCheckboxChange = (e, type) => {
		if (type === "email") {
			setCheckEmailPsdChange({
				...checkEmailPsdChange,
				email: e.target.checked,
			});
		} else {
			setCheckEmailPsdChange({
				...checkEmailPsdChange,
				password: e.target.checked,
			});
		}
	};
	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.Associates}</span>
			</div>
			<div className='container mx-auto'>
				<Space>
					<Button className='bg-sky-800 text-white uppercase my-2.5' onClick={showModal}>{constants.AddAssociates}</Button>
					<span className='font-semibold text-base'>{getRecordMessage()}</span>
					<Tooltip title={constants.Filter}><Button className="bg-sky-800 text-white" onClick={advancedFilter} icon={<FilterOutlined />}></Button></Tooltip>
				</Space>
				{filteredSearchCriteria ? (<div className='text-lg font-bold mt-2 text-sm'>Search Criteria: {filteredSearchCriteria}</div>) : null}
				{
					isAdvanceFilterOpen ? <div className='my-2 p-4 bg-white client-details'>
						<Form className='border-solid label-fs-xs form-mb-0' layout="vertical" form={form} onFinish={onFinishFilterSearch}>
							<Row gutter={[48, 8]}>
								<Col span={8}>
									<Form.Item
										label={"Status"}
										name={"isactive"}
									>
										<Radio.Group onChange={onChangeRadio} value={radioValue}>
											<Space size={'large'}>
												<Radio className='!items-start' value="All">All</Radio>
												<Radio className='!items-start' value="Active">Active</Radio>
												<Radio className='!items-start' value="Inactive">Inactive</Radio>
											</Space>
										</Radio.Group>
									</Form.Item>
								</Col>
							</Row>
							<Divider className="my-3" />
							<div className="text-right">
								<Space >
									<Form.Item className="mb-1">
										<Button htmlType="submit" className='bg-sky-800 text-white' icon={<SearchOutlined />}>Search</Button>
									</Form.Item>
									<Form.Item className="mb-1">
										<Button onClick={() => { filterReset("Reset"); }} icon={<ReloadOutlined />}>Reset</Button>
									</Form.Item>
									<Form.Item className="mb-1">
										<Button className='bg-red-600 text-white' onClick={() => { setIsAdvanceFilterOpen(!isAdvanceFilterOpen); }} icon={<CloseOutlined />}>Close</Button>
									</Form.Item>
								</Space>
							</div>
						</Form>
					</div> :
						null
				}
				<Spin spinning={loader} size="large">
					<Table
						pagination={pagination}
						onChange={onChangeTable}
						dataSource={filteredSearchCriteria ? filteredTableData : originalTableData}
						columns={columns}
						bordered
						scroll={{ x: 900 }}
						locale={{ emptyText: 'No Records Found' }}
					/>
				</Spin>
			</div>
			<Modal 
				title={<div className="text-center text-lg">{isEdit === true ? constants.EditAssociateDetails : constants.AddAssociateDetails}</div>} 
				open={isModalOpen} 
				onOk={handleOk} 
				onCancel={(e) => handleCancel(e, "associatemodal")}
				centered 
				style={{ top: 20 }} 
				width={700} 
				className="client-details"  
				footer={null} 
			>
				<Spin spinning={loader} size="large">
					<Form
						labelWrap
						form={form}
						onFinish={onFinishForm}
						{...layout}
					>
						<Form.Item
							className="mb-3"
							label={constants.FirstName}
							name={"firstname"}
							rules={[
								{
									required: true,
									message: constants?.FirstNameRegistration,
									whitespace: true
								},
								{ validator: handleValidateName },
							]}
						>
							<Input autoFocus placeholder={constants.FirstName}/>
						</Form.Item>
						<Form.Item
							className="mb-3"			
							label={constants.LastName}
							name={"lastname"}
							rules={[
								{
									required: true,
									message: constants?.LastNameRegistration,
								},
								{ validator: handleValidateLastName },
							]}>
							<Input placeholder={constants.LastName}/>
						</Form.Item>
						<Form.Item
							className="mb-3"
							label={
								<>
									{constants.Email}
									{isEdit === true ? (
										<Button
											title="Resend Email"
											type="link"
											name={"clientid"}
											icon={<MailOutlined />}
											onClick={() => handleResend(editAssociateid)}
										/>
									) : null}
								</>
							}
							hasFeedback
							validateStatus={emailValidateStatus}
							name={"email"}
							rules={[
								{
									required: true,
									message: constants.EmailRegistration,
								},
								{
									type: 'email',
									message: constants.emailVaidationError,
								},
							]}
						>
							<Input
								placeholder={constants.EmailPlaceholder}
								onChange={(e) => {
									setEmailIdValue(e.target.value.toLocaleLowerCase());
								}}
								onBlur={() => { handleCheckEmail(); }}
							/>
						</Form.Item>
						<Form.Item
							className="mb-3"
							label={constants.phone}
							name={"contactno"}
							rules={[
								{
									required: true,
									message: constants.PhoneNoRegistration,
								},
							]}
						>
							<PhoneInput preferredCountries={["us","in"]} className='w-full' country={"in"} placeholder="1(999)123-4567"/>
						</Form.Item>
						{DOB(startDate.month, null, startDate.date, null, startDate.year, null, constants.Startdt, true, null)}
						<div className="-mb-3.5"></div>
						{DOB(endDate.month, handleMonthChange, endDate.date, handleDayChange, endDate.year, null, constants.Enddt, false, validateEndDate(endDate, startDate), handleEndYearChange, true)}
						{showUploadSignature ? uploadFile(fileList, handleFileChange,handleRemove,beforeUpload,"upload-image-signature"):null}
						{/* {isEdit === false ?
							<Form.Item
								className="mb-2"
								label={""}
								name={"isvissible"}
								valuePropName="checked"
								{...tailFormItemLayout}
							>
								<Checkbox>{constants.NotesVisible}</Checkbox>
							</Form.Item> : null
						} */}
						<Form.Item {...tailLayout}>
							<Space className="float-right">
								<Button
									size="middle"
									onClick={(e) => handleCancel(e, "associatemodal")}
								>
									{constants.CancelBTN}
								</Button>
								<Button
									size="middle"
									className='bg-sky-800 text-white'
									htmlType="submit"
								>
									{isEdit === true ? constants.Save: constants.Add}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
			{welcomeEmailPopup(isEmailChangeModalOpen,handleCancel,loader,emailchangeForm,onFinishEmailChange,onCheckboxChange,checkEmailPsdChange,layout2,isEdit==false?false:true)}
		</div>
	);
};
export default AssociateListing;