import React,{ useEffect, useState } from 'react';
import { fetchAdminApi, fetchApi } from "../../../services/api";
import constants from '../../../constants/constants';
import { Table, Space, Form, Input, Tooltip, Button, Row, Col, Select, Divider, AutoComplete, Checkbox, message, Spin } from 'antd';
import { SearchOutlined, DeploymentUnitOutlined, ReloadOutlined, MailOutlined, FilterOutlined, CloseOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from "react-router";
import { COOKIE, getCookie } from '../../../services/cookie';
import { DOB } from '../../../globalFunctions/GlobalFunctions';
import moment from "moment";
const ClientEnquiryList = () => {
	const [form] = Form.useForm();
	const [tableData, setTableData] = useState([]);
	const [filterTable, setFilterTable] = useState([]);
	const [clientEnquiryType, setClientEnquiryType] = useState(null);
	const history = useHistory();
	let location = useLocation();
	const [loader, setloader] = useState(false);
	const [isAdvanceFilterOpen, setIsAdvancetFilterOpen] = useState(false);
	const [filteredSearchCriteria, setfilteredSearchCriteria] = useState("");
	const [listCountries, setCountries] = useState([]);
	const [tempState, setTempState] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [stateKey, setStateKey] = useState("");
	const [cityOptions, setCityOptions] = useState([]);
	const [listCities, setlistCities] = useState([]);
	const [emailId, setEmailId] = useState("");
	const [pagination, setPagination] = useState(constants.paginationConfig);
	let loginType = getCookie(COOKIE.LoginType);
	let clientId = getCookie(COOKIE.ClientId);
	const [date, setDate] = useState({
		startDate:{
			date: null,
			month: null,
			year: null
		},
		endDate:{
			date: null,
			month: null,
			year: null
		}
	});
	const [sortPayload, setSortPayload] = useState({});
	const [advanceFilterPayload, setAdvanceFilterPayload] = useState({});
	useEffect(() => {
		getEmailId();
		getClientEnquiryList();
		fetchCountries();
		getCitiesFromDb();
	}, [location]);
	//filter code.
	const advancedFilter = () => {
		setIsAdvancetFilterOpen(!isAdvanceFilterOpen);
	};
	const [userStatusCheckbox,setUserStatusCheckbox] = useState([
		{
			label:"New",
			value:"0",
			hide: false,
		},
		{
			label:"Parent Consent Requested",
			value:"1",
			hide: false,
		},
		{
			label:"Parent Consent Received",
			value:"2",
			hide: false,
		},
		{
			label:"Registration Link Sent",
			value:"3",
			hide: false,
		},
		{
			label:"Registered (Awaiting Docs)",
			value:"4",
			hide: false,
		},
		{
			label:"Agreements Signed (Awaiting Payment)",
			value:"6",
			hide: false,
		},
		{
			label:"Fee Plan Sent to Caller",
			value:"7",
			hide: false,
		},
		{
			label:"Payment Received from Caller",
			value:"8",
			hide: false,
		},
		{
			label:"Payment Received",
			value:"9",
			hide: false,
		},
	]);
	const getClientEnquiryList = (tablePayload = null,filterPayload = null) => {
		setloader(true);
		let client_enquiry_list_type; 
		let loginType = getCookie(COOKIE.LoginType);
		let loginID = getCookie(COOKIE.ClientId);
		if (location.pathname === "/client-enquiry-list-all") {
			client_enquiry_list_type = "";
			setClientEnquiryType("All");
			if (tablePayload === null || filterPayload === null) {
				form.resetFields();
				setUserStatusCheckbox([
					{
						label: "New",
						value: "0",
						hide: false,
					},
					{
						label: "Parent Consent Requested",
						value: "1",
						hide: false,
					},
					{
						label: "Parent Consent Received",
						value: "2",
						hide: false,
					},
					{
						label: "Registration Link Sent",
						value: "3",
						hide: false,
					},
					{
						label: "Registered (Awaiting Docs)",
						value: "4",
						hide: false,
					},
					{
						label: "Agreements Signed (Awaiting Payment)",
						value: "6",
						hide: false,
					},
					{
						label: "Fee Plan Sent to Caller",
						value: "7",
						hide: false,
					},
					{
						label: "Payment Received from Caller",
						value: "8",
						hide: false,
					},
					{
						label: "Payment Received",
						value: "9",
						hide: false,
					},
				]);
				setfilteredSearchCriteria("");
			}
		}
		else if (location.pathname === "/client-enquiry-forceclosed-list") {
			if(tablePayload === null || filterPayload === null){
				form.resetFields();
				setUserStatusCheckbox([
					{
						label:"New",
						value:"0",
						hide: false,
					},
					{
						label:"Parent Consent Requested",
						value:"1",
						hide: false,
					},
					{
						label:"Parent Consent Received",
						value:"2",
						hide: false,
					},
					{
						label:"Registration Link Sent",
						value:"3",
						hide: false,
					},
					{
						label:"Registered (Awaiting Docs)",
						value:"4",
						hide: false,
					},
					{
						label:"Agreements Signed (Awaiting Payment)",
						value:"6",
						hide: false,
					},
					{
						label:"Fee Plan Sent to Caller",
						value:"7",
						hide: false,
					},
					{
						label:"Payment Received from Caller",
						value:"8",
						hide: false,
					},
					{
						label:"Payment Received",
						value:"9",
						hide: false,
					},
				]);
				setfilteredSearchCriteria("");
			}
			client_enquiry_list_type="3";
			setClientEnquiryType("Force Closed");
		}
		else if (location.pathname === "/client-enquiry-open-list"){
			client_enquiry_list_type="1";
			setClientEnquiryType("Open");
			if (tablePayload === null || filterPayload === null) {
				form.resetFields();
				let newArray = userStatusCheckbox.filter(item => item.value !== "9");
				setUserStatusCheckbox(newArray);
				setfilteredSearchCriteria("");
			}
		}
		let payload = { type: client_enquiry_list_type };
		if(loginType === "2"){
			payload.role_id = 2;
			payload.login_id = loginID;
		}
		if (tablePayload !== null){
			let mergepayload = {...payload,...tablePayload}
			payload = mergepayload;
		}
		if (filterPayload !== null){
			let mergepayload = {...payload,...tablePayload,...filterPayload}
			payload = mergepayload;
		};
		fetchAdminApi(`/client-enquiry`, "post",payload).then((res) => {
			if (res && res.code && res.code === 200 && res.data) {
				setloader(false);
				setTableData(res.data);
				setFilterTable(res.data);
			}else{
				setTableData([]);
				setFilterTable([]);
				setloader(false);
			}
		});
	};
	const onSearch = (event) => {
		let search = event.target.value;
		if (search !== null || search !== "") {
			let tempData = filterTable.length > 0 ? filterTable.filter((searchField) =>
				searchField?.parent_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
				searchField?.parent_email?.toLowerCase()?.includes(search?.toLowerCase()) ||
				searchField?.parent_contact_number?.toLowerCase()?.includes(search?.toLowerCase()) ||
				searchField?.client_email?.toLowerCase()?.includes(search?.toLowerCase()) ||
				searchField?.client_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
				searchField?.client_contact_number?.toLowerCase()?.includes(search?.toLowerCase())||
				searchField?.caller_name?.toLowerCase()?.includes(search?.toLowerCase())||
				searchField?.source_details?.toLowerCase()?.includes(search?.toLowerCase())
			) : [];
			setTableData(tempData);
		}
	};
	const routeToEditClientEnquiry = (enquiry_id,record) => {
		let pathname;
		let state;
		if(record.status === "4" || record.status === "9"){
			sessionStorage.removeItem("enquiryDetails");
			pathname = "/edit-client";
			state = { client_id: record?.client_id };
		}else{
			sessionStorage.setItem("enquiryDetails", JSON.stringify(record));
			pathname = "/edit-enquiry";
			state = { enquiry_id: enquiry_id };
		}
		history.push({
			pathname: pathname,
			state: state
		});
	};
	const columns = [
		{
			title: '#',
			align:"center",
			dataIndex: 'enquiry_id',
			key: 'enquiry_id',
			render:(enquiry_id)=>{
				return <span className="text-right w-8 block m-auto">{enquiry_id}</span>;
			},
			width: "5%",
			sorter: () => { }
		},
		{
			title: 'Name',
			dataIndex: 'client_name',
			key: 'client_name',
			render:(client_name,record) => {
				return client_name ? <a onClick={() => routeToEditClientEnquiry(record?.enquiry_id,record)}>
					<Space>
						<SearchOutlined/>{client_name}
						{record?.is_refresher === 1 && <ReloadOutlined/>}
					</Space></a> : "";
			},
			width: "20%",
			sorter: () => {}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: "20%",
			sorter: () => {},
			render: (status,record) => {
				let loginType = getCookie(COOKIE.LoginType);
				let associateFullName = record?.associate_name;
				let namesArray = associateFullName?.split(' ');
				let initialFN = namesArray?.[0]?.charAt(0);
				let initialLN = namesArray?.[1]?.charAt(0);
				switch (status) {
					case "1":
						return <Space>
							<span>Parent Consent Requested</span>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "2":
						return <Space>
							<span><DeploymentUnitOutlined onClick={() => handleStatus(record)} /></span>
							<a><span onClick={() => handleStatus(record)}>Parent Consent Received</span></a>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "3":
						return <Space>
							<span>Registration Link Sent</span>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "4":
						return <Space>
							<span>Registered (Awaiting Docs)</span>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "5":
						return <Space>
							<span>Refresher link sent</span>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "6":
						return <Space>
							<a onClick={() => routeToEditClientEnquiry(record?.enquiry_id, record)}><span>Agreements Signed (Awaiting Payment)</span></a>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "7":
						return <Space>
							<span>Fee Plan Sent to Caller </span>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "8":
						return <Space>
							<a><span onClick={() => handleStatus(record, "caller")}>Payment Received from Caller </span></a>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "9":
						return <Space>
							<span>Payment received</span>
							{loginType === "1" && associateFullName !== null && record?.associate_role !== "1" && (<Tooltip title={associateFullName}><span>{"->" + " " + initialFN + initialLN}</span></Tooltip>)}
						</Space>;
					case "0":
						return <Space>
							<span><DeploymentUnitOutlined onClick={() => handleStatus(record)} /></span>
							<a><span onClick={() => handleStatus(record)}>New</span></a>
						</Space>;
				}
			},
		},
		{
			title: 'Contact No.',
			dataIndex: 'client_contact_number',
			key: 'client_contact_number',
			width: "10%",
			render: (client_contact_number,record) => {
				if(record?.caller_contact_number){
					return <span>{record?.caller_contact_number}</span>
				}else{
					if(client_contact_number !== "00000"){
						return <span>{client_contact_number}</span>
					}else{
						return <span>-</span>
					}
				}
			}
		},
		{
			title: 'Email',
			dataIndex: 'client_email',
			key: 'client_email',
			width:"5%",
			align:"center",
			render: (client_email) => {
				return <Tooltip title={client_email}><MailOutlined onClick={ ()=>copyToClipboard(client_email)}/></Tooltip>;
			}
		},
		{
			title: 'Parent Name',
			dataIndex: 'parent_name',
			key: 'parent_name',
			width:"10%",
			sorter: () => {},
		},
		{
			title: 'Parent Contact No.',
			dataIndex: 'parent_contact_number',
			key: 'parent_contact_number',
			width:"12%"
		},
		{
			title: 'For',
			dataIndex: 'is_minor',
			key: 'is_minor',
			width:"5%",
			sorter: () => {},
			render:(is_minor) => {
				return <span>{is_minor === "0"? <Tooltip title="Adult"><span>A</span></Tooltip>: <Tooltip title="Minor"><span>M</span></Tooltip>}</span>;
			},
		},
		{
			title: 'Date of Enquiry',
			dataIndex: 'dt_created',
			key: 'date',
			width: "8%",
			render: (record) => {
				return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
			},
		},
	];
	const copyToClipboard = (email_id) => {
		const el = document.createElement('textarea');
		el.value = email_id;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};
	const handleStatus = (record,paidBy=null) => {
		let payload ={};
		payload = record;
		payload.userType = "new";
		if(paidBy){
			payload.paidBy = paidBy;
		}
		sessionStorage.setItem("enquiryDetails", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/registration-plans");
	};
	const getRecordMessage = () => {
		let record_message;
		if(tableData.length > 1){
			record_message = tableData.length + " " + "records found.";
		}else if (tableData.length === 0){
			record_message = "No records found.";
		}else{
			record_message = tableData.length + " " + "record found.";
		}
		return record_message;
	};
	const onChangeTable = (pagination, filters, sorter)=>{
		setPagination(pagination);
		let payload = {};
		let sortorder = null;
		let column_key = null;
		let sort_column = {};
		if(sorter){
			//set variables
			sortorder = sorter.order;
			column_key = sorter.columnKey;
			//serial number-enquiry id
			if (sortorder === "descend" && column_key === "enquiry_id") {
				payload.sort_column = "enquiry_id";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "enquiry_id") {
				payload.sort_column = "enquiry_id";
				payload.sort_type = "ASC";
			};
			//client_name
			if (sortorder === "descend" && column_key === "client_name"){
				payload.sort_column = "concat(client_firstname, ' ', client_lastname)";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "client_name"){
				payload.sort_column = "concat(client_firstname, ' ', client_lastname)";
				payload.sort_type = "ASC";
			};
			//status
			if (sortorder === "descend" && column_key === "status"){
				payload.sort_column = "status";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "status"){
				payload.sort_column = "status";
				payload.sort_type = "ASC";
			};
			//parent_name
			if (sortorder === "descend" && column_key === "parent_name"){
				payload.sort_column = "concat(parent_firstname, ' ', parent_lastname)";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "parent_name"){
				payload.sort_column = "concat(parent_firstname, ' ', parent_lastname)";
				payload.sort_type = "ASC";
			};
			//is_minor
			if (sortorder === "descend" && column_key === "is_minor"){
				payload.sort_column = "is_minor";
				payload.sort_type = "DESC";
			};
			if (sortorder === "ascend" && column_key === "is_minor"){
				payload.sort_column = "is_minor";
				payload.sort_type = "ASC";
			};
		};
		setSortPayload(payload);
		getClientEnquiryList(payload, advanceFilterPayload);
	};
	const getNamebyId = (id,res,type) => {
		// Return the name if the object is found, otherwise return a default value
		let foundItem; 
		if(type === "country"){
			foundItem = res.find(item => item.country_id === id);
			return foundItem ? foundItem.country_name : '';
		}
		else if (type === "status"){
			foundItem = res.find(item => item.value === id);
			return foundItem ? foundItem.label : '';
		}
		else{
			res=res[stateKey].states;
			foundItem = res.find(item => item.region_id === id);
			return foundItem ? foundItem.region_name : '';
		};
	};
	const onFinishFilterSearch = (values) => {
		setIsAdvancetFilterOpen(!isAdvanceFilterOpen);
		let payload = {};
		let searchCriteriaMessage = [];
		if (values.country_id) {
			payload.country_id = values.country_id;
			searchCriteriaMessage.push(`Country: ${getNamebyId(values.country_id, listCountries, "country")}`);
		};
		if (values.region_id) {
			payload.region_id = values.region_id;
			searchCriteriaMessage.push(`State: ${getNamebyId(values.region_id, listStates, "state")}`);
		};
		if (values.city) {
			payload.city = values.city;
			searchCriteriaMessage.push(`City: ${values.city}`);
		};
		if (values?.status?.length>0) {
			payload.status = values?.status?.toString();
			if(values?.status?.length > 0){
				let statuslist = [];
				for (let index = 0; index < values?.status?.length; index++) {
					const statusval = values?.status[index];
					statuslist.push(getNamebyId(statusval, userStatusCheckbox, "status"));
				};
				let resultString = statuslist.join(', ');
				searchCriteriaMessage.push(`Status: ${resultString}`);
			};
		};
		if (values.is_minor) {
			let resultString = values.is_minor.join(', ');
			if (values.is_minor.length > 0 && values.is_minor.length <= 1) {
				payload.is_minor = values.is_minor[0] === "Adult" ? "0" : "1";
				searchCriteriaMessage.push(`For: ${resultString}`);
			} else if (values.is_minor.length > 1) {
				searchCriteriaMessage.push(`For: ${resultString}`);
			}
		};
		if (date.startDate.date && date.startDate.month && date.startDate.year) {
			payload.start_date = `${date.startDate.year}-${date.startDate.month}-${date.startDate.date}`;
		};
		if (date.endDate.date && date.endDate.month && date.endDate.year) {
			payload.end_date = `${date.endDate.year}-${date.endDate.month}-${date.endDate.date}`;
		};
		setAdvanceFilterPayload(payload);
		if (date.startDate.date || date.startDate.month || date.startDate.year || date.endDate.date || date.endDate.month || date.endDate.year) {
			if (date.startDate.date && date.startDate.month && date.startDate.year || date.endDate.date && date.endDate.month && date.endDate.year) {
				getClientEnquiryList(sortPayload, payload);
				if ((date.startDate.date && date.startDate.month && date.startDate.year) && (date.endDate.date && date.endDate.month && date.endDate.year)) {
					searchCriteriaMessage.push(`Enquiry from ${moment(`${date.startDate.year}-${date.startDate.month}-${date.startDate.date}`).format(constants.dateformat)} to ${moment(`${date.endDate.year}-${date.endDate.month}-${date.endDate.date}`).format(constants.dateformat)}`);
				} else {
					if (date.startDate.date && date.startDate.month && date.startDate.year) {
						searchCriteriaMessage.push(`Enquiry from ${moment(`${date.startDate.year}-${date.startDate.month}-${date.startDate.date}`).format(constants.dateformat)}`);
					}
					if (date.endDate.date && date.endDate.month && date.endDate.year) {
						searchCriteriaMessage.push(`Enquiry from ${moment(`${date.endDate.year}-${date.endDate.month}-${date.endDate.date}`).format(constants.dateformat)}`);
					}
				}
				if (searchCriteriaMessage.length >= 1) {
					setfilteredSearchCriteria(searchCriteriaMessage.join(". ") + ".");
				} else {
					setfilteredSearchCriteria(searchCriteriaMessage.join(". "));
				};
			} else {
				message.error("Please fill proper date.")
			}
		} else {
			getClientEnquiryList(sortPayload, payload);
			if (searchCriteriaMessage.length >= 1) {
				setfilteredSearchCriteria(searchCriteriaMessage.join(". ") + ".");
			} else {
				setfilteredSearchCriteria(searchCriteriaMessage.join("." + " "));
			};
		}
	};
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
			} else {
				setCountries([]);
			}
		});
	};
	const fetchStates = (country_code) => {
		fetchApi(`/states/${country_code}`, "get").then(
			(res) => {
				if (res?.data) {
					setTempState(res?.data);
					setListStates(res.data);
				} else {
					setListStates(null);
					setTempState(null);
				}
			}
		);
	};
	const getCitiesFromDb = () => {
		fetchApi(`/cities`, "get").then(
			(res) => {
				if (res?.data) {
					setlistCities(res?.data);
				} else {
					setlistCities([]);
				}
			}
		);
	};
	const onChangeCountry = (option,val) => {
		fetchStates(val.key);
		setStateKey(val.key);
		form.setFieldsValue({ state: null });
	};
	const handleSearchCity = (value) => {
		setCityOptions(
      !value
        ? []
				: listCities,
    );
	};
	const selectFilterOption = (input,option)=>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	const onChangeIsMinor = (checkedValues) => {
		console.log('checked = ', checkedValues);
	};
	const filterReset = () => {
		setfilteredSearchCriteria("");
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate: {
				...prevEnquiryDate.startDate,
				date: null,
				month: null,
				year: null
			},
			endDate: {
				...prevEnquiryDate.endDate,
				date: null,
				month: null,
				year: null
			}
		}));
		setAdvanceFilterPayload(null);
		form.resetFields();
		getClientEnquiryList(sortPayload);
	};
	//from date onchange
	const handleDayChangeFD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate: {
				...prevEnquiryDate.startDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeFD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate:{
				...prevEnquiryDate.startDate,
				month:value,
			},
		}));
	};
	const handleYearChangeFD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			startDate:{
				...prevEnquiryDate.startDate,
				year:value,
			},
		}));
	};
	//to date onchange
	const handleDayChangeTD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			endDate: {
				...prevEnquiryDate.endDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeTD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			endDate:{
				...prevEnquiryDate.endDate,
				month:value,
			},
		}));
	};
	const handleYearChangeTD = (value) => {
		setDate((prevEnquiryDate) => ({
			...prevEnquiryDate,
			endDate:{
				...prevEnquiryDate.endDate,
				year:value,
			},
		}));
	};
	const getEmailId = () => {
		fetchApi(`/client-profile/${clientId}`, "get").then((res) => {
			if (res && res.data && res.code === 200) {
				setEmailId(res?.data?.email);
			} else {
				setEmailId("");
			};
		});
	};
	const onclickHere = () => {
		window.open(constants.BaseUrl + "/json-enquiry");
	};

	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ClientEL} ({clientEnquiryType})</span></div>
			<div className='container mx-auto'>
				<div className='mt-2'>
					<Space>
						<Form.Item label="Search" className="mb-0">
							<Input className='w-full lg:w-52' size="middle" onChange={onSearch} />
						</Form.Item>
						<span className='font-semibold text-base'>{getRecordMessage()}</span>
						<Tooltip title={constants.Filter}><Button className="bg-sky-800 text-white" onClick={advancedFilter} icon={<FilterOutlined />}></Button></Tooltip>
						{loginType === "1" && emailId === "demoa5267@gmail.com" || loginType === "1" && emailId === "admin@mailinator.com" || loginType === "1" && emailId === "roy@mailinator.com" ? <a style={{ visibility: "hidden" }} onClick={onclickHere}>enquiry logs</a> : null}
					</Space>
					{((loginType === "1" && emailId === "demoa5267@gmail.com") || (loginType === "1" && emailId === "roy@mailinator.com") || (loginType === "1" && emailId === "Roy@mailinator.com")) ? <Tooltip title={constants.Delete}><Button style={{ visibility: "hidden" }} className="float-right bg-sky-800 text-white" onClick={() => { history.push({ pathname: "/delete-enquiry" }); }}>{constants.Delete}</Button></Tooltip> : null}
					{(location.pathname === "/client-enquiry-open-list" || location.pathname === "/client-enquiry-forceclosed-list") && loginType === "1" && (<Tooltip title={`${constants.ClientEL} (${constants.All})`}><Button className="float-right bg-sky-800 text-white" onClick={() => { window.open(`${constants.BaseUrl}/client-enquiry-list-all`, '_blank'); }}>{constants.ClientEL} ({constants.All})</Button></Tooltip>)}
					{location.pathname === "/client-enquiry-forceclosed-list" && loginType === "1" && emailId === "demoa5267@gmail.com" && (<Tooltip title={`${constants.RearrangeEnq}`}><Button style={{ visibility: "hidden" }} className="float-right bg-sky-800 text-white" onClick={() => { window.open(`${constants.BaseUrl}/rearrange-enquiry`, '_blank'); }}>{constants.RearrangeEnq}</Button></Tooltip>)}
					<Tooltip title={constants.ExportToWord}><Button style={{ visibility:"hidden" }} className="float-right bg-sky-800 text-white" icon={<FileExcelOutlined/>}>{constants.ExportToWord}</Button></Tooltip>
					{filteredSearchCriteria ? <div className='text-lg font-bold mt-2.5 text-sm'>Search Criteria: {filteredSearchCriteria}</div> : null}
				</div>
				{
					isAdvanceFilterOpen ?
						<div className='mt-2 p-4 bg-white client-details'>
							<Form className='border-solid label-fs-xs form-mb-0' layout="vertical" form={form} onFinish={onFinishFilterSearch}>
								<Row gutter={[48, 8]}>
									<Col span={8}>
										<Form.Item
											label={constants.country}
											name="country_id"
										>
											<Select
												onChange={onChangeCountry}
												placeholder={"Country"}
												className="w-100"
												showSearch
												allowClear
												filterOption={selectFilterOption}>
												{listCountries &&
													listCountries.map((listCountry) => (
														<Option key={listCountry.country_code} value={listCountry.country_id}>
															{listCountry.country_name}
														</Option>
													))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={constants.state}
											name="region_id"
										>
											<Select
												placeholder={constants.state}
												allowClear
												showSearch
												filterOption={selectFilterOption}
											>
												{tempState ?
													Object.entries(tempState).map(([key, value]) => (
														<>
															{value &&
																value.states &&
																value.states.length > 0 &&
																value.states.map((state) => (
																	<Option
																		key={state.region_id}
																		value={state.region_id}
																		region={state.region_id}
																	>
																		{state.region_name}
																	</Option>
																))}
														</>
													)) :
													listStates &&
													Object.entries(tempState ? tempState : listStates).map(([key, value]) => (
														<>
															{value &&
																value.states &&
																value.states.length > 0 &&
																value.states.map((state) => (
																	<Option
																		key={state.region_id}
																		value={state.region_id}
																		region={state.region_id}
																	>
																		{state.region_name}
																	</Option>
																))}
														</>
													))
												}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={constants.city} name="city" >
											<AutoComplete
												options={cityOptions}
												// onSelect={onSelectCity}
												onSearch={handleSearchCity}
												filterOption={(inputValue, option) =>
													option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1 && option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) < 1
												}
											>
												<Input
													placeholder= {constants.city}
												/>
											</AutoComplete>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											label={"Status"}
											name={"status"}
										>
											<Select
												placeholder={"Status"}
												className="w-100"
												showSearch
												allowClear
												mode="multiple"
												filterOption={selectFilterOption}>
												{userStatusCheckbox &&
													userStatusCheckbox.map((status,key) => (
														<Option key={key} value={status.value}>
															{status.label}
														</Option>
													))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										{DOB(date.startDate.month,handleMonthChangeFD,date.startDate.date,handleDayChangeFD,date.startDate.year,handleYearChangeFD,"Enquiry From",null)}
									</Col>
									<Col span={8}>
										{DOB(date.endDate.month,handleMonthChangeTD,date.endDate.date,handleDayChangeTD,date.endDate.year,handleYearChangeTD,"Enquiry To",null)}
									</Col>
									<Col span={8}>
										<Form.Item
											label={"For"}
											name={"is_minor"}
										>
											<Checkbox.Group onChange={onChangeIsMinor} >
												<Checkbox className='!items-start' value={"Adult"}>Adult</Checkbox>
												<Checkbox className='!items-start' value={"Minor"}>Minor</Checkbox>
											</Checkbox.Group>
										</Form.Item>
									</Col>
								</Row>
								<Divider className="my-3" />
								<div className="text-right">
									<Space >
										<Form.Item className="mb-1">
											<Button htmlType="submit" className='bg-sky-800 text-white' icon={<SearchOutlined />}>Search</Button>
										</Form.Item>
										<Form.Item className="mb-1">
											<Button onClick={() => { filterReset(); }} icon={<ReloadOutlined />}>Reset</Button>
										</Form.Item>
										<Form.Item className="mb-1">
											<Button className='bg-red-600 text-white' onClick={() => { setIsAdvancetFilterOpen(!isAdvanceFilterOpen); }} icon={<CloseOutlined />}>Close</Button>
										</Form.Item>
									</Space>
								</div>
							</Form>
						</div> :
						null
				}
				<Spin spinning={loader} size="large">
				<Table
					pagination={pagination}
					dataSource={tableData}
					columns={columns}
					bordered
					scroll={{ x: 900 }}
					locale={{ emptyText: 'No Records Found' }}
					onChange={onChangeTable}
					className='mt-2'
					size="small"
				/>
				</Spin>
			</div>
		</div>
	);
};
export default ClientEnquiryList;