/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Input, Form, Row, Col, Button, Select, message, Tooltip, Space, Card, Radio } from 'antd';
import constants from '../../../constants/constants';
import { fetchApi } from "../../../services/api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { removeExtraSpaces,DOB } from "../../../globalFunctions/GlobalFunctions";
import { useHistory } from "react-router";
import { COOKIE, getCookie } from "../../../services/cookie";
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
const { Option } = Select;
const MyProfile = (props) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [countryPhoneCode, setcountryPhoneCode] = useState("");
	const [passwordStatus, setPasswordStatus] = useState("");
	const [form] = Form.useForm();
	const [emailValidateStatus, setEmailValidateStatus] = useState("");
	const [emailIdValue, setEmailIdValue] = useState(null);
	const [passwordRequired, setPasswordRequired] = useState(true);
	const [validationErrormessages, setValidationErrorMessages] = useState([
		{
			IsError: false,
			errorMessage: ""
		},
		{
			IsError: false,
			errorMessage: ""
		},
		{
			IsError: false,
			errorMessage: ""
		},
	]);
	const [listCountries, setCountries] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [tempState, setTempState] = useState([]);
	const [styles, setStyles] = useState(null);
	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [clientDetails, setClientDetails] = useState({});
	const loginType = getCookie(COOKIE.LoginType);
	const [gender, setGender] = useState(""); 
	useEffect(() => {
		fetchCountries();
		getClientData();
	}, []);
	//Function to get dashboard data
	const getClientData = () => {
		let clientId = getCookie(COOKIE.ClientId);
		fetchApi(`/client-profile/${clientId}`, "get").then((res) => {
			if (res && res.data && res.code === 200) {
				setClientDetails(res.data);
				setFormFieldValues(res.data);
			} else {
				message.error(res.message);
				setClientDetails({});
			}
		});
	};
	//Function to set form values
	const setFormFieldValues = (values) => {
		let optionTemp = {};
		optionTemp.key = values.country_code;
		onChangeCountry(null,optionTemp);
		setYear(values?.dob?.year);
		setMonth(values?.dob?.month);
		setDay(values?.dob?.day);
		setGender(values?.gender);
		form.setFieldsValue({
			firstName: values.firstname,
			lastName: values.lastname,
			email: values.email,
			country: values.country_id,
			state: values.region_id,
			city: values.city,
			contactNo: values.contact_no,
			gender:values?.gender,
			gender_details:values?.gender_details
		});
	};
	//Function to validate password to check if it has 1 special character, 1 Upper case, 1 number and should have 8 characters
	const handlePasswordValidation = (rule, value, callback) => {
		try {
			if (value) {
				let passwordValidate = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).*$/g;
				let check = passwordValidate.test(value);
				if (check === false) {
					setPasswordStatus("error");
					throw new Error(constants.PsdTip);
				} else {
					setPasswordStatus("success");
				}
				let passwordSpaceValidate = /^\S*$/;
				let spaceCheck = passwordSpaceValidate.test(value);
				if (spaceCheck === false) {
					setPasswordStatus("error");
					throw new Error(constants.PsdTip);
				}
			} else {
				setPasswordStatus("error");
				throw new Error(constants.PasswordRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//To Submit the values from the registration form to the API.
	const onFinish = (values) => {
		setLoading(true);
		let clientId = getCookie(COOKIE.ClientId);
		let payload = null;
		payload = {
			firstname: removeExtraSpaces(values.firstName),
			lastname: removeExtraSpaces(values.lastName),
			contact_no: values.contactNo,
			email: values.email,
			// login_password: values.password,
			// confirm_password: values.confirmPassword,
			country_id: values.country,
			region_id: values.state,
			city: removeExtraSpaces(values.city),
			client_type: 1,
			dob:`${year}-${month}-${day}`,
			gender:values?.gender,
			gender_details:values?.gender_details
		};
		if (values.password) {
			payload.login_password = values.password;
		}
		fetchApi(`/client-profile/${clientId}`, "put", payload).then((res) => {
			setLoading(false);
			if (res && res.code && res.code === 200) {
				message.success(res.message);
			} else {
				message.error(res.message);
			}
			let fromsectionzero = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
			if (loginType === "0") {
				if (fromsectionzero?.new_user) {
					sessionStorage.setItem("count", props?.location?.state?.counter)
					history.push("workbook-section-0")
				} else {
					history.push("/dashboard");
				}
			} else if (loginType === "3") {
				history.push("/image-list");
			}
			else {
				history.push("/client-list-open");
			};
		});
	};
	//Function to check validation errors on click of register button
	const onFinishFailed = (values) => {
		if ((values && values.values && values.values.contactNo === null) ||
			(values && values.values && values.values.contactNo === undefined) ||
			(values && values.values && values.values.contactNo === "")) {
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}
	};
	const handleValidateName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:"\/\[\]\|{}()=+]/;
				if (nameValidate.test(value) === true) {
					throw new Error(constants.FirstNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateLastName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:"\/\[\]\|{}()=+]/;
				if (nameValidate.test(value) === true) {
					throw new Error(constants.LastNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
			} else {
				setCountries([]);
			}
		});
	};
	const fetchStates = (country_code) => {
		fetchApi(`/states/${country_code}`, "get").then(
			(res) => {
				if (res?.data) {
					setTempState(res?.data);
					setListStates(res.data);
				} else {
					setListStates(null);
				}
			}
		);
	};
	const selectFilterOption = (input, option) => {
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	const onChangeCountry = (val,option) => {
		fetchStates(option?.key);
		setcountryPhoneCode(option?.key);
		form.setFieldsValue({ state: null });
	};
	const handleCheckEmail = () => {
		let payload = {};
		if (emailIdValue) {
			payload.email = emailIdValue;
		}
		fetchApi("/client/register/check-emails", "post", payload).then((res) => {
			if (res?.code === 200) {
				setEmailValidateStatus("success");
			} else {
				setEmailValidateStatus("error");
				message.error(res.message?.email);
			}
		});
	};
	//Function to check the contact number and set validation error using css
	const handleContactNo = (value) => {
		if (value === "" || value === null || value === undefined) {
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		} else {
			setStyles(null);
		}
	};
	// const handlePasswordChange=(e)=>{
	// 	if(e.target.value){
	// 		setPasswordRequired(true);
	// 	}else{
	// 		setPasswordRequired(false);
	// 	}
	// };
	const handleDayChange = (value) => {
		setDay(value);
	};
	const handleMonthChange = (value) => {
		setMonth(value);
	};
	const handleYearChange = (value) => {
		setYear(value);
	};
	const redirectToFilesListing = () => {
		sessionStorage.clear();
		let payload = {
			clientId:  clientDetails?.client_id,
			client_serial_number: clientDetails?.client_serial_number,
			client_name: clientDetails?.firstname + " " + clientDetails?.lastname
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/documents");
	};
	const redirectToFriendsFamilyListing = () => {
		sessionStorage.clear();
		let payload = {
			clientId:  clientDetails?.client_id,
			client_serial_number: clientDetails?.client_serial_number,
			client_name: clientDetails?.firstname + " " + clientDetails?.lastname
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/family-and-friends");
	};
	const getMedicalForm = () => {
		sessionStorage.clear();
		let payload = {
			clientId:  clientDetails?.client_id,
			client_name: clientDetails?.firstname + " " + clientDetails?.lastname,
			is_medical_info : true
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/medical-info");
	};
	const onChangeGender = (e) => {
		setGender(e.target.value);
	};
	const handleCancel = () => {
		let fromsectionzero = JSON.parse(sessionStorage.getItem("adminDiscussionValue"));
		if(loginType === "0"){
			if(fromsectionzero?.new_user){
				sessionStorage.setItem("count",props?.location?.state?.counter)
				history.push("workbook-section-0")
			}else{
				history.push("/dashboard");
			}
		} else if (loginType === "3") {
			history.push("/image-list");
		}else{
			history.push("/client-list-open");
		};
	};

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};

	const tailLayout = { wrapperCol: { span: 24 } };

	const handleValidatePhone = (rule, value, callback) => {
		try {
			if (value) {
				let phoneValidate = /^(?=.{5,})\S*$/;
				if (phoneValidate.test(value) === false) {
					const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
					setStyles(stylesValidation);
					throw new Error(constants.PhoneNoRegistration);
				} else {
					setStyles(null);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};


	return (
		<div>
			<Row>
				<Col lg={24}>
					<div className="mx-auto my-8 w-3/4">
						<div className="flex justify-between items-center">
							<span className='text-3xl font-semibold'>{constants.MyProfileText}</span>
							{/* {
								loginType === "0" ?
									<Button
										size="large"
										className='bg-sky-800 text-white uppercase'
										onClick={redirectToFriendsFamilyListing}
									>
										{constants.FamilyAndFriends}
									</Button> : null
							} */}
						</div>
						<br/>
						<Form
							layout="vertical"
							className='label-style'
							form={form}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Row gutter={[24, 0]}>
								<Col xs={24} lg={12}>
									<Form.Item
										name="firstName"
										label={constants.FirstName}
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
									>
										<Input autoFocus placeholder={constants.FirstName}/>
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item
										label={constants.LastName}
										name="lastName"
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}>
										<Input placeholder={constants.LastName}/>
									</Form.Item>
								</Col>
							</Row>
							<Form.Item
								name="email"
								label={constants.Email}
								rules={[
									{
										required: true,
										message: constants.EmailRegistration,
									},
									{
										type: 'email',
										message: constants.emailVaidationError,
									},
								]}
							>
								<Input
									placeholder={constants.EmailPlaceholder}
									// onBlur={() => { handleCheckEmail(); }}
									readOnly={true}
								/>
							</Form.Item>
							<Card
								title={
									<div >
										{
											passwordRequired === true ?
												<Tooltip title="Edit Password">Edit Password{" "}<Button icon={<EditOutlined/>} onClick={() => setPasswordRequired(false)}></Button></Tooltip> :
												<Tooltip title="Cancel">Edit Password{" "}<Button icon={<CloseOutlined/>} onClick={() => setPasswordRequired(true)}></Button></Tooltip>
										}
									</div>
								}
							>
								<Form.Item
									name="password"
									label={constants.Password}
									// validateStatus={passwordStatus}
									rules={[
										{
											required: !passwordRequired,
											message: " ",
										},
										// { validator: handlePasswordValidation },
									]}
								// tooltip={constants.PsdTip}
								>
									<Input.Password placeholder={constants.Password}
										// onChange={handlePasswordChange} 
										disabled={passwordRequired}/>
								</Form.Item>
								<Form.Item
									name="confirmPassword"
									label={constants.ConfirmPsd}
									dependencies={["password"]}
									// validateStatus={passwordStatus}
									rules={[
										{
											required: !passwordRequired,
											message: constants.ConfirmPsd,
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue("password") === value) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														constants.PasswordNotMatch
													)
												);
											},
										}),
									]}
								>
									<Input.Password placeholder={constants.ConfirmPsd} disabled={passwordRequired}/>
								</Form.Item>
							</Card>
							<Form.Item
								label={constants.country}
								name="country"
								rules={[
									{
										required: true,
										message: constants.CountryRegistration,
									},
								]}
							>
								<Select
									onChange={onChangeCountry}
									placeholder={"Country"}
									className="w-100"
									showSearch
									allowClear
									filterOption={selectFilterOption}>
									{listCountries &&
										listCountries.map((listCountry) => (
											<Option key={listCountry.country_code} value={listCountry.country_id}>
												{listCountry.country_name}
											</Option>
										))}
								</Select>
							</Form.Item>
							<Form.Item
								label={constants.state}
								name="state"
								rules={[
									{
										required: true,
										message: constants.StateRegistration,
									},
								]}
							>
								<Select
									// value={u_id}
									placeholder={constants.state}
									allowClear
									showSearch
									// onChange={onChangeUniversity}
									filterOption={selectFilterOption}
								>
									{tempState ?
										// eslint-disable-next-line no-unused-vars
										Object.entries(tempState).map(([key, value]) => (
											<>
												{value &&
													value.states &&
													value.states.length > 0 &&
													value.states.map((state) => (
														<Option
															key={state.region_id}
															value={state.region_id}
															region={state.region_id}
														>
															{state.region_name}
														</Option>
													))}
											</>
										)) :
										listStates &&
										// eslint-disable-next-line no-unused-vars
										Object.entries(tempState ? tempState : listStates).map(([key, value]) => (
											<>
												{value &&
													value.states &&
													value.states.length > 0 &&
													value.states.map((state) => (
														<Option
															key={state.region_id}
															value={state.region_id}
															// universityN={state.university_name}
															region={state.region_id}
														>
															{state.region_name}
														</Option>
													))}
											</>
										))
									}
								</Select>
							</Form.Item>
							<Form.Item
								label={constants.city}
								name="city"
								rules={[
									{
										required: true,
										message: constants.CityRegistration,
									},
								]}
							>
								<Input
									placeholder={constants.city}
									// onChange={handleChangeCity}
									type="text"
								// value={cityMessage}
								/>
							</Form.Item>
							<Form.Item
								label={constants.phone}
								name="contactNo"
								rules={[
									{
										required: true,
										message: constants.RequiredField,
									},
								]}
							>
								<PhoneInput preferredCountries={["us","in"]} className='w-full' country={countryPhoneCode ? countryPhoneCode.toLocaleLowerCase() : "in"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567"/>
							</Form.Item>
							{DOB(month,handleMonthChange,day,handleDayChange,year,handleYearChange,constants.dob)}
							<Form.Item
								label={constants.Gender}
								name="gender"
								rules={[
									{
										required: true,
										message: constants.GenderVaidationMessage,
									},
								]}
							>
								<Radio.Group  onChange={onChangeGender} value={gender}>
									<Radio value={constants.Male}>{constants.Male}</Radio>
									<Radio value={constants.Female}>{constants.Female}</Radio>
									<Radio value={constants.Other}>{constants.Other}</Radio>
								</Radio.Group>
							</Form.Item>
							{gender === constants.Other && (
								<Form.Item
									name="gender_details"
								>
									<Input />
								</Form.Item>
							)}
							<Form.Item>
								<div className='float-right'>
									<Space>
										<Button
											size="large"
											className='bg-sky-800 text-white uppercase'
											onClick={handleCancel}
										>
											{constants.Cancel}
										</Button>
										<Button
											size="large"
											className='bg-sky-800 text-white uppercase'
											htmlType="submit"
											loading={loading}
										>
											{constants.UpdateBtn}
										</Button>
									</Space>
								</div>
							</Form.Item>
						</Form>
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default MyProfile;
