import React, { useEffect } from 'react';
import { Input, Button, Space, Form, message } from 'antd';
import { useHistory } from "react-router";
import { fetchApi } from "../../services/api";
const { TextArea } = Input;
const EditWbContentAndClientAns = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  let queryString = window.location.href;
  let url = queryString.substring(queryString.lastIndexOf('/') + 1);
  const isClientAns =  url === "edit-wb-client-ans" ? true : false;
  useEffect(() => {
    if(props?.location?.state?.serachparam){
      if(props?.location?.state?.serachparam?.is_client_ans){
        getWbContentAndClientAnswer(props?.location?.state?.serachparam?.answer_id,"client_ans");
      }else{
        getWbContentAndClientAnswer(props?.location?.state?.serachparam?.item_id,"wb_content");
      }
    }
  }, []);
  const getWbContentAndClientAnswer = (id,type) => {
    let payload = {};
    let url;
    if (type==="client_ans") {
      url = '/get-answers';
      payload.answer_id = id;
    }else{
      url = '/get-content';
      payload.item_id = id;
    }
    fetchApi(url, 'post', payload).then((res) => {
      if (res && res.code && res.code === 200) {
        form.setFieldsValue({
          item_id: res?.data[0]?.item_id,
          section_id: res?.data[0]?.section_id,
          section_type: res?.data[0]?.section_type,
          part_id: res?.data[0]?.part_id,
          display_order: res?.data[0]?.display_order,
          title: res?.data[0]?.title,
          content_left: res?.data[0]?.content_left,
          type_left: res?.data[0]?.type_left,
          content_right: res?.data[0]?.content_right,
          type_right: res?.data[0]?.type_right,
          template_id: res?.data[0]?.template_id,
        });
        if (type==="client_ans") {
          form.setFieldsValue({
            answer_id: res?.data[0]?.answer_id,
            client_id: res?.data[0]?.client_id,
            dt_checked: res?.data[0]?.dt_checked,
            dt_created: res?.data[0]?.dt_created,
            dt_last_discussed: res?.data[0]?.dt_last_discussed,
            dt_last_edited: res?.data[0]?.dt_last_edited,
            dt_updated: res?.data[0]?.dt_updated,
            json_data: res?.data[0]?.json_data,
            option_data: res?.data[0]?.option_data,
            text_data: res?.data[0]?.text_data
          })
        }
      } else {
        form.resetFields();
      }
    });
  };
  const onCancel = () => {
    let pathname = "/wb-contents";
    let state = {
      serachparam: props?.location?.state?.serachparam,
      searchType: props?.location?.state?.searchType
    }
    if (isClientAns) {
      pathname = "/client-answers"
      state = {
        serachparam: props?.location?.state?.serachparam,
      }
    }
    history.push({
      pathname: pathname,
      state: state
    });
  };
  const onFinishEdit = (val) => {
    let route;
    let method;
    let payload={};
    if(isClientAns){
      payload = {
        answer_id: val.answer_id ? val.answer_id: null ,
        client_id: val.client_id ? val.client_id : null,
        dt_checked: val.dt_checked ? val.dt_checked : null,
        dt_created: val.dt_created ? val.dt_created : null,
        dt_last_discussed: val.dt_last_discussed ? val.dt_last_discussed : null,
        dt_last_edited: val.dt_last_edited ? val.dt_last_edited : null,
        dt_updated: val.dt_updated ? val.dt_updated : null,
        json_data: val.json_data ? val.json_data : null,
        option_data: val.option_data ? val.option_data : null,
        text_data: val.text_data ? val.text_data : null
      };
      route=`/edit-answer`;
      method='put';
    }else{
      payload = {
        section_id: val.section_id ? val.section_id: "" ,
        section_type: val.section_type ? val.section_type : "",
        part_id: val.part_id ? val.part_id : "",
        display_order: val.display_order ? val.display_order : "",
        title: val.title ? val.title : "",
        content_left: val.content_left ? val.content_left : "",
        type_left: val.type_left ? val.type_left : "",
        content_right: val.content_right ? val.content_right : "",
        type_right: val.type_right ? val.type_right : "",
        template_id: val.template_id ? val.template_id : "",
      };
    }
    
    if(url === "edit-wb-content"){
      payload.item_id= val.item_id,
      route=`/edit-contents`
      method='put'
    }
    else if(url === "add-wb-content"){
      route=`/add-content`
      method='post'
    };
    fetchApi(route, method, payload).then((res) => {
      if (res && res.code && res.code === 200) {
        let pathname="/wb-contents";
        let state = {
          serachparam: props?.location?.state?.serachparam,
          searchType: props?.location?.state?.searchType
        }
        if(isClientAns){
          pathname="/client-answers"
          state={
            serachparam: props?.location?.state?.serachparam,
          }
        }
        history.push({
          pathname: pathname,
          state: state
        });
        message.success(res?.message);
      } else {
        message.error(res?.message);
      };
    });
  };
  return (
    <div className='container mx-auto'>
      <div className='mx-2 md:mx-6 md:mt-8'>
        <b className='text-center block'>{url === "edit-wb-content" ? "Edit Wb Content" : isClientAns ? "Edit Client Answers": "Add Wb Content"}</b>
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          form={form}
          onFinish={onFinishEdit}
        >
          {
            isClientAns ?
              <>
                <Form.Item label="answer_id" name={"answer_id"}>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label="client_id" name={"client_id"}>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label="item_id" name={"item_id"}>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label="json_data" name={"json_data"}>
                  <TextArea  />
                </Form.Item>
                <Form.Item label="dt_updated" name={"dt_updated"}>
                  <Input  />
                </Form.Item>
                <Form.Item label="option_data" name={"option_data"}>
                  <Input  />
                </Form.Item>
                <Form.Item label="text_data" name={"text_data"}>
                  <TextArea  />
                </Form.Item>
                <Form.Item label="dt_last_edited" name={"dt_last_edited"}>
                  <Input  />
                </Form.Item>
                <Form.Item label="dt_last_discussed" name={"dt_last_discussed"}>
                  <Input  />
                </Form.Item>
                <Form.Item  label="section_id" name={"section_id"}>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label="dt_created" name={"dt_created"}>
                  <Input  />
                </Form.Item>
                <Form.Item label="dt_checked" name={"dt_checked"}>
                  <Input  />
                </Form.Item>
              </>
              :
              <>
                {
                  url === "edit-wb-content" ?
                    <>
                      <Form.Item label="item_id" name={"item_id"}>
                        <Input disabled={true} />
                      </Form.Item>
                      
                    </> :
                    null
                }
                <Form.Item label="section_id" name={"section_id"}>
                  <Input />
                </Form.Item>
                <Form.Item label="section_type" name={"section_type"}>
                  <Input />
                </Form.Item>
                <Form.Item label="part_id" name={"part_id"}>
                  <Input />
                </Form.Item>
                <Form.Item label="display_order" name={"display_order"}>
                  <Input />
                </Form.Item>
                <Form.Item label="title" name={"title"}>
                  <Input />
                </Form.Item>
                <Form.Item label="content_left" name={"content_left"}>
                  <Input />
                </Form.Item>
                <Form.Item label="type_left" name={"type_left"}>
                  <Input />
                </Form.Item>
                <Form.Item label="content_right" name={"content_right"}>
                  <Input />
                </Form.Item>
                <Form.Item label="type_right" name={"type_right"}>
                  <Input />
                </Form.Item>
                <Form.Item label="template_id" name={"template_id"}>
                  <Input />
                </Form.Item>
              </>
          }
          <Form.Item>
            <Space>
              <Button size="middle"
                className='bg-sky-800 text-white'
                htmlType="submit">Save</Button>
              <Button size="middle"
                className='bg-sky-800 text-white' onClick={ onCancel}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default EditWbContentAndClientAns