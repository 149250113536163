import React, { useEffect, useState } from 'react';
import constants from '../../../constants/constants';
import { COOKIE, getCookie } from "../../../services/cookie";
import { Button, Form, Space, Table, Input, Spin, Tooltip, message, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { fetchApi } from '../../../services/api';
import PhoneInput from 'react-phone-input-2';

const FriendsFamilyListing = () => {
    const [form] = Form.useForm();
    const [familyList, setFamilyList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editEnquiryId, setEditEnquiryId] = useState("");
    const [emailIdValue, setEmailIdValue] = useState(null);
    const [styles, setStyles] = useState(null);
    const [isFamilyModalOpen, setIsFamilyModalOpen] = useState(false);
    const [pagination, setPagination] = useState(constants.paginationConfig);
    const [loader, setLoader] = useState(true);
    const [emailValidateStatus, setEmailValidateStatus] = useState("");

    const adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValueFam"));
    const loginType = getCookie(COOKIE.LoginType);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = { wrapperCol: { span: 24 } };

    useEffect(() => {
        getFamilyList();
    }, []);

    const getFamilyList = () => {
        setLoader(true);
        let payload = {};
        if (adminClientValuefromSession?.clientId) {
            payload.client_id = adminClientValuefromSession?.clientId;
        } else {
            payload.enquiry_id = adminClientValuefromSession?.enquiryId;
        }
        fetchApi("/family-list", "post", payload).then((res) => {
            if (res?.data) {
                setFamilyList(res?.data);
            } else {
                setFamilyList([]);
            }
            setLoader(false);
        });
    };
    const handleAddFamMem = () => {
        form.resetFields();
        setEmailValidateStatus("");
        setIsFamilyModalOpen(true);
    };
    const handleOk = () => {
        setIsFamilyModalOpen(false);
    };
    const handleCancel = () => {
        setIsFamilyModalOpen(false);
        setIsEdit(false);
    };
    const handleValidateName = (rule, value, callback) => {
        try {
            if (value) {
                // eslint-disable-next-line no-useless-escape
                let nameValidate = /[`~@#$%^&*,<>;?:"\/\[\]\|{}()=+]/;
                if (nameValidate.test(value) === true) {
                    throw new Error(constants.FirstNameInvalid);
                }
            }
            callback(); // < -- this
        } catch (err) {
            callback(err);
        }
    };
    const handleCheckEmail = () => {
        let payload = {};
        if (emailIdValue) {
            payload.email = emailIdValue;
        }
        fetchApi("/client/register/check-emails", "post", payload).then((res) => {
            if (res?.code === 200) {
                setEmailValidateStatus("success");
            } else {
                setEmailValidateStatus("error");
                message.error(res.message?.email);
            }
        });
    };
    const handleContactNo = (value) => {
        if (value === "" || value === null || value === undefined) {
            const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
            setStyles(stylesValidation);
        } else {
            setStyles(null);
        }
    };
    const handleValidatePhone = (rule, value, callback) => {
        try {
            if (value) {
                let phoneValidate = /^(?=.{5,})\S*$/;
                if (phoneValidate.test(value) === false) {
                    const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
                    setStyles(stylesValidation);
                    throw new Error(constants.PhoneNoRegistration);
                } else {
                    setStyles(null);
                }
            }
            callback(); // < -- this
        } catch (err) {
            callback(err);
        }
    };
    const onFinishForm = (value) => {
        saveFriendsFamilyDetails(value);
    };
    const saveFriendsFamilyDetails = (value) => {
        setLoader(true);
        const admin_id = getCookie(COOKIE.ClientId);
        let url;
        let method;
        let payload = {
            client_firstname: value?.client_firstname,
            client_lastname: value?.client_lastname,
            client_email: value?.client_email,
            client_contact_number: value?.client_contact_number,
            client_relation: value?.client_relation,
            added_by: admin_id,
        };
        if (isEdit) {
            url = "/edit-family-member";
            method = "put";
            payload = {
                ...payload,
                enquiry_id: editEnquiryId,
            };
        } else {
            url = "/add-family";
            method = "post";
            payload = {
                ...payload,
                client_id: adminClientValuefromSession?.clientId,
            };
        }
        fetchApi(url, method, payload).then((res) => {
            if (res?.code === 200) {
                getFamilyList();
                setLoader(false);
                message.success(res.message);
                setIsFamilyModalOpen(false);
                setIsEdit(false);
            } else {
                setLoader(false);
                setIsFamilyModalOpen(false);
                message.error(res.message);
            }
        });
    };
    const editFriendsFamily = (family_data) => {
        setEditEnquiryId(family_data?.enquiry_id);
        handleAddFamMem();

        form.setFieldsValue({
            client_firstname: family_data?.client_firstname,
            client_lastname: family_data?.client_lastname,
            client_email: family_data?.client_email,
            client_contact_number: family_data?.client_contact_number,
            client_relation: family_data?.client_relation,
        });
        setIsEdit(true);
    }
    const routeToEditClientEnquiry = (record) => {
        let pathname;

        sessionStorage.removeItem("adminClientValue");
        sessionStorage.removeItem("enquiryDetails");

        if (record?.client_id) {
            pathname = "/edit-client";
            let payloadClient = {
                client_name: record.client_firstname + " " + record.client_lastname,
                client_serial_number: record.client_serial_number,
                clientId: record.client_id
            };
            sessionStorage.setItem("adminClientValue", JSON.stringify(payloadClient));
        } else {
            if (record.status === "5") {
                sessionStorage.removeItem("enquiryDetails");
                pathname = "/edit-client";
                let payload = {
                    client_name: record.client_firstname + " " + record.client_lastname,
                    client_serial_number: record.client_serial_number,
                    clientId: record.client_id
                };
                sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
            } else {
                sessionStorage.setItem("enquiryDetails", JSON.stringify(record));
                pathname = "/edit-enquiry";
            }
        }
        window.open(constants.BaseUrl + pathname, '_blank');
    };
    const columns = [
        {
            title: 'Action',
            align: "center",
            render: (record) => {
                return record.status !== "5" ? (
                    <Space>
                        <Tooltip title="Edit">
                            <Button
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => editFriendsFamily(record)}
                            />
                        </Tooltip>
                    </Space>
                ) : null;
            },
            hidden: adminClientValuefromSession?.enquiryId,
        },
        {
            title: 'Name',
            dataIndex: 'client_firstname',
            key: 'client_firstname',
            render: (client_firstname, record) => <a onClick={() => routeToEditClientEnquiry(record)}>{client_firstname + " " + record.client_lastname}</a>
        },
        {
            title: 'Email',
            dataIndex: 'client_email',
            key: 'client_email',
            render: (client_email) => <span>{client_email}</span>,
        },
        {
            title: 'Contact No.',
            dataIndex: 'client_contact_number',
            key: 'client_contact_number',
            render: (client_contact_number) => {
                if (client_contact_number === "00000") {
                    return <span>-</span>
                } else {
                    return <span>{client_contact_number}</span>
                }
            }
        },
        {
            title: 'Relation',
            dataIndex: 'client_relation',
            key: 'client_relation',
            render: (client_relation) => <span>{client_relation}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
                const statusMap = {
                    "0": "New",
                    "1": "Parent Consent Requested",
                    "2": "Consent Received",
                    "3": "Registration Link Sent",
                    "4": "Registered (Awaiting Payment)",
                    "5": "Payment Received"
                };
                if (record.send_registration === 1) {
                    return (
                        <Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => saveFriendsFamilyDetails(record)}>{constants.SendFamilyAgg}</Button>
                    );
                }

                if (record.client_id) {
                    const activeStatusMap = {
                        "0": "Pending",
                        "1": "Active",
                        "2": "Closed",
                        "3": "Refresher"
                    };

                    return activeStatusMap[record.is_active] || null;
                }

                return statusMap[status] || null;
            },
        },
        {
            title: 'Added By',
            dataIndex: 'added_by',
            key: 'added_by',
            render: (record) => {
                return <span>{record}</span>;
            },
        }
    ].filter(item => !item.hidden);

    const onChangeTable = (paginate, sort, filter) => {
        setPagination({
            pageSize: paginate.pageSize,
            currentPage: paginate.current
        });
    };

    const getDocumentHeader = () => {
        let client_name;
        let serial_no;
        if (adminClientValuefromSession?.clientId) {
            client_name = adminClientValuefromSession?.client_name ? adminClientValuefromSession?.client_name : "";
            serial_no = adminClientValuefromSession?.client_serial_number !== null ? adminClientValuefromSession?.client_serial_number : "";
        } else {
            client_name = adminClientValuefromSession?.client_firstname ? adminClientValuefromSession?.client_firstname + " " + adminClientValuefromSession?.client_lastname : "";
            serial_no = adminClientValuefromSession?.enquiryId ? adminClientValuefromSession?.enquiryId : "";
        }
        return <div className='bg-amber-100 text-base font-semibold p-2 mb-3'><div className="container mx-auto">{loginType === "0" ? `My ${constants.FamilyAndFriends}` : `${constants.FamilyAndFriends} - ${client_name} (#${serial_no})`}</div></div>;
    };

    return (
        <>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.FamilyAndFriends}</span>
            </div>
            {getDocumentHeader()}
            <div className='container mx-auto mt-2'>
                {adminClientValuefromSession?.clientId ? <Button className='bg-sky-800 text-white uppercase my-2.5' onClick={handleAddFamMem} >{constants.AddFnF}</Button> : null}
                <Spin spinning={loader} size="large">
                    <Table
                        dataSource={familyList}
                        columns={columns}
                        pagination={pagination}
                        onChange={onChangeTable}
                        bordered
                        scroll={{ x: 900 }}
                        locale={{ emptyText: 'No Records Found' }}
                        size="small"
                        rowKey="enquiry_id"
                    />
                </Spin>
            </div>
            <Modal
                title={<div className="text-center">{isEdit === true ? constants.EditFnF : constants.AddFnF}</div>}
                open={isFamilyModalOpen}
                footer={null}
                onOk={handleOk}
                centered
                style={{ top: 20 }}
                width={700}
                className="client-details"
                onCancel={handleCancel}
            >
                <Spin spinning={loader} size="large">
                    <Form
                        labelWrap
                        form={form}
                        onFinish={onFinishForm}
                        {...layout}
                    >
                        <Form.Item
                            className="mb-3"
                            name={"client_firstname"}
                            label={constants.FirstName}
                            rules={[
                                {
                                    required: true,
                                    message: constants.FirstNameRegistration,
                                    whitespace: true
                                },
                                { validator: handleValidateName },
                            ]}
                        >
                            <Input autoFocus placeholder={constants.FirstName} />
                        </Form.Item>
                        <Form.Item
                            className="mb-3"
                            name={"client_lastname"}
                            label={constants.LastName}
                            rules={[
                                {
                                    required: true,
                                    message: constants.LastNameRegistration,
                                    whitespace: true
                                },
                                { validator: handleValidateName },
                            ]}
                        >
                            <Input autoFocus placeholder={constants.LastName} />
                        </Form.Item>
                        <Form.Item
                            name="client_email"
                            hasFeedback
                            validateStatus={emailValidateStatus}
                            label={constants.Email}
                            rules={[
                                {
                                    required: true,
                                    message: constants.EmailRegistration,
                                },
                                {
                                    type: 'email',
                                    message: constants.emailVaidationError,
                                },
                            ]}
                        >
                            <Input
                                placeholder={constants.EmailPlaceholder}
                                onChange={(e) => {
                                    setEmailIdValue(e.target.value.toLocaleLowerCase());
                                }}
                                onBlur={() => { handleCheckEmail(); }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={constants.phone}
                            name="client_contact_number"
                            rules={[
                                {
                                    required: true,
                                    message: constants.PhoneNoRegistration,
                                },
                                { validator: handleValidatePhone },
                            ]}
                        >
                            <PhoneInput preferredCountries={["us", "in"]} className='w-full' country={"in"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567" />
                        </Form.Item>
                        <Form.Item
                            className="mb-3"
                            name={"client_relation"}
                            label={constants.Relation}
                            rules={[
                                {
                                    required: true,
                                    message: constants.RequiredField,
                                }
                            ]}
                        >
                            <Input autoFocus placeholder={constants.Relation} />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Space className="float-right">
                                <Button
                                    size="middle"
                                    onClick={handleCancel}
                                >
                                    {constants.CancelBTN}
                                </Button>
                                <Button
                                    size="middle"
                                    className='bg-sky-800 text-white'
                                    htmlType="submit"
                                >
                                    {isEdit === true ? constants.Save : constants.Add}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
};
export default FriendsFamilyListing;