import React,{ useEffect, useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
import { Button, Modal, Form, message, Space,Row, Image, Col, Spin, Popconfirm, Tooltip, Pagination } from 'antd';
import constants from '../../../constants/constants';
import { COOKIE, getCookie } from "../../../services/cookie";
import moment from "moment";
import { DeleteOutlined } from '@ant-design/icons';
import { DOB, uploadFile } from '../../../globalFunctions/GlobalFunctions';
const ImageList = () => {
	const [form] = Form.useForm();
	const [loader, setLoader] = useState(false);
	const [imageList, setImageList] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [latestDate, setlatestDate] = useState([]);
	const [previewImageData, setPreviewImage] = useState('');
	const [showPreviewImage, setShowPreviewhowImage] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(24);
	const [totalRecords, setTotalRecords] = useState(0); 
	const [cachedData, setCachedData] = useState({});
    const [uploadDeleteSuccess, setUploadDeleteSuccess] = useState(false);

	useEffect(() => {
        // Check if upload or deletion was successful
        if (uploadDeleteSuccess) {
            setCachedData({});
            getImageList();
            setUploadDeleteSuccess(false);
        } else if (cachedData[`${currentPage}-${pageSize}`]) {
            // If cached data exists, set it as the image list
            setImageList(cachedData[`${currentPage}-${pageSize}`]);
        } else {
            // If cached data does not exist, fetch the data from the API
            getImageList();
        }
    }, [currentPage, pageSize, uploadDeleteSuccess]);
	const handlePageChange = (page) => {
		setCurrentPage(page);
	}
	const handlePageSizeChange = (current, size) => {
		setPageSize(size);
	}
	const getImageList = () =>{
		setLoader(true);
		let payload= {
			page: currentPage,
			limit:pageSize,
		};
		fetchAdminApi(`/image-list`, "post", payload).then((res) => {
			if (res && res.code && res.code === 200 && res.data) {
				setLoader(false);
				setImageList(res.data.images);
				setTotalRecords(parseInt(res?.data?.total_images));
				// Cache the fetched data based on the current page and page size
				setCachedData({
					...cachedData,
					[`${currentPage}-${pageSize}`]: res.data.images,
				});
				let latestDate=res?.data?.images[0]?.date;
				let nextDate = moment(latestDate).add(1,'day');
				let dateString = nextDate.format('YYYY-MM-DD');
				let dateParts = dateString?.split("-");
				setlatestDate(dateParts);
			}else{
				setLoader(false);
				setImageList([]);
				let currentDate = moment().format('YYYY-MM-DD');
				let dateParts = currentDate?.split("-");
				setlatestDate(dateParts);
			}
		});
	};
	const addImage = () => {
		setIsModalOpen(true);
		setDay(parseInt(latestDate[2]));
		setYear(parseInt(latestDate[0]));
		setMonth((latestDate[1]));
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const onFinishForm = () => {
		if(day && month && year){
			handleUpload();
		}
	};
	const handleDayChange = (value) => {
		setDay(value);
	};
	const handleMonthChange = (value) => {
		setMonth(value);
	};
	const handleYearChange = (value) => {
		setYear(value);
	};
	const [fileList, setFileList] = useState([]);
	const handleFileChange = ({ fileList }) => {
		setFileList(fileList);
	};
	function beforeUpload(file) {
		const isJpgOrPng =
    file.name.includes(".jpg") ||
    file.name.includes(".png") ||
		file.name.includes(".jpeg");
		if (!isJpgOrPng) {
			message.error({
				message: "Failed",
				description: "You can only upload JPEG/PNG/JPG file!",
			});
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error({
				message: "Failed",
				description: "Max file size accepted 2MB!",
			});
		}
		return isLt2M;
	}
	const handleUpload = async () => {
		try {
			const formData = new FormData();
			fileList.forEach((file) => {
				formData.append('files[]', file.originFileObj);
			});
			const response = await fetch(`${constants.AdminApiUrl}/upload-image?date=${year}-${month}-${day}`, {
				method: 'POST',
				body: formData,
				headers: { Authorization: `Bearer ${getCookie(COOKIE.Token)}` },
			});
			const data = await response.json();
			if (data.code === 200) {
				message.success(data.message);
				setCachedData({});
				getImageList();
				setUploadDeleteSuccess(true);
				setIsModalOpen(false);
				setDay(null);
				setMonth(null);
				setYear(null);
				setFileList([]);
			} else {
				message.error(data.message.date);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const handleRemove = (file) => {
		// remove the file from the fileList
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};
	const tailLayout = { wrapperCol: { span: 24 } };
	const previewImage = (image_id) => {
		setLoader(true);
		setPreviewImage("");
		fetchAdminApi(`/preview-image/${image_id}`, "get").then((res) => {
			if (res && res.code && res.code === 200 && res.data) {
				setLoader(false);
				setPreviewImage(res?.data?.image_data);
				setShowPreviewhowImage(true);
			}
			else{
				setLoader(false);
				message.error(res.message);
				setShowPreviewhowImage(false);
			}
		});
	};
	const deleteImage = (image_id) => {
		fetchAdminApi(`/delete-file/${image_id}`, "get").then((res) => {
			if (res && res.code && res.code === 200 ) {
				setCachedData({});
				getImageList();
				message.success(res.message);
				setUploadDeleteSuccess(true);
			}else{
				message.error(res.message);
			}
		});
	};
	const handleCancelImage = () => {
		setShowPreviewhowImage(false);
	};
	const imagePreview = () => {
		return <Modal 
			centered 
			style={{ top: 20 }} 
			width={700} 
			open={showPreviewImage}
			footer={null}
			onCancel={handleCancelImage}>
			<Image 
				preview={false}
				src={previewImageData}
				className="w-full"
			></Image>
		</Modal>;
	};
	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ImageList}</span>
			</div>
			<div className='container mx-auto'>
				<Button className='bg-sky-800 text-white uppercase my-2.5' onClick={addImage}>{constants.UploadImages}</Button>
				<Spin spinning={loader} size="large">
					<Row gutter={[16, 16]}>
						{imageList.length > 0 ?
							imageList.map((item) => (
								<Col key={item.id} xs={24} sm={12} md={6} lg={3} className="text-center">
									<>
										<span className="font-bold">{moment(item.date).format(constants.dateformat)}</span>
										<Image 
											preview={false}
											onClick={ ()=> previewImage(item.image_id)} 
											src={item.image_data} 
											alt={item.image_name} 
											className="w-full cursor-pointer"
										/>
										<Tooltip title={constants.Delete}>
											<Popconfirm title="Sure to Delete?" onConfirm={() => deleteImage(item.image_id)}>
												<Button style={{ color:"red" }} type='text' icon={<DeleteOutlined/>}/>
											</Popconfirm>
										</Tooltip>
									</>
								</Col>
							))
							: 
							<Col xs={24} sm={24} md={24} lg={24} className="text-center"><span>No Images Found.</span></Col>}
					</Row>
					{/* Pagination */}
					{imageList.length > 0 && (
						<div className="mb-3 flex justify-end">
							<Pagination
								current={currentPage}
								total={totalRecords}
								pageSize={pageSize}
								showSizeChanger
								pageSizeOptions={[8, 24, 48, 96]}
								onChange={handlePageChange}
								onShowSizeChange={handlePageSizeChange}
							/>
						</div>
					)}
				</Spin>
			</div>
			<Modal 
				title={<div className="text-center">{constants.UploadImages}</div>} 
				open={isModalOpen} 
				onOk={handleOk} 
				onCancel={handleCancel}
				footer={null}
				centered 
				style={{ top: 20 }} 
				width={700} 
				className="client-details"
			>
				<Form
					form={form}
					labelWrap
					onFinish={onFinishForm}
					layout="vertical"
				>
					{DOB(month,handleMonthChange,day,handleDayChange,year,handleYearChange,"Date")}
					{uploadFile(fileList, handleFileChange,handleRemove,beforeUpload,"upload-image")}
					<Form.Item {...tailLayout}>
						<Space className="float-right">
							<Button
								size="middle"
								onClick={handleCancel}
							>
								{constants.CancelBTN}
							</Button>
							<Button
								size="middle"
								className='bg-sky-800 text-white'
								htmlType="submit"
							>
								Upload
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Modal>
			{showPreviewImage && imagePreview()}
		</div>
	);
};
export default ImageList;