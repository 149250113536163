import { Input, Form, Row, Col, Image, Button, Space, message } from 'antd';
import constants from '../../../constants/constants';
import logo from '../../../logo-blue-black.svg';
import { useHistory } from "react-router";
import { fetchApi } from '../../../services/api';
const ForgotPassword = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const onFinalSubmit=(values)=>{
		console.log("values",values);
		let payload=null;
		payload={
			user:values.email,
			type:"client",
		};
		fetchApi("/client/forgot-password", "post", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				message.success(res.message);
			} else {
				message.error(res.message);
			}
		});
	};
	return (
		<div>
			<Row gutter={[0, 16]}>
				<Col xs={24} sm={24} lg={9} className="bg-amber-300 text-yellow-600 capitalize text-3xl font-semibold lg:min-h-screen">
					<div className='mx-2 md:mx-6 md:mt-8'>
						<Image src={logo} alt="RoyCares & Associates" preview={false}/><br/>
						<div className='pt-6'>
							{constants.AnyoneRecover}
						</div>
						<div>
							{constants.Tagline}
						</div>
					</div>
				</Col>
				<Col xs={24} sm={24} lg={15} className="lg:min-h-screen">
					<div className='mx-2 md:mx-5 md:mt-8'>
						<div className='text-xl lg:text-3xl font-semibold mb-3'>{constants.ForgotPsdTitle}</div>
						<div className='text-base mb-5'>{constants.ForgotPsdSubTitle}</div>
						<Form layout="vertical" className='label-style lg:w-96' form={form}  onFinish={onFinalSubmit}>
							<Form.Item label={constants.Email} name="email"
								rules={[
									{
										type: 'email',
										message: 'The input is not valid E-mail!',
									},
									{
										required: true,
										message: 'Please input your E-mail!',
									},
								]}
							>
								<Input size="large"/>
							</Form.Item>
							<Space>
								<Form.Item>
									<Button size="large" className='bg-sky-800 text-white uppercase' onClick={()=>history.push("/login")}>
										{constants.Cancel}
									</Button>
								</Form.Item>
								<Form.Item>
									<Button size="large" htmlType="submit" className='bg-sky-800 text-white uppercase'>
										{constants.SendIns}
									</Button>
								</Form.Item>
							</Space>
						</Form>
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default ForgotPassword;