import axios from "axios";
import constants from "../../constants/constants";
import { COOKIE, getCookie, deleteCookie } from "../cookie";
/**
 * @name fetchApi
 * @description will fet the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
/**
 * @name fetchAdminApi
 * @description will fet the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
export const fetchApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.ApiUrl}${param}`,
		data: variables,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
			"Content-Type": "application/json",
			mode: "no-cors",
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if (err.response.data.error_message === "Invalid token") {
					deleteCookie(COOKIE.ClientId, "/");
					deleteCookie(COOKIE.FirstName, "/");
					deleteCookie(COOKIE.LastName, "/");
					deleteCookie(COOKIE.LoginType, "/");
					deleteCookie(COOKIE.Token, "/");
					window.location.href = constants.BaseUrl + "/";
					sessionStorage.clear();
				}
				if (err.response.data.message) {
					return err.response.data;
				} else if (err.response.data.error_message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else if (err.response.data.error_message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});
export const fetchAdminApi = (param = null, method = null, variables = null) =>
	axios({
		method: method,
		url: `${constants.AdminApiUrl}${param}`,
		data: variables,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
			"Content-Type": "application/json",
			mode: "no-cors",
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if (err.response.data.error_message === "Invalid token") {
					deleteCookie(COOKIE.ClientId, "/");
					deleteCookie(COOKIE.FirstName, "/");
					deleteCookie(COOKIE.LastName, "/");
					deleteCookie(COOKIE.LoginType, "/");
					deleteCookie(COOKIE.Token, "/");
					window.location.href = constants.BaseUrl + "/";
					sessionStorage.clear();
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});

export const fetchDownloadApi = (param = null, method = null, variables = null, url= null) =>
	axios({
		method: method,
		url: `${url}${param}`,
		data: variables,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
			"Content-Type": "application/json",
			mode: "no-cors",
			Authorization: `Bearer ${getCookie(COOKIE.Token)}`
		},
		responseType: 'blob'
	})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.log("The Api error", err);
			if (err.response.data.message && err.response.data.status_code) {
				console.log(err.response.data);
			}
			let { status } = err.response;
			if (status === 401) {
				console.log("Error", err.response.data);
				if (err.response.data.error_message === "Invalid token") {
					deleteCookie(COOKIE.UserId, "/");
					deleteCookie(COOKIE.FirstName, "/");
					deleteCookie(COOKIE.LastName, "/");
					deleteCookie(COOKIE.Token, "/");
					window.location.href = constants.BaseUrl + "/admin-login";
				}
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else if (status === 400) {
				if (err.response.data.message) {
					return err.response.data;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});