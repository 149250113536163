import { Button, Layout, Space } from "antd";
import "./index.css";
import constants from './constants/constants';
import { WhatsAppOutlined } from '@ant-design/icons';
const { Footer } = Layout;
const Footers = () => {
	return (
		<div>
			<Footer className="roycares-footer text-center">
				<div className="bg-sky-800 py-3 px-6 ">
					<div className="text-lg italic text-white capitalize">{constants.AnyoneRecover}</div>
					<div className="flex justify-between flex-wrap items-center text-lg italic lg:w-96 mx-auto text-white">
						<span>Renew</span>&#8226;
						<span>Restart</span>&#8226;
						<span>Reset</span>&#8226;
						<span>Refocus</span>
					</div>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-2 text-sm bg-black py-2 text-white px-2 lg:px-6">
					<div className="text-center lg:text-left">©{(new Date().getFullYear())}. RoyCares. All Rights Reserved.</div>
					<div className="text-center lg:text-right">
						<Space className="flex-wrap place-content-center">
							<Button type="text" href="tel:+919022276484" icon={<WhatsAppOutlined className="text-white text-lg"/>} className="text-amber-300">IN +91 90222 76484</Button>
							<Button type="text" href="tel:+1(917)974-4265" icon={<WhatsAppOutlined className="text-white text-lg"/>} className="text-amber-300">INT +1 (917) 974-4265</Button>
						</Space>
					</div>
				</div>
			</Footer>
		</div>
	);
};
export default Footers;
