import React from "react";
import "./index.css";
import { Layout } from "antd";
import Headers from "./header";
import Footer from "./footer";
const { Content } = Layout;
const Layouts = (props) => {
	return (
		<div>
			<Layout>
				<Headers/>
				<Content className="bg-pattern min-content-height">
					{React.cloneElement(props.children, { ...props, })}
				</Content>
				<Footer/>
			</Layout>
		</div>
	);
};
export default Layouts;