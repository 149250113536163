import React,{ useEffect,useState } from 'react';
import { fetchAdminApi } from "../../../services/api";
import { Table, Button, message, Form, Tooltip, Space, Popconfirm, Radio, Spin } from 'antd';
import { EditOutlined, DownloadOutlined, DeleteOutlined, LeftCircleOutlined, RightCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from "moment";
import constants from '../../../constants/constants';
import { notesModel, DOB } from "../../../globalFunctions/GlobalFunctions";
import { COOKIE, getCookie } from "../../../services/cookie";
const NoteList =()=> {
	const [form] = Form.useForm();
	const [tableData, setTableData] = useState([]);
	//notes
	const [notesVisible, setnotesVisible] = useState(false);
	const [noteValue, setNoteValue] = useState(null);
	const [noteTypeValue, setNoteTypeValue] = useState("0");
	const [sectionId, setSectionId] = useState(null);
	const [sessionChecked, setSessionChecked] = useState(false);
	const [isSessionPresent, setIsSessionPresent] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [noteId, setNoteId] = useState("");
	const [loader, setLoader] = useState(true);
	const [isDisableNoteType, setIsDisableNoteType] = useState(false);
	const [editNoteIndex, setEditNoteIndex] = useState("");
	const [disableNext, setDisableNext] = useState(false);
	const [disablePrevious, setDisablePrevious] = useState(false);
	const [noteView, setNoteView] = useState("listView");
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 25,
		pageSizeOptions: [10, 25, 50, 100]
	});
	const defaultPaginationBook = {
		current: 1,
		pageSize: 1,
	};
	const defaultPagination = {
		current: 1,
		pageSize: 25,
		pageSizeOptions: [10, 25, 50, 100]
	};
	const current = new Date();
	const [noteDate,setNoteDate] = useState({
		date: current.getDate(),
		month: String(current.getMonth() + 1),
		year: current.getFullYear()
	});
	//date of notes date onchange
	const handleNoteDayChange = (value) => {
		setNoteDate({
			...noteDate,
			date:value
		});
	};
	const handleNoteMonthChange = (value) => {
		setNoteDate({
			...noteDate,
			month:value
		});
	};
	const handleNoteYearChange = (value) => {
		setNoteDate({
			...noteDate,
			year:value
		});
	};
	const adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
	const handleDelete = (notes_id) => {
		fetchAdminApi(`/delete-note/${notes_id}`, "delete").then((res) => {
			if (res?.code === 200) {
				message.success(res?.message);
				fetchNotes("delete");
			} 
			else {
				message.error(res?.message);
			}
		});
	}
	const columns = [
		{
			title: 'Action',
			width:"5%",
			align: "center",
			render: (_, record) => {
				const adminId = getCookie(COOKIE.ClientId);
				const isAdmin = record?.admin_id === adminId; 
				let noteIndex = tableData?.findIndex((val)=> val?.notes_id === record?.notes_id);
				return <Space>
					{isAdmin && record?.signed_by === null ? 
						<Tooltip title={"Edit"}><Button
							onClick={()=>addNotes("edit",record,noteIndex)}
							type='text'
							icon={<EditOutlined/>}
						/></Tooltip>:null
					}
					<Tooltip title={"Delete"}>
						<Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record?.notes_id)}>
							<Button
								type='text'
								icon={<DeleteOutlined />}
							/>
						</Popconfirm>
					</Tooltip>
					<Tooltip title={"Download"}><Button
						onClick={()=>downloadNotes(record)}
						type='text'
						icon={<DownloadOutlined/>}
					/></Tooltip>
				</Space>;
			}
		},
		{
			title: 'Date',
			dataIndex: 'date_of_notes',
			key: 'date_of_notes',
			render:(date,record)=>{
				return <div>
					<span>{moment(date).format(constants.dateformat)}</span>
					{
						record?.signed_by ?
							<><br/><br/><span> Signed by {record?.signed_by_name} on {moment(record?.signed_on).format(constants.dateformat)}</span></>:
							<span></span>
					}
				</div>;
			},
			width:"10%"
		},
		{
			title: 'Intervention',
			dataIndex: 'intervention',
			key: 'intervention',
			render: (intervention) => {
				return <span className="m-auto">{intervention}</span>;
			},
			width:"5%"
		},
		{
			title: 'Content',
			dataIndex: 'notes_content',
			key: 'notes_content',
			// width:"80%",
			editable: true,
			render:(notes,record)=>{
				let note1 = record?.notes_content !== null ? record?.notes_content + '<br/>' : "";
				let note2 = record?.notes_assesment !== null ? record?.notes_assesment + '<br/>' : "";
				let note3 = record?.notes_plan !== null ? record?.notes_plan + '<br/>' : "";
				let notes_content = note1  + note2  + note3;
				const contentDiv = document.createElement('div');
				contentDiv.innerHTML = notes_content?.replace(/\n/g, '<br/>');
				const formattedHtml = contentDiv.innerHTML;
				return <div dangerouslySetInnerHTML={{ __html: notes_content ?formattedHtml:"" }}/>;
			}
		},
		{
			title: 'Session',
			dataIndex: 'session',
			key: 'session',
			width: "5%",
			render: (session) => {
				return <span className="text-right w-6 block m-auto">{session}</span>;
			}
		},
		{
			title: 'Section',
			dataIndex: 'section_id',
			key: 'section_id',
			width:"5%",
			render:(section) => {
				return <span className="text-right w-6 block m-auto">{section}</span>;
			}
		},
		{
			title: 'Entered By',
			dataIndex: 'note_by_name',
			key: 'note_by_name',
			width:"10%",
			render: (name) => {
				return <span className="m-auto">{name}</span>;
			}
		},
	];
	const nextPreviousButton = () => {
		return isEdit ?
		<div className='flex'>
			<Tooltip placement="bottom" title={constants.Previous}><Button disabled={disablePrevious} type='text' icon={<LeftCircleOutlined />} onClick={()=>addNotes("edit",tableData[editNoteIndex-1],editNoteIndex-1,)}/*  onClick={()=>onNextandPrev("previous")} */ /></Tooltip>
			<Tooltip placement="bottom" title={constants.Next}><Button disabled={disableNext} type='text' icon={<RightCircleOutlined />} onClick={()=>addNotes("edit", tableData[editNoteIndex + 1], editNoteIndex+1)} /* onClick={()=>onNextandPrev("next")} *//></Tooltip>
		</div> : null
	}
	useEffect(() => {
		const noteViewValue = JSON.parse(sessionStorage.getItem('noteViewValue'));
		if (noteViewValue?.noteView && noteViewValue?.noteId) {
			fetchNotes(null, noteViewValue?.noteId);
			setNoteView(noteViewValue?.noteView);
			sessionStorage.removeItem('noteView');
		}
		else {
			fetchNotes();
		}
	}, []);
	const fetchNotes = (type=null, note_Id) => {
		setLoader(true);
		let payload;
		if(adminClientValuefromSession?.clientId){
			payload = { client_id: adminClientValuefromSession?.clientId };
		}else{
			payload = { enquiry_id: adminClientValuefromSession?.enquiryId };
		}
		fetchAdminApi(`/client-notes`, "post",payload).then((res) => {
			if (res && res.code && res.code === 200) {
				setTableData(res?.data);
				if (note_Id) {
					const index = findIndexByNoteId(note_Id, res?.data);
					if (index !== -1) {
						setPagination({ ...pagination, current: index + 1, pageSize: 1 });
					} else {
						setPagination({ ...pagination, current: 1, pageSize: 1 });
					}
				}
				setLoader(false);
			} 
			else {
				setTableData([]);
				setLoader(false);
			}
		});
	};
	const findIndexByNoteId = (noteId, data) => {
		for (let i = 0; i < data?.length; i++) {
			if (data[i]?.notes_id === String(noteId)) {
				return i;
			}
		}
		return -1;
	};
	const hideModal = () => {
		setNoteValue(null);
		setNoteTypeValue("0");
		setnotesVisible(false);
	};
	const onchangeNoteValue = (e,index) => {
		const hasContent = e.replace(/<\/?[^>]+(>|$)/g, '').trim().length > 0;
		if(hasContent){
			noteValue[index] = e;
		}else{
			noteValue[index] = null;
		}
		
	};
	const addNotes = (type, record, noteindex = null) => {
		const is_note_answered = tableData.some((note) => note.notes_type === "0");
		if (((tableData && tableData.length === 0) || is_note_answered) && type !== "edit") {
			window.open(constants.BaseUrl + "/add-note");
		} else {
			document.getElementById('top').scrollIntoView({ behavior: "smooth" });
			if (type === "edit") {
				setIsEdit(true);
				setEditNoteIndex(noteindex);
				if (noteindex === 0) {
					setDisablePrevious(true);
				} else {
					setDisablePrevious(false);
				};
				if (noteindex + 1 === tableData.length) {
					setDisableNext(true);
				} else {
					setDisableNext(false);
				};
				setIsDisableNoteType(true);
				let tempNotevalue = [];
				tempNotevalue.push(record?.notes_content ? record?.notes_content?.replace(/<b><u>DATA:<\/u><\/b>/g, '') : "", record?.notes_assesment ? record?.notes_assesment?.replace(/<b><u>ASSESSMENT:<\/u><\/b>/g, '') : "", record?.notes_plan ? record?.notes_plan?.replace(/<b><u>PLAN:<\/u><\/b>/g, '') : "");
				setNoteValue(tempNotevalue);
				setNoteTypeValue(record?.notes_type);
				setSectionId(record?.section_id);
				setNoteId(record?.notes_id);
				let dateParts = record?.date_of_notes?.split("-");
				setNoteDate({
					...noteDate,
					date: parseInt(dateParts[2]),
					month: dateParts[1],
					year: parseInt(dateParts[0])
				});
				setSessionChecked(record.session !== null);
				setIsSessionPresent(record.session !== null);
			} else {
				getForm(noteTypeValue);
				setSectionId(null);
				setIsEdit(false);
				setNoteId("");
				setIsDisableNoteType(false);
				setSessionChecked(false);
				setIsSessionPresent(false);
			}
			setnotesVisible(true);
		}
	};
	const createNote =(type)=>{
		let admin_id = getCookie(COOKIE.ClientId);
		let payload={
			notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " +noteValue[0]: "",
			notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " +noteValue[1] : "",
			notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " +noteValue[2]: "",
			section_id: sectionId,
			admin_id:admin_id,
			notes_type:noteTypeValue,
			dt_created:`${noteDate.year}-${noteDate.month}-${noteDate?.date}`
		};
		if (sessionChecked === true) {
			payload.session_checked = sessionChecked;
		}
		if(adminClientValuefromSession?.clientId){
			payload.client_id =   adminClientValuefromSession?.clientId;
		}else{
			payload.enquiry_id = adminClientValuefromSession?.enquiryId;
		}
		if(type === "esign"){
			payload.signed_by = admin_id;
		}
		let url;
		let method;
		if(isEdit){
			url = `/edit-notes`;
			method = 'put';
			payload.notes_id = noteId;
		}else{
			url = `/add-notes`;
			method = 'post';
		}
		if(noteValue !== null){
			fetchAdminApi(url, method, payload).then((res) => {
				if (res && res.code && res.code === 200) {
					if(isEdit){
						fetchNotes("edit");
					}else{
						fetchNotes();
					}
					setNoteDate({
						...noteDate,
						date: current.getDate(),
						month: String(current.getMonth() + 1),
						year: current.getFullYear()
					});
					message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
		hideModal();
	};
	const getForm = (formType) => {
		setNoteTypeValue(formType);
		let payload = { note_type: formType };
		if(adminClientValuefromSession?.clientId){
			payload.client_id =   adminClientValuefromSession?.clientId;
		}else{
			payload.enquiry_id = adminClientValuefromSession?.enquiryId;
		}
		fetchAdminApi("/notes",'post',payload).then((res) => {
			if (res?.code === 200) {
				setNoteValue(res?.data?.data);
				if(res?.data?.is_note_answered === 1){
					setIsDisableNoteType(true);
				}else{
					setIsDisableNoteType(false);
				}
			}else{
				setNoteValue([]);
			}
		});
	};
	const downloadNotes = (notesValue) => {
		let notesPayload={
			notes_id : notesValue?.notes_id,
			notes_assesment : 1
		};
		if(notesValue?.client_id !== null){
			notesPayload.client_id = notesValue?.client_id;
		}else{
			notesPayload.enquiry_id = adminClientValuefromSession?.enquiryId;
		}
		let documentValue={ 
			file_type : "notes-pdf",
			notes_payload : notesPayload
		};
		sessionStorage.setItem("documentValue",JSON.stringify(documentValue));
		window.open(constants.BaseUrl + `/view-document`);
	};

	const totalRecords = Math.ceil(tableData?.length);
	const onPreviousPage = () => {
		setPagination({
			...pagination,
			current: pagination.current - 1
		});
	};
	const onNextPage = () => {
		setPagination({
			...pagination,
			current: pagination.current + 1
		})
	};

	const onChangeNoteView = (value) => {
		setNoteView(value);
		if (value === "bookView") {
			setPagination(defaultPaginationBook);
		} else {
			setPagination(defaultPagination);
		}
	};

	const viewNoteOption = [
		{
			label: 'List View',
			value: 'listView',
		},
		{
			label: 'Book View',
			value: 'bookView',
		},
	];

	const onChangeTable = (pagination, paginationBook, sort, filter) => {
		setPagination(pagination);
	};

	const onSessionChange = (value) => {
		setSessionChecked(value);
	}

	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">Notes</span>
			</div>
			<div className='bg-amber-100 text-base font-semibold p-2 mb-3'><div className="container mx-auto">Notes - {`${adminClientValuefromSession?.client_name ? adminClientValuefromSession?.client_name:""} (#${adminClientValuefromSession?.client_serial_number ? adminClientValuefromSession?.client_serial_number:""})`}</div></div>
			<div className='container mx-auto'>
				<Form form={form} component={false}>
					<div className='mb-2 flex justify-between'>
						<div>
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => addNotes("add", null)}>{constants.AddNote}</Button>
							<Radio.Group
								options={viewNoteOption}
								onChange={(e) => onChangeNoteView(e.target.value)}
								value={noteView}
								optionType="button"
								buttonStyle="solid"
								size='small'
								className='ml-2 client-details font-semibold uppercase'
								rowKey="notes_id"
							/>
						</div>
						{totalRecords > 0 && (
							<div>
								<Tooltip placement="bottom" title={constants.Previous}>
									<Button
										size='large'
										disabled={pagination.current === 1}
										type='text'
										onClick={onPreviousPage}
										icon={<LeftOutlined className='text-base' />}
									/>
								</Tooltip>
								<Tooltip placement="bottom" title={constants.Next}>
									<Button
										size='large'
										disabled={pagination.current === Math.ceil(totalRecords / pagination.pageSize)}
										type='text'
										onClick={onNextPage}
										icon={<RightOutlined className='text-base' />}
									/>
								</Tooltip>
							</div>
						)}
					</div>
					<Spin spinning={loader} size="large">
						<Table
							className='borderTable'
							dataSource={tableData}
							columns={columns}
							onChange={onChangeTable}
							pagination={
								noteView === "listView" ? pagination : {
									...pagination,
									itemRender: (page, type, originalElement) => {
										if (type === 'page') {
											const reversedPage = totalRecords - page + 1;
											return <a>{reversedPage}</a>;
										}
										return originalElement;
									},
								}
							}
							bordered
							locale={{ emptyText: 'No Records Found' }}
							footerBg="#f0f0f0"
							rowKey="notes_id"
						/>
					</Spin>
				</Form>
				{noteValue && notesModel(hideModal, notesVisible, onchangeNoteValue, createNote, getForm, noteValue, noteTypeValue, isDisableNoteType, DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", false), onSessionChange, sessionChecked, isSessionPresent, nextPreviousButton, isEdit)}
			</div>
		</div>
	);
};
export default NoteList;