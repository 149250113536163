import React, { useEffect, useState } from 'react';
import constants from '../../../constants/constants';
import { Button, Modal, Form, Space, Table, Input, Select, message, Typography,Spin, Popconfirm, Tooltip  } from 'antd';
import { FileTwoTone, PictureTwoTone, FilePdfTwoTone, ExceptionOutlined } from '@ant-design/icons';
import moment from "moment";
import { uploadFile } from '../../../globalFunctions/GlobalFunctions';
import { COOKIE, getCookie } from "../../../services/cookie";
import { fetchApi, fetchDownloadApi } from '../../../services/api';
const { TextArea } = Input;
const DocumentListing =() => {
	const [form] = Form.useForm();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
	const adminEnquiryValuefromSession = JSON.parse(sessionStorage.getItem("enquiryDetails"));
	const [documentList, setDocumentList] = useState([]);
	const [loader, setLoader] = useState(false);
	const [docId, setdocId] = useState("");
	const loginType = getCookie(COOKIE.LoginType);
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};
	const tailLayout = { wrapperCol: { span: 24 } };
	const [fileList, setFileList] = useState([]);
	const handleFileChange = ({ fileList }) => {
		setFileList(fileList);
	};
	const [pagination, setPagination] = useState(constants.paginationConfig);
	function beforeUpload(file) {
		const isJpgOrPng =
    file.name.includes(".jpg") ||
    file.name.includes(".png") ||
		file.name.includes(".jpeg");
		if (!isJpgOrPng) {
			message.error({
				message: "Failed",
				description: "You can only upload JPEG/PNG/JPG file!",
			});
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error({
				message: "Failed",
				description: "Max file size accepted 2MB!",
			});
		}
		return isLt2M;
	}
	const handleRemove = (file) => {
		// remove the file from the fileList
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};
	useEffect(() => {
		getFileList();
		return () => {
			// Remove the session here
			sessionStorage.removeItem('adminClientValue');
			sessionStorage.removeItem('enquiryDetails');
		};
	}, []);
	const getFileList = () => {
		let payload = {};
		if(adminClientValuefromSession?.clientId){
			payload.client_id = adminClientValuefromSession?.clientId;
		}else{
			if(adminEnquiryValuefromSession?.client_id !== null){
				payload.client_id = adminEnquiryValuefromSession?.client_id
			}else{
				payload.enquiry_id = adminEnquiryValuefromSession?.enquiry_id
			}
		}
		setLoader(true);
		fetchApi(`/get-client-documments`, "post",payload).then((res) => {
			if (res && res.data) {
				setDocumentList(res.data);
				setLoader(false);
			}
			else {
				setDocumentList([]);
				setLoader(false);
			}
		});
	};
	const handleRevoke = (clientId, docId) => {
		let payload = { client_id: clientId, revoked: 1, doc_id: docId };
		setLoader(true);
		fetchApi(`/get-client-documments`, "post", payload).then((res) => {
			setLoader(false);
			if (res && res.code === 200 && res.data) {
				message.success(res.data);
				getFileList();
			} else {
				message.error("Failed to revoke document");
			}
		}).catch((error) => {
			setLoader(false);
			message.error("An error occurred");
		});
	};
	const columns = [
		{
			title: 'Date Uploaded',
			dataIndex: 'dt_uploaded',
			key: 'dt_uploaded',
			width: loginType === "0" ? "20%" : "10%",
			render: (record) => {
				return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
			},
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			width: "20%",
			render: (record,val) => {
				switch (record) {
				case "1":
					return <span>Medical Info Release {val?.file_count ? val?.file_count : ""}</span>;
				case "2":
					return <span>Discharge Report</span>;
				case "3":
					return <span>Medical Report</span>;
				case "4":
					return <span>Prescription</span>;
				case "5":
					return <span>Parent Consent</span>;
				case "6":
					return <span>Coaching Agreement</span>;
				case "7":
					return <span>Other</span>;
				case "8":
					return <span>Age Proof</span>;
				case "9":
					return <span>{constants.ConsentForm}</span>;
				case "10":
					return <span>{constants.PaymentAgreement}</span>;
				}
			},
		},
		{
			title: 'Details',
			dataIndex: 'doc_details',
			key: 'doc_details	',
			render: (record) => {
				return <span>{record}</span>;
			},
		},
		{
			title: 'File',
			dataIndex: 'file_extension',
			key: 'file_extension',
			width: loginType === "0" ? "30%" : "10%",
			render: (file_extension,record) => {
				let icon;
				if (file_extension === 'doc' || file_extension === 'docx' ) {
					icon = <FileTwoTone onClick={() => viewDocument(record?.doc_id, file_extension, record?.doc_name)} className="text-lg cursor-pointer" />;
				} else if (file_extension === 'png' || file_extension === 'jpg' || file_extension === 'jpeg') {
					icon = <PictureTwoTone className="text-lg cursor-pointer" onClick={ ()=>viewDocument(record?.doc_id,file_extension)}/>;
				} else if (file_extension === 'pdf') {
					icon = <FilePdfTwoTone onClick={ ()=>viewDocument(record?.doc_id,file_extension)} className="text-lg cursor-pointer"/>;
				} else {
					icon = <FileTwoTone onClick={ ()=>viewDocument(record?.doc_id,file_extension)} className="text-lg cursor-pointer"/>;
				}
				return (
					<Space>
						{icon}
						<Typography.Text>{file_extension.toUpperCase()}</Typography.Text>
					</Space>
				);
			},
		},
		{
			title: 'Uploaded By',
			dataIndex: 'uploaded_by_name',
			key: 'uploaded_by_name',
			render: (record) => {
				return <span>{record}</span>;
			},
			hidden: loginType === "0" ? true : false
		},
		{
			title: 'Action',
			dataIndex: 'action',
			width: "8%",
			align: 'center',
			render: (_, record) => {
				return (record.type === "1" && (record.is_revoked === "0" || record.is_revoked === null)) ? (
					<Tooltip title="Revoke">
						<Popconfirm title="Sure to revoke?" onConfirm={() => handleRevoke(record?.client_id, record?.doc_id)}>
							<Button type='text' icon={<ExceptionOutlined />} />
						</Popconfirm>
					</Tooltip>
				) : null;
			},
			hidden: loginType === "0" ? false : true,
		},
	].filter(item => !item.hidden);
	const getRowClassName = (record) => {
		if (record.is_revoked === "0" || record.is_revoked === null) {
			return '';
		} else { return 'bg-gray-200' };
	};
	const addFile = () => {
		form.resetFields();
		setFileList([]);
		setIsModalOpen(true);
	};
	const onFinishAddFileForm = async (values) =>{
		setLoader(true);
		let uploadedBy;
		if(loginType === "0"){
			uploadedBy = "";
		}else{
			uploadedBy = getCookie(COOKIE.ClientId);
		}
		try {
			const formData = new FormData();
			fileList.forEach((file) => {
				formData.append('files[]', file.originFileObj);
			});
			let payload = {
				type:values?.type,
				doc_details:values?.doc_details,
				uploaded_by:uploadedBy,
			};
			if(adminClientValuefromSession?.clientId){
				payload.client_id = adminClientValuefromSession?.clientId;
			}else{
				payload.enquiry_id = adminClientValuefromSession?.enquiry_id;
			}
			formData.append('additionalPayload', JSON.stringify(payload));
			const response = await fetch(`${constants.ApiUrl}/upload-documents`, {
				method: 'POST',
				body: formData,
				headers: { Authorization: `Bearer ${getCookie(COOKIE.Token)}` },
			});
			const data = await response.json();
			if (data.code === 200) {
				setLoader(false);
				message.success(data.message);
				getFileList();
				setIsModalOpen(false);
				setFileList([]);
			} else {
				setLoader(false);
				message.error(data.message);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const downloadDoc = (doc_id, file_extension, doc_name) => {
		let payload = {
			doc_id: doc_id,
			file_type: file_extension
		};
		fetchDownloadApi(`/view-document`, "post", payload, constants.ApiUrl).then(
			(res) => {
				if (res) {
					let url, a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application/msword" }),
						{ type: "application/msword" }
					);
					let filename = doc_name;
					a = document.createElement("a");
					a.download = filename;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
					setLoader(false);
				} else {
					message.error("Failed to download");
					setLoader(false);
				}
			}
		);
	};
	const viewDocument = (doc_id, file_extension, doc_name) => {
		if (file_extension === "doc" || file_extension === 'docx') {
			downloadDoc(doc_id, file_extension, doc_name);
		}else{
			let documentValue={ 
				doc_id : doc_id,
				file_type : file_extension
			};
			sessionStorage.setItem("documentValue",JSON.stringify(documentValue));
			window.open(constants.BaseUrl + `/view-document`);
		}
	};
	const getDocumentHeader = () => {
		let client_name;
		let serial_no;
		if(adminClientValuefromSession?.clientId){
			client_name = adminClientValuefromSession?.client_name ? adminClientValuefromSession?.client_name : "";
			serial_no = adminClientValuefromSession?.client_serial_number !== null ? adminClientValuefromSession?.client_serial_number : "";
		}else{
			client_name =adminEnquiryValuefromSession?.client_firstname ? adminEnquiryValuefromSession?.client_firstname + " " + adminEnquiryValuefromSession?.client_lastname : "";
			serial_no = adminEnquiryValuefromSession?.enquiry_id ? adminEnquiryValuefromSession?.enquiry_id : "" ;
		}
		return <div className='bg-amber-100 text-base font-semibold p-2 mb-3'><div className="container mx-auto">{loginType === "0" ? `My Documents`: `${constants.DocumentList} - ${client_name} (#${serial_no})` }</div></div>;
	};

	const onChangeTable = (paginate, sort, filter) => {
		setPagination(paginate);
	};
	return (
		<Spin spinning={loader} size="large">
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.DocumentList}</span>
			</div>
			{getDocumentHeader()}
			<div className='container mx-auto'>
				{adminClientValuefromSession?.clientId ?<Button className='bg-sky-800 text-white uppercase my-2.5' onClick={addFile}>{constants.AddDocument}</Button>:null}
				<Table
					dataSource = {documentList}
					columns={columns}
					pagination={pagination}
					onChange={onChangeTable}
					bordered
					scroll={{ x: 900 }}
					locale={{ emptyText: 'No Records Found' }}
					rowClassName={getRowClassName}
				/>
			</div>
			<Modal 
				title={<div className="text-center">{constants.AddDocument}</div>} 
				open={isModalOpen} 
				onOk={handleOk} 
				onCancel={handleCancel}
				footer={null}
				centered 
				style={{ top: 20 }} 
				width={700} 
				className="client-details"
			>
				<Form
					form={form}
					labelWrap
					onFinish={onFinishAddFileForm}
					{...layout}
				>
					<Form.Item
						label={"Type"}
						name="type"
						className="mb-3"
					>
						<Select
							size= "middle"
							placeholder="Select document type"
							// onChange={handleSelectFileType}
							options={constants.DocumentTypeOption}
						/>
					</Form.Item>
					<Form.Item
						label={"Details"}
						name="doc_details"
						className="mb-3"
					>
						<TextArea autoSize={true}/>
					</Form.Item>
					{uploadFile(fileList, handleFileChange,handleRemove,beforeUpload,"document")}
					<Form.Item {...tailLayout}>
						<Space className="float-right">
							<Button
								size="middle"
								onClick={handleCancel}
							>
								{constants.CancelBTN}
							</Button>
							<Button
								size="middle"
								className='bg-sky-800 text-white'
								htmlType="submit"
							>
								Upload
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Modal>
		</Spin>
	);
};
export default DocumentListing;