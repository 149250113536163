import React from "react";
import { Switch, Route } from "react-router-dom";
import Layouts from "../layout";
import { RouteWithLayout } from "./helper";
import Login from "../views/common/cred/Login";
import Registration from "../views/common/cred/Registration";
import Dashboard from "../views/common/Dashboard";
import Workbook from "../views/workbook/workbook";
import ForgotPassword from "../views/common/cred/ForgotPassword";
import ResetPassword from "../views/common/cred/ResetPassword";
import AccountActivation from "../views/common/cred/AccountActivation";
import ClientList from "../views/admin/ManageClient/ClientList";
import EditClientProfile from "../views/admin/ManageClient/EditClientProfile";
import MyProfile from "../views/common/cred/MyProfile";
import NoteList from "../views/admin/Notes/NoteList";
import ImageList from "../views/admin/Document/ImageList";
import ClientEnquiryList from '../views/admin/ManageClient/ClientEnquiryList';
import DocumentListing from "../views/admin/Document/DocumentListing";
import ViewDocument from "../views/admin/Document/ViewDocument";
import RegistrationPlan from "../views/admin/Plan/RegistrationPlan";
import AssociateListing from "../views/admin/Associates/AssociateListing";;
import WbContentAndClientAns from "../views/workbook/WbContentAndClientAns";
import EditWbContentAndClientAns from "../views/workbook/EditWbContentAndClientAns";
import VideoComponent from "../views/admin/VideoComponent/VideoComponant";
import EnquiryJsonInfo from "../views/admin/JsonInfo/EnquiryJsonInfo";
import MasterWbList from "../views/workbook/MasterWbList";
import MasterDocs from "../views/admin/Document/MasterDocs";
import PaymentStatement from "../views/admin/Document/PaymentStatement";
import ClientEnquiryDelete from "../views/admin/ManageClient/ClientEnquiryDelete"
import ClientDocs from "../views/admin/Document/ClientDocs";
import ClientNotes from "../views/admin/Notes/ClientNotes";
import FriendsFamilyListing from "../views/admin/ManageClient/FriendsFamilyListing";
import ServerDocs from "../views/admin/Document/ServerDocs";
import ClientEnquiryRearrange from "../views/admin/ManageClient/ClientEnquiryRearrange";
import WorkbookSections from "../views/workbook/WorkbookSections";
import ChangeJar from "../views/workbook/ChangeJar";
import AddNote from "../views/admin/Notes/AddNote";
const Routes = ({ location, ...rest }) => {
	return (
		<div>
			<Switch>
				<Route
					exact
					path="/registration"
					component={Registration}
				/>
				<Route
					exact
					path="/login"
					component={Login}
				/>
				<Route
					exact
					path="/"
					component={Login}
				/>
				<Route
					exact
					path="/forgot-password"
					component={ForgotPassword}
				/>
				<Route
					exact
					path="/reset-password"
					component={ResetPassword}
				/>
				<Route
					exact
					path="/activation"
					component={AccountActivation}
				/>
				<Route
					exact
					path="/wb-contents"
					component={WbContentAndClientAns}
				/>
				<Route
					exact
					path="/client-answers"
					component={WbContentAndClientAns}
				/>
				<Route
					exact
					path="/edit-wb-client-ans"
					component={EditWbContentAndClientAns}
				/>
				<Route
					exact
					path="/edit-wb-content"
					component={EditWbContentAndClientAns}
				/>
				<Route
					exact
					path="/add-wb-content"
					component={EditWbContentAndClientAns}
				/>
				<RouteWithLayout
					exact
					path="/dashboard"
					layout={Layouts}
					location={location}
					component={Dashboard}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/video"
					layout={Layouts}
					location={location}
					component={VideoComponent}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/my-profile"
					layout={Layouts}
					location={location}
					component={MyProfile}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/parent-consent"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/payment-agreement"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-0"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-1"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-2"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-3"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-4"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-5"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-6"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-7"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section-8"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/workbook-section"
					layout={Layouts}
					location={location}
					component={WorkbookSections}
					{...rest}
				/>
				{/* {section only for refreshrs} */}
				<RouteWithLayout
					exact
					path="/refresher-workbook"
					layout={Layouts}
					location={location}
					component={WorkbookSections}
					{...rest}
				/>
				{/* medical info route */}
				<RouteWithLayout
					exact
					path="/medical-info"
					layout={Layouts}
					location={location}
					component={Workbook}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-list-all"
					layout={Layouts}
					location={location}
					component={ClientList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/documents"
					layout={Layouts}
					location={location}
					component={DocumentListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-list-open"
					layout={Layouts}
					location={location}
					component={ClientList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-list-closed"
					layout={Layouts}
					location={location}
					component={ClientList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/demo-client-list"
					layout={Layouts}
					location={location}
					component={ClientList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-client"
					layout={Layouts}
					location={location}
					component={EditClientProfile}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/edit-enquiry"
					layout={Layouts}
					location={location}
					component={EditClientProfile}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/registration-plans"
					layout={Layouts}
					location={location}
					component={RegistrationPlan}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/notes"
					layout={Layouts}
					location={location}
					component={NoteList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-notes"
					layout={Layouts}
					location={location}
					component={ClientNotes}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/add-note"
					layout={Layouts}
					location={location}
					component={AddNote}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/image-list"
					layout={Layouts}
					location={location}
					component={ImageList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/master-workbook-section"
					layout={Layouts}
					location={location}
					component={MasterWbList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/refresher-worksheet"
					layout={Layouts}
					location={location}
					component={MasterWbList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/coaching-agreement"
					layout={Layouts}
					location={location}
					component={MasterWbList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/consent-form"
					layout={Layouts}
					location={location}
					component={MasterWbList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/master-docs"
					layout={Layouts}
					location={location}
					component={MasterDocs}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-docs"
					layout={Layouts}
					location={location}
					component={ClientDocs}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/server-docs"
					layout={Layouts}
					location={location}
					component={ServerDocs}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/payment-statement"
					layout={Layouts}
					location={location}
					component={PaymentStatement}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-enquiry-list-all"
					layout={Layouts}
					location={location}
					component={ClientEnquiryList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-enquiry-forceclosed-list"
					layout={Layouts}
					location={location}
					component={ClientEnquiryList}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/client-enquiry-open-list"
					layout={Layouts}
					location={location}
					component={ClientEnquiryList}
					{...rest}
				/>
				{/* <Route
					exact
					path="/parent-consent"
					layout={Layouts}
					location={location}
					component={ParentConsent}
					{...rest}
				/> */}
				<Route
					exact
					path="/view-document"
					layout={Layouts}
					location={location}
					component={ViewDocument}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/associate-list"
					layout={Layouts}
					location={location}
					component={AssociateListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/json-enquiry"
					layout={Layouts}
					location={location}
					component={EnquiryJsonInfo}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/delete-enquiry"
					layout={Layouts}
					location={location}
					component={ClientEnquiryDelete}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/family-and-friends"
					layout={Layouts}
					location={location}
					component={FriendsFamilyListing}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/rearrange-enquiry"
					layout={Layouts}
					location={location}
					component={ClientEnquiryRearrange}
					{...rest}
				/>
				<RouteWithLayout
					exact
					path="/change-jar"
					layout={Layouts}
					location={location}
					component={ChangeJar}
					{...rest}
				/>
			</Switch>
		</div>
	);
};
export default Routes;
