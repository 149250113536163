import React, { useState } from 'react';
import { fetchAdminApi } from '../../../services/api';
import constants from '../../../constants/constants';
import { Button, Form, Input, InputNumber, Modal, Space, message } from 'antd';

const ClientEnquiryDelete = () => {
    const [form] = Form.useForm();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [inputValue, setInputValue] = useState(null);

    const handleInputChange = (value) => {
        setInputValue(value);
    };

    const showDeleteModal = () => {
        setIsDeleteModal(true);
    };

    const onFinish = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();
            const enquiryId = values.enquiry_id;
            const res = await fetchAdminApi(`/delete-enquiry/${enquiryId}`, 'delete');
            setIsDeleteModal(false);
            if (res && res.code === 200) {
                message.success(res.data);
                form.resetFields();
            } else {
                message.error(res.message);
            }
        } catch (error) {
			message.error(error.message);
        }
    };

    return (
        <div>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>
                {constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">
                    {constants.Delete} {constants.ClientEL}
                </span>
            </div>

            <div className='bg-amber-100 text-base font-semibold p-2 mb-3'>
                <div className="container mx-auto text-center">
                    {constants.DeleteDescription}
                </div>
            </div>

            <div className='w-4/5 mx-auto my-2 pt-7 pb-4 border-2 border-black h-32 relative'>
                <Form
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >
                    <Form.Item
                        label="Enquiry Id"
                        className="mb-0"
                        name="enquiry_id"
                        rules={[
                            { required: true, message: constants.RequiredField },
                        ]}
                    >
                        <InputNumber
                            min={1}
                            className='w-1/3'
                            size="middle"
                            type="number"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </Form.Item>
                    <Space className="flex justify-end pr-6 absolute bottom-4 right-4">
                        <Button
                            size="middle"
                            className='bg-sky-800 text-white'
                            onClick={showDeleteModal}
                            disabled={!form.getFieldValue('enquiry_id')}
                        >
                            {constants.SendBtn}
                        </Button>
                    </Space>
                </Form>
            </div>

            <Modal
                title="Delete Enquiry"
                visible={isDeleteModal}
                width={510}
                onCancel={() => { setIsDeleteModal(false); }}
                footer={[
                    <div className='text-right' key="footer-div">
                        <Space>
                            <Input
                                key="submit"
                                type="button"
                                value="Yes"
                                className="bg-sky-800 text-white w-24 h-8"
                                onClick={onFinish}
                            />
                            <Button key="close" onClick={() => { setIsDeleteModal(false); }}>
                                {constants.CancelBTN}
                            </Button>
                        </Space>
                    </div>
                ]}
            >
                <p>{constants.DeleteModal} #{form.getFieldValue('enquiry_id')}?</p>
            </Modal>
        </div>
    );
}

export default ClientEnquiryDelete;
