import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import { fetchApi } from '../../services/api';
import { Button, Table, Space, Switch, Spin, Image } from 'antd';
import constants from '../../constants/constants';
import logo_bw from '../../assets/logo-black-and-white.svg';
import logo_blue from '../../assets/logo-blue.svg';
import { COOKIE, getCookie } from "../../services/cookie";

const ChangeJar = () => {
    const [changeJarData, setChangeJarData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [isColored, setIsColored] = useState(() => {
        const colorValue = JSON.parse(sessionStorage.getItem("colorValue"));
        return colorValue ? colorValue.color : true;
    });
    const [apiError, setApiError] = useState(false);
    const [pageHeader, setPageHeader] = useState(constants.ChangeJar);

    useEffect(() => {
        fetchData();

        const handleDownloadClick = () => {
            const element = document.getElementById('pdfContent');
            if (!element) {
                return;
            }

            const options = {
                margin: 0,
                filename: isColored ? 'Change Jar colored.pdf' : 'Change Jar B&W.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy'],
                },
            };
            html2pdf().from(element).set(options).save().catch((error) => {
                console.error('Error generating PDF:', error);
            });
        };

        const downloadButtons = document.querySelectorAll('.downloadPdfButton');
        downloadButtons.forEach(button => {
            button.addEventListener('click', handleDownloadClick);
        });

        return () => {
            downloadButtons.forEach(button => {
                button.removeEventListener('click', handleDownloadClick);
            });
        };
    }, [isColored]);

    const fetchData = () => {
        setLoader(true);
        setApiError(false);
        let adminClientValue = JSON.parse(sessionStorage.getItem("adminClientValue"));
        let clientId = null;
        if (adminClientValue?.isAdmin) {
            clientId = adminClientValue?.clientId;
            setPageHeader(`${constants.ChangeJar} - ${adminClientValue?.client_name} (#${adminClientValue?.client_serial_number})`);
        } else {
            clientId = getCookie(COOKIE.ClientId);
            setPageHeader(`My ${constants.ChangeJar}`);
        }
        fetchApi(`/get-opposites/${clientId}`, "get").then((res) => {
            if (res?.code === 200) {
                const { liabilities, assets } = res.message;
                const data = liabilities.map((liability, index) => ({
                    key: index,
                    liability,
                    asset: assets[index],
                }));
                setChangeJarData(data);
                setLoader(false);
            } else {
                setChangeJarData([]);
                setLoader(false);
                setApiError(true);
            }
        }).catch((error) => {
            setChangeJarData([]);
            setLoader(false);
            setApiError(true);
        });
    };

    const columns = [
        {
            title: 'Column 1',
            dataIndex: 'col1',
            key: 'col1',
            render: (text, record) => renderCell(record.col1, 1),
            className: 'w-1/4',
        },
        {
            title: 'Column 2',
            dataIndex: 'col2',
            key: 'col2',
            render: (text, record) => renderCell(record.col2, 2),
            className: 'w-1/4',

        },
        {
            title: 'Column 3',
            dataIndex: 'col3',
            key: 'col3',
            render: (text, record) => renderCell(record.col3, 3),
            className: 'w-1/4',
        },
        {
            title: 'Column 4',
            dataIndex: 'col4',
            key: 'col4',
            render: (text, record) => renderCell(record.col4, 4),
            className: 'w-1/4',
        }
    ];

    const renderCell = (data, colIndex) => {
        return data ? (
            <div className='flex flex-col justify-around h-[230px] items-center content-center'>
                <div className='pb-3'>
                    <div className={`text-center ${!isColored ? 'text-black' : 'text-[#fb2e2e]'}`}>
                        <div className="text-[12px] !font-semibold gloria-hallelujah-regular">My Character Flaws</div>
                        <div className="text-[10px] gloria-hallelujah-regular">to watch for...</div>
                    </div>
                    <div className="font-bold text-center">{data.liability}</div>
                </div>
                <div className='pb-3'>
                    <div className={`text-center ${!isColored ? 'text-black' : 'text-[#3d3dfa]'}`}>
                        <div className="text-[12px] !font-semibold gloria-hallelujah-regular">Assets</div>
                        <div className="text-[10px] gloria-hallelujah-regular">to strive for...</div>
                    </div>
                    <div className="font-bold text-center">{data.asset}</div>
                </div>
                <div className='text-center mx-auto text-[7px]'>
                    {colIndex === 1 || colIndex === 3 ? (
                        <Image src={isColored ? logo_blue : logo_bw} alt="Logo Blue" preview={false} className='!w-12' />
                    ) : (
                        <>
                            {constants.AnyoneRecover} <br /> {constants.Tagline}
                        </>
                    )}
                </div>
            </div>
        ) : null;
    };

    const getRowClassName = (record, index) => {
        const colors = ['bg-[#d5f0d1]', 'bg-[#dccbec]', 'bg-[#fbf9c5]', 'bg-[#c0fcfd]'];
        const colorClass = isColored ? colors[index % colors.length] : 'bg-white';
        return `border-b-2 border-black relative h-[256px] border-dashed ${colorClass}`;
    };

    const renderTables = () => {
        if (!changeJarData.length) return null;

        const rowCount = Math.ceil(changeJarData.length / 4);
        const formattedData = Array.from({ length: rowCount }, (_, rowIndex) => {
            const row = { key: rowIndex };
            for (let colIndex = 0; colIndex < 4; colIndex++) {
                const dataIndex = rowIndex * 4 + colIndex;
                row[`col${colIndex + 1}`] = changeJarData[dataIndex] || null;
            }
            return row;
        });

        return (
            <>
                <Table
                    dataSource={formattedData}
                    columns={columns}
                    pagination={false}
                    bordered
                    size="middle"
                    className='borderNoneTable'
                    rowClassName={(record, index) => getRowClassName(record, index)}
                    showHeader={false}
                />
                <div className="html2pdf__page-break"></div>
            </>
        );
    };

    return (
        <Spin spinning={loader} size="large">
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ChangeJar}</span>
            </div>
            <div className='bg-amber-100 text-base font-semibold p-2 mb-3'><div className="container mx-auto">
                {pageHeader}
                <Space className='flex justify-between mt-1'>
                    <Switch
                        checkedChildren="Colored PDF"
                        unCheckedChildren="Black & White PDF"
                        checked={isColored}
                        onChange={(checked) => setIsColored(checked)}
                        className='!bg-sky-800 text-white uppercase font-semibold uppercase'
                        disabled={apiError || loader}
                    />
                    <Button
                        className='downloadPdfButton text-xs bg-sky-800 text-white font-semibold uppercase'
                        disabled={apiError || loader}
                        size='small'
                    >
                        {constants.DownloadPDF}
                    </Button>
                </Space>
            </div></div>

            <div className="container mx-auto">
                <div className='my-8'>
                    <div id="pdfContent">
                        {renderTables()}
                    </div>
                    <div className='!mt-8 text-end'>
                        <Button
                            className='downloadPdfButton bg-sky-800 text-white uppercase'
                            disabled={apiError || loader}
                        >
                            {constants.DownloadPDF}
                        </Button>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ChangeJar;
