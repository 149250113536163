/* eslint-disable react/jsx-key */
import { Input, Form, Row, Col, Image, Button, message, Modal, Space } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import constants from '../../../constants/constants';
import logo from '../../../logo-blue-black.svg';
import { useHistory } from "react-router";
import { fetchApi,fetchAdminApi } from "../../../services/api";
import React, { useState, useEffect, useRef } from "react";
import CryptoJS from 'crypto-js';
import { COOKIE, getCookie, createCookie } from "../../../services/cookie";
const Login = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [rememberme, setRememberMe] = useState(false);
	const [isLoggedInModal, setIsLoggedInModal] = useState(false);
	const [imageData, setImageData] = useState(null);
	const [date,setDate] = useState({
		serverDate: null,
		indiaDate: null,
	});
	const myRef = useRef();
	const encryptData = (data, salt) => {
		let encPass = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
		createCookie(COOKIE.UserPass, encPass, null, "/");
	};
	useEffect(() => {
		setFormLogin();
		getImageData();
		let loginTye = getCookie(COOKIE.LoginType);
		if (loginTye === "0") {
			history.push("/dashboard");
		} else if (loginTye === "1" || loginTye === "2") {
			history.push("/client-list-open");
		} else if (loginTye === "3") {
			history.push("image-list")
		}
	}, [history]);
	useEffect(() => {
		if (myRef && myRef.current) {
			const { input } = myRef.current;
			input.focus();
		}
	});
	const getImageData = () =>{
		fetchAdminApi(`/image`, "get").then((res) => {
			if (res && res.code && res.code === 200 && res.data) {
				setImageData(res.data[0].image);
				setDate({
					...date,
					serverDate: res?.data[0]?.server,
					indiaDate: res?.data[0]?.ist
				});
			}else{
				setDate({
					...date,
					serverDate: null,
					indiaDate: null
				});
			}
		});
	};
	const setFormLogin = () => {
		if (getCookie(COOKIE.EmailId)) {
			let userPassEnc = CryptoJS.AES.decrypt(getCookie(COOKIE.EmailPass), getCookie(COOKIE.EmailId));
			let originalPass = JSON.parse(userPassEnc.toString(CryptoJS.enc.Utf8));
			setEmail(getCookie(COOKIE.EmailId) !== null ? getCookie(COOKIE.EmailId) : "");
			setPassword(originalPass !== null ? originalPass : "");
			setRememberMe(getCookie(COOKIE.EmailId) !== null && getCookie(COOKIE.EmailId) !== "" ? true : false);
			form.setFieldsValue({
				eamil: getCookie(COOKIE.EmailId),
				password: originalPass,
			});
		}
	};
	//Executes on click of login button
	const onFinish = () => {
		if (email && password) {
			let agent = navigator.userAgent;
			setLoading(true);
			let payload = null;
			payload = {
				email: email,
				password: password,
				browser: agent,
			};
			if (isLoggedIn === true) {
				payload.allow_login = 1;
			}
			fetchApi("/login", "post", payload)
				.then((res) => {
					setLoading(false);
					if (res && res.code && res.code === 200) {
						setIsLoggedIn(false);
						if (rememberme) {
							encryptData(password, email);
							createCookie(COOKIE.EmailId, email, null, "/");
						} else {
							createCookie(COOKIE.EmailId, [], null, "/");
							createCookie(COOKIE.EmailPass, [], null, "/");
						}
						createCookie(COOKIE.ClientId, res.data.client_id, null, "/");
						createCookie(COOKIE.FirstName, res.data.firstname, null, "/");
						createCookie(COOKIE.LastName, res.data.lastname, null, "/");
						createCookie(COOKIE.Token, res.token, null, "/");
						if (res && res.data) {
							if (res.data?.role_id === "1") {
								createCookie(COOKIE.LoginType, "1", null, "/");
								window.location.href = constants.BaseUrl + "/client-list-open";
							}
							else if (res.data?.role_id === "2") {
								createCookie(COOKIE.LoginType, "2", null, "/");
								window.location.href = constants.BaseUrl + "/client-list-open";
							}
							else if (res.data?.role_id === "3") {
								createCookie(COOKIE.LoginType, "3", null, "/");
								window.location.href = constants.BaseUrl + "/image-list";
							}
							else {
								createCookie(COOKIE.LoginType, "0", null, "/");
								window.location.href = constants.BaseUrl + "/dashboard";
							}
						}
					} else {
						if (res && res.message === "User already Logged-in") {
							setIsLoggedInModal(true);
							setIsLoggedIn(true);
						} else {
							message.error({
								content: res.message,
								duration: constants.MessageDisplayLong,
							});
						}
					}
				})
				.catch(() => {
					setLoading(false);
					message.error("Failed to login");
				});
		}
	};
	const handleEmail = (e) => {
		setEmail(e.target.value);
	};
	const handlePassword = (e) => {
		setPassword(e.target.value);
	};
	return (
		<div>
			<Row gutter={[0, 16]}>
				<Col xs={24} sm={24} lg={9} className="bg-amber-300 text-yellow-600 capitalize text-3xl font-semibold lg:min-h-screen">
					<div className='mx-2 md:mx-6 md:mt-8'>
						{imageData !==null ?
							<>
								<Image src={logo} alt="RoyCares & Associates" preview={false} /><br />
								<Image
									alt="img"
									preview={false}
									src={imageData}
									className='!w-11/12 mx-auto'
								/>
								<div>
									{constants.AnyoneRecover}
								</div>
								<div>
									{constants.Tagline}
								</div>
							</>:
							<>
								<Image src={logo} alt="RoyCares & Associates" preview={false}/><br/><div className='pt-6'>
									{constants.AnyoneRecover}
								</div><div>
									{constants.Tagline}
								</div>
							</>
						}
					</div>
				</Col>
				<Col xs={24} sm={24} lg={15} className="lg:min-h-screen">
					<div className='mx-2 md:mx-5 md:mt-8'>
						<span className='text-2xl lg:text-3xl font-semibold'>{constants.LoginHeader} {constants.AppName}</span><br/><br/>
						<Form layout="vertical" className='label-style' form={form} onFinish={onFinish} autoComplete="off">
							<Form.Item
								label={constants.Email}
								name="email"
								rules={[
									{
										required: true,
										message: constants.LoginEmailValid,
									},
								]}
							>
								<Input autoFocus size="large" placeholder={constants.Email} onChange={handleEmail}/>
							</Form.Item>
							<Form.Item
								label={ 
									constants.Password
								}
								name="password"
								rules={[
									{
										required: true,
										message: constants.LoginPasswordValid,
									},
								]}
								className='label-w-full'
							>
								<Input.Password
									placeholder={constants.Password}
									iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
									size="large"
									onChange={handlePassword}
								/>
							</Form.Item>
							<div className="text-right">
								<Button type="link" onClick={() => { history.push("/forgot-password"); }} className='px-0'>{constants.ForgotPsdRedirectLink}</Button>
							</div>
							<Form.Item>
								<Button size="large" className='bg-sky-800 text-white uppercase w-full lg:w-auto' htmlType="submit"
									loading={loading}>
									{constants.LoginBTN}
								</Button>
							</Form.Item>
						</Form>
					</div>
					<div className='invisible'>
						<span>Server Date: {date?.serverDate}</span>
						<span>Indian Date: {date?.indiaDate}</span>
					</div>
				</Col>
			</Row>
			<Modal
				title="Login"
				visible={isLoggedInModal}
				width={510}
				onCancel={() => { setIsLoggedInModal(false); }}
				// className="already-logged-modal"
				footer={[
					<div className='text-right' key="footer-div">
						<Space>
							<Input
								ref={myRef}
								key="submit"
								type="button"
								value="Use Here"
								className="bg-sky-800 text-white w-28 h-8"
								htmlType="submit"
								onClick={() => { onFinish(); }}
							/>
							<Button key="close" onClick={() => { setIsLoggedInModal(false); }}>
								{constants.CancelBTN}
							</Button>
						</Space>
					</div>
				]}
			>
				<p>{constants.LoginRestriction}</p>
				<p>{constants.LoginRestrictionPart2}</p>
			</Modal>
		</div>
	);
};
export default Login;
