import React, { useState } from 'react';
import constants from '../../../constants/constants';
import { Space, Button, message, Spin } from 'antd';
import { fetchAdminApi } from '../../../services/api';

const ClientEnquiryRearrange = () => {
    const [responseData, setResponseData] = useState(null);
    const [loader, setLoader] = useState(false);

    const handleClick = () => {
        setLoader(true);

        fetchAdminApi(`/rearrange-enquiry`, "get")
            .then((res) => {
                setLoader(false);
                if (res && res.code === 200 && res.data) {
                    setResponseData(res.data);
                    message.success(res.data);
                } else {
                    message.error(res.message);
                }
            })
            .catch((error) => {
                setLoader(false);
                message.error(error.message);
            });
    };

    return (
        <>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.RearrangeEnq}</span>
            </div>
            <div className='w-4/5 mx-auto my-2 pt-7 pb-4 border-2 border-black h-32 relative'>
                <Space className="flex justify-end pr-6 absolute bottom-4 right-4">
                    <Button size="middle" className='bg-sky-800 text-white' onClick={handleClick}>Fetch Data</Button>
                </Space>
                <Spin spinning={loader}>
                    {responseData ? (
                        <div className="text-center mt-4 text-xl">
                            <p>{responseData}</p>
                        </div>
                    ) : (
                        <div className="text-center mt-4 text-xl">
                            No data available
                        </div>
                    )}
                </Spin>
            </div>
        </>
    )
};
export default ClientEnquiryRearrange;