import React, { useState, useEffect } from 'react';
import constants from '../../../constants/constants';
import { fetchAdminApi, fetchApi } from '../../../services/api';
import { COOKIE, getCookie } from "../../../services/cookie";
import { InputNumber, Form, Input, Button, Row, Radio, message, Space, Spin, Divider, Select, Checkbox, Col, Card } from 'antd';
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router";
const { Option } = Select;
const { TextArea } = Input;
const RegistrationPlan = () => {
	const [form] = Form.useForm();
	const history = useHistory();
	const clientEnquiry = JSON.parse(sessionStorage.getItem("enquiryDetails"));
	const [listAssociates, setListAssociates] = useState([]);
	const [enquiryDetails, setEnquiryDetails] = useState({});
	const [isParentFormitemVissible, setIsParentFormitemVissible] = useState(false);
	const [clientRegistrationTypeDetails, setClientRegistrationTypeDetails] = useState({
		is_minor: clientEnquiry?.is_minor ? clientEnquiry?.is_minor : '',
		// is_scholarship: clientEnquiry?.is_scholarship ? clientEnquiry?.is_scholarship : getCookie(COOKIE.LoginType) === "2"? null :"0",
		// agreement_duration:clientEnquiry?.userType ==="refresher" ? 1: clientEnquiry?.agreement_duration !== null ? Number(clientEnquiry?.agreement_duration) : getCookie(COOKIE.LoginType) === "2"? null: 7,
		// asign_to: getCookie(COOKIE.ClientId)
		is_scholarship: null,
		agreement_duration: clientEnquiry?.userType === "refresher" ? 1 : clientEnquiry?.agreement_duration !== null ? Number(clientEnquiry?.agreement_duration) : 7,
		asign_to: clientEnquiry?.assigned_to ? clientEnquiry?.assigned_to : null
	});
	// const [disablePlanType, setDisablePlanType] = useState(clientEnquiry?.userType ==="refresher" ? true : false);
	// const [disableProgramType, setDisableProgramType] = useState(clientEnquiry?.userType ==="refresher" ? true : false);
	const [emailIdValue, setEmailIdValue] = useState(null);
	const [emailValidateStatus, setEmailValidateStatus] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [inputValue, setInputValue] = useState(null);
	const [totalAmount, setTotalAmount] = useState(null);
	const [sessionValue, setSessionValue] = useState("6");
	const [selectValue, setSelectValue] = useState("USD");
	const [enquiryNotes, setEnquiryNotes] = useState(null);
	const [workbookType, setWorkbookType] = useState(null);
	const [showCost, setShowCost] = useState(1);
	const [showFam, setShowFam] = useState(1);
	const [showPsych, setShowPsych] = useState(1);
	const [payer, setPayer] = useState(null);
	const [filteredOptions, setFilteredOptions] = useState(constants.AgreementOptions);
	// const [assosiatePlanType, setAssosiatePlanType] = useState({
	// 	agreement_duration:[],
	// 	is_scholarship:[]
	// });
	const horizontalLayout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	};
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};
	const tailLayout = { wrapperCol: { span: 24 } };
	const [validationErrormessages, setValidationErrormessages] = useState([
		{
			IsError: false,
			errorMessage: ""
		},
		{
			IsError: false,
			errorMessage: ""
		},
		{
			IsError: false,
			errorMessage: ""
		},
	]);
	const setFormFields = () => {
		form.setFieldsValue({
			age: clientRegistrationTypeDetails?.is_minor,
			plan_type: clientRegistrationTypeDetails?.is_scholarship,
			duration: clientRegistrationTypeDetails?.agreement_duration,
			asignto: clientRegistrationTypeDetails?.asign_to,
			typeaddict: workbookType,
		});
		if (clientRegistrationTypeDetails?.is_minor === "1") {
			setIsParentFormitemVissible(true);
		} else {
			setIsParentFormitemVissible(false);
		}
	};
	// const removeItemsById = (array, idsToRemove) => {
	// 	const removedItems = [];
	// 	const newArray = array.filter(item => {
	// 		if (idsToRemove.includes(item.client_id)) {
	// 			removedItems.push(item);
	// 			return false; // Exclude this item from the new array
	// 		}
	// 		return true; // Include all other items
	// 	});
	// 	return { newArray, removedItems };
	// };
	const getListAssociates = () => {
		// if(clientEnquiry?.userType ==="refresher"){
		// 	setAssosiatePlanType({
		// 		...assosiatePlanType,
		// 		agreement_duration:['1'],
		// 		is_scholarship:["0"]
		// 	});
		// }
		let payload = {
			// is_scholarship:clientRegistrationTypeDetails?.is_scholarship,
			duration: clientRegistrationTypeDetails?.agreement_duration
		};
		if (clientRegistrationTypeDetails?.agreement_duration === 0) {
			payload.is_scholarship = clientRegistrationTypeDetails?.is_scholarship
		}
		fetchAdminApi(`/view-associates`, "post", payload).then((res) => {
			if (res?.data) {
				// const { newArray, removedItems } = removeItemsById(res?.data, ["1"]);
				// let tempArray = removedItems;
				// let adminid = tempArray.findIndex((client) => client.client_id === "1");
				// //object to add on top
				// let obj = tempArray[adminid];
				// let updateArray = newArray;
				// updateArray = [obj, ...updateArray];
				// setListAssociates(adminid > -1 ? updateArray : newArray);
				// Filter out the object with client_id equal to "1"
				const firstItem = res?.data?.filter(obj => obj?.client_id === "1")[0];
				// Filter out the remaining items excluding the one with client_id equal to "1"
				const remainingItems = res?.data?.filter(obj => obj?.client_id !== "1");
				// Concatenate the firstItem with the remainingItems
				let sortedData;
				if (firstItem) {
					sortedData = [firstItem, ...remainingItems];
				} else {
					sortedData = remainingItems
				}
				setListAssociates(sortedData);
			}
			else {
				setListAssociates([]);
			}
		});
	};

	const onFinishForm = (value) => {
		setLoader(true);
		let payload = {
			client_firstname: value?.first_name,
			client_lastname: value?.last_name,
			client_email: value?.email,
			client_contact_number: value?.contactNo,
			parent_firstname: value?.parent_first_name,
			parent_lastname: value?.parent_last_name,
			parent_email: value?.parent_email,
			parent_contact_number: value?.parentcontactNo,
			your_firstname: value?.other_first_name,
			your_lastname: value?.other_last_name,
			your_email: value?.other_email,
			your_contact_number: value?.othercontactNo,
			payment_firstname: value?.caller_first_name,
			payment_lastname: value?.caller_last_name,
			payment_email: value?.caller_email,
			payment_contact_number: value?.callercontactNo,
			payment_relation: value?.caller_relation,
			is_minor: clientRegistrationTypeDetails?.is_minor,
			is_scholarship: clientRegistrationTypeDetails?.is_scholarship,
			agreement_duration: clientRegistrationTypeDetails?.agreement_duration,
			client_type: value?.typeaddict,
			to_register: 1,
			country_id: enquiryDetails?.country_id,
			region_id: enquiryDetails?.region_id,
			city: enquiryDetails?.city,
			is_self: enquiryDetails?.is_self,
			token: enquiryDetails?.token,
			associate_id: value?.asignto,
			is_associate: value?.asignto !== getCookie(COOKIE.ClientId) ? 1 : 0,
			amount: value?.amount,
			total_plan_amount: value?.totalamount,
			currency: selectValue === "USD" ? "0" : "1",
			paid_by: value?.paid_by,
			session: value?.session,
			enquiry_notes: value?.notes,
			show_cost: showCost,
			show_family: showFam,
			show_psych: showPsych,
		};
		if (clientEnquiry?.userType === "refresher") {
			payload.client_id = clientEnquiry?.client_id;
			payload.email_type = "3";
		}
		// else if (value?.paid_by === "1" && clientEnquiry?.paidBy !== "caller") {
		// 	payload.email_type = "4"
		// }
		else {
			payload.email_type = enquiryDetails?.status === "0" && enquiryDetails?.is_minor === "1" ? "2" : "1";
		}
		fetchApi(`/send-registration-link`, "post", payload)
			.then((res) => {
				if (res?.code === 200) {
					message.success(res?.message);
					setIsModalOpen(false);
					history.push("/client-enquiry-open-list");
					setLoader(false);
					setEmailIdValue(null);
				} else {
					if (res.message) {
						if (res.message.email) {
							message.error(res?.message?.email);
						} else {
							message.error(res?.message);
						}
					}
					setLoader(false);
				}
			});
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		setPayer(null);
		setEmailValidateStatus("");
		setFormFields();
	};
	const handlePreview = () => {
		let agreement_duration = clientRegistrationTypeDetails?.agreement_duration;
		agreement_duration = agreement_duration === 100 ? null : agreement_duration;
		let agreementValue = {
			is_scholarship: agreement_duration === 0 ? clientRegistrationTypeDetails?.is_scholarship : null,
			associate_id: clientRegistrationTypeDetails.asign_to,
			agreement_duration: agreement_duration,
			amount: inputValue,
			totalamount: totalAmount,
			session: sessionValue,
			currency: selectValue === "USD" ? "0" : "1",
			notes: enquiryNotes,
			showcost: showCost,
			show_family: showFam,
			show_psych: showPsych,
		};
		sessionStorage.setItem("agreementValue", JSON.stringify(agreementValue));
		window.open(constants.BaseUrl + `/coaching-agreement`, '_blank');
		sessionStorage.removeItem('agreementValue');
	};
	//Validate email id
	const handleCheckEmail = () => {
		let payload = {};
		if (emailIdValue) {
			payload.email = emailIdValue;
		}
		fetchApi("/client/register/check-emails", "post", payload).then((res) => {
			if (res?.code === 200) {
				setEmailValidateStatus("success");
				let tempArray = validationErrormessages;
				tempArray[2].IsError = false;
				tempArray[2].errorMessage = "";
				setValidationErrormessages(tempArray);
			} else {
				setEmailValidateStatus("error");
				message.error(res.message?.email);
				let tempArray = validationErrormessages;
				tempArray[2].IsError = true;
				tempArray[2].errorMessage = res.message?.email;
				setValidationErrormessages(tempArray);
			}
		});
	};
	const handleValidateLastName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:!"\/\[\]\|{}()=+\\]/;
				if (nameValidate.test(value) === true || value.length < 2) {
					throw new Error(constants.LastNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:!"\/\[\]\|{}()=+\\]/;
				if (nameValidate.test(value) === true || value.length < 2) {
					throw new Error(constants.NameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const setPlanFormItem = () => {
		let payload = {
			enquiry_id: clientEnquiry?.userType === "new" ? clientEnquiry?.enquiry_id : clientEnquiry?.enquiry_info?.enquiry_id,
			is_scholarship: clientRegistrationTypeDetails.agreement_duration === 0 ? clientRegistrationTypeDetails.is_scholarship : null,
			agreement_duration: clientRegistrationTypeDetails.agreement_duration,
			assigned_to: clientRegistrationTypeDetails.asign_to
		}
		fetchApi(`/get-enquiry`, "post", payload).then((res) => {
			if (res?.data) {
				if (res?.data?.country_code === "US") {
					setSelectValue("USD");
				} else {
					setSelectValue("INR");
				}
				setEnquiryDetails(res.data);
				if (res.data?.client_type) {
					form.setFieldsValue({ typeaddict: parseInt(res.data?.client_type) });
				}
				let currency = "INR";
				let amount = null;
				let totalamount = null;
				let session = res?.data?.session !== null ? res?.data?.session : 6;
				let notes = res.data?.enquiry_notes;
				if (res.data?.status === "2" && res.data?.is_minor === "1") {
					const amountString = res.data?.amount;
					const totalamountString = res.data?.total_plan_amount;
					if (amountString) {
						const [cur, amt] = amountString ? amountString.split(' ') : ["INR", ''];
						currency = cur;
						amount = amt;
					}
					if (totalamountString) {
						const [cur, amt] = totalamountString ? totalamountString.split(' ') : ["INR", ''];
						totalamount = amt;
					}
				}
				setInputValue(amount);
				setTotalAmount(totalamount);
				setSelectValue(currency);
				setSessionValue(session);
				setEnquiryNotes(notes);
				form.setFieldsValue({
					parent_first_name: res.data?.parent_firstname,
					parent_last_name: res.data?.parent_lastname,
					parent_email: res.data?.parent_email,
					parentcontactNo: res.data?.parent_contact_number,
					other_first_name: res.data?.your_firstname,
					other_last_name: res.data?.your_lastname,
					other_email: res.data?.your_email,
					othercontactNo: res.data?.your_contact_number,
					caller_first_name: res.data?.payment_firstname,
					caller_last_name: res.data?.payment_lastname,
					caller_email: res.data?.payment_email,
					callercontactNo: res.data?.payment_contact_number,
					caller_relation: res.data?.payment_relation,
					first_name: res.data?.client_firstname,
					last_name: res.data?.client_lastname,
					contactNo: res.data?.client_contact_number,
					email: res.data?.client_email,
					paid_by: res.data?.paid_by,
					//by default session is 6.
					amount: amount,
					totalamount: totalamount,
					selectValue: currency,
					session: session,
					notes: notes,
				});
				// if(res?.data?.is_scholarship === "3"){
				// 	form.setFieldsValue({amount:trimmedAmount})
				// }
			}
			else {
				setEnquiryDetails({});
				form.setFieldsValue({
					parent_first_name: "",
					parent_last_name: "",
					parent_email: "",
					parentcontactNo: "",
					first_name: "",
					last_name: "",
					contactNo: "",
					email: "",
				});
			}
		});
	};
	const formItemNameEmailPhone = (form_first_name, form_last_name, name_validation_message, last_name_validation_message, form_email_name, email_validation_message1, email_validation_message2, contactNo, emailCheck, contact_no_validation_message) => {
		return <Row gutter={[24, 0]}>
			<Col xs={24} lg={12}>
				<Form.Item
					className="mb-3"
					label={constants.FirstName}
					name={form_first_name}
					rules={[
						{
							required: true,
							message: name_validation_message,
							whitespace: true
						},
						{ validator: handleValidateName },
					]}
				>
					<Input autoFocus placeholder={constants.FirstName} />
				</Form.Item>
			</Col>
			<Col xs={24} lg={12}>
				<Form.Item
					className="mb-3"
					label={constants.LastName}
					name={form_last_name}
					rules={[
						{
							required: true,
							message: last_name_validation_message,
						},
						{ validator: handleValidateLastName },
					]}>
					<Input placeholder={constants.LastName} />
				</Form.Item></Col>
			<Col xs={24} lg={12}>
				<Form.Item
					className="mb-3"
					label={constants.Email}
					hasFeedback
					validateStatus={emailCheck === "checkemail" ? emailValidateStatus : null}
					name={form_email_name}
					rules={[
						{
							required: true,
							message: email_validation_message1,
						},
						{
							type: 'email',
							message: email_validation_message2,
						},
					]}
				>
					{emailCheck === "checkemail" ?
						<Input
							placeholder={constants.EmailPlaceholder}
							onChange={(e) => {
								setEmailIdValue(e.target.value.toLocaleLowerCase());
							}}
							onBlur={() => { handleCheckEmail(); }}
						/> : <Input placeholder={constants.EmailPlaceholder} />}
				</Form.Item></Col>
			<Col xs={24} lg={12}>
				<Form.Item
					className="mb-3"
					label={constants.phone}
					name={contactNo}
					rules={[
						{
							required: true,
							message: contact_no_validation_message,
						},
					]}
				>
					<PhoneInput preferredCountries={["us", "in"]} className='w-full' country={"in"} placeholder="1(999)123-4567" />
				</Form.Item>
			</Col>
		</Row>;
	};
	const handlePlanChange = (value) => {
		form.setFieldsValue({ plan_type: "0" });
		if (value === 0) {
			setClientRegistrationTypeDetails(prevState => ({
				...prevState,
				is_scholarship: "3"
			}));
			// 	setClientRegistrationTypeDetails({
			// 		...clientRegistrationTypeDetails,
			// 		is_scholarship : "0",
			// 		agreement_duration: value
			// 	});
			// }else{
			// 	setAssosiatePlanType(prevState => ({
			// 		...prevState
			// 	}));
			// 	setClientRegistrationTypeDetails({
			// 		...clientRegistrationTypeDetails,
			// 		agreement_duration: value
			// 	});
		}
		setClientRegistrationTypeDetails(prevState => ({
			...prevState,
			agreement_duration: value,
			asign_to: null
		}));
	};
	const handlePlanTypeChange = (value) => {
		setClientRegistrationTypeDetails({
			...clientRegistrationTypeDetails,
			is_scholarship: value
		});
	};
	const onChangeAsignTo = (value) => {
		setClientRegistrationTypeDetails({
			...clientRegistrationTypeDetails,
			asign_to: value
		});
	};
	const onChangeAge = (value) => {
		setClientRegistrationTypeDetails({
			...clientRegistrationTypeDetails,
			is_minor: value
		});
		if (value === "1") {
			setIsParentFormitemVissible(true);
		} else {
			setIsParentFormitemVissible(false);
		}
	};
	const onChangePayer = (value) => {
		setPayer(value);
	}
	const onChangeWbType = (value) => {
		setWorkbookType(value);
		setClientRegistrationTypeDetails(prevState => ({
			...prevState,
			agreement_duration: value === 0 ? value : 7,
			is_scholarship: null,
			asign_to: null
		}));
	};
	const handleInputChange = (value) => {
		setInputValue(value);
		calculateTotal(value, sessionValue);
	};
	const handleSessionChange = (value) => {
		setSessionValue(value);
		calculateTotal(inputValue, value);
	};
	const calculateTotal = (amount, session) => {
		const total = amount * session;
		setTotalAmount(total);
	};
	const handleSelectChange = (value) => {
		setSelectValue(value);
	};
	const handleNotesChange = (e) => {
		setEnquiryNotes(e.target.value);
	}
	const handleShowCost = (e) => {
		setShowCost(e.target.checked ? 1 : 0);
	}
	const handleShowFam = (e) => {
		setShowFam(e.target.checked ? 1 : 0);
	}
	const handleShowPsych = (e) => {
		setShowPsych(e.target.checked ? 1 : 0);
	}
	const selectAfter = (
		<Select
			value={selectValue}
			onChange={handleSelectChange}
			placement="bottomLeft"
			className="select-item-bold"
		>
			<Option value="INR">INR (₹)</Option>
			<Option value="USD">USD ($)</Option>
		</Select>
	);
	const formatNumber = (value) => {
		if (selectValue === 'INR') {
			return `${value}`
				.replace(/(\d)(?=(\d\d)+\d(\.\d{0,2})?$)/g, '$1,');
		} else {
			return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
	};
	const parseNumber = (value) => {
		return value ? value.replace(/\$\s?|(,*)/g, '') : '';
	};
	//fetching assosiate programe
	// const getAssociatePrograme = () => {
	// 	let payload ={
	// 		associate_id:getCookie(COOKIE.ClientId)
	// 	};
	// 	fetchAdminApi(`/view-programs`, "post",payload).then((res) => {
	// 		if(res.code === 200){
	// 			setAssosiatePlanType({
	// 				...assosiatePlanType,
	// 				agreement_duration:res?.data?.agreement_duration,
	// 				is_scholarship:res?.data?.is_scholarship
	// 			});
	// 		}else{
	// 			setAssosiatePlanType({
	// 				...assosiatePlanType,
	// 				agreement_duration:[],
	// 				is_scholarship:[]
	// 			});
	// 		}
	// 	});
	// };
	useEffect(() => {
		setPlanFormItem();
		setFormFields();
		setEmailValidateStatus("");
		// const openModal = sessionStorage.getItem("openModal");
		// if (openModal === "true" || clientEnquiry?.assigned_to) {
		// 	showModal();
		// 	sessionStorage.removeItem("openModal");
		// }
		// if(getCookie(COOKIE.LoginType) === "2"){
		// 	getAssociatePrograme();
		// }else{
		// 	getListAssociates();
		// }
		getListAssociates();
		setFormFields();
	}, [clientRegistrationTypeDetails?.agreement_duration, clientRegistrationTypeDetails?.is_scholarship]);

	useEffect(() => {
		form.setFieldsValue({ totalamount: totalAmount });
	}, [totalAmount, form]);

	useEffect(() => {
		if (workbookType === null) {
			setFilteredOptions(constants.AgreementOptions);
		} else if (workbookType === 0) {
			setFilteredOptions(constants.AgreementOptions.filter(option => option.value === 0));
		} else {
			setFilteredOptions(constants.AgreementOptions.filter(option => option.value !== 0));
		}
	}, [workbookType]);

	const selectFilterOption = (input, option) => {
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};

	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.RegistrationLinkText}</span>
			</div>
			<div className='bg-amber-100 text-base font-semibold p-2 mb-3'><div className="container mx-auto text-center">{isParentFormitemVissible ? constants.HeaderRegistrationLinkPopupM : clientEnquiry?.userType === "refresher" ? constants.RefresherHeader : constants.HeaderRegistrationLinkPopupA} {clientEnquiry?.userType === "new" ? `Enquiry - ${clientEnquiry?.client_name}` : `${constants.Refresher} - ${clientEnquiry?.client_firstname} ${clientEnquiry?.client_lastname}`}</div></div>
			{/* <div className='w-4/5 mx-auto py-5 border-2 border-black px-2 relative'>
				<Form 
					{...horizontalLayout} 
					labelWrap
					form={form}
				>
					<Form.Item 
						label={constants.Age}
						name="age"
					>
						<Radio.Group onChange={(e)=>onChangeAge(e.target.value)}>
							<Radio value={"0"}>{constants.Adult}</Radio>
							<Radio value={"1"}>{constants.Minor}</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						label={"Workbook Type"}
						name="typeaddict"
					>
						<Radio.Group onChange={(e) => onChangeWbType(e.target.value)}>
							<Radio value={1}>{constants.addict}</Radio>
							<Radio value={0}>{constants.NotAnAddict}</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item 
						label={constants.Program}
						name="duration"
					>
						<Radio.Group  onChange={(e)=>handlePlanChange(e.target.value)}>
							{filteredOptions.map((option) => (
								<Radio
									key={option.value}
									value={option.value}
								>
									{option.label}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
					{
						//displaying plan only when agreement duration is equal to General Coaching(0).
						clientRegistrationTypeDetails.agreement_duration === 0 ?
							<Form.Item
								label={constants.Plan}
								name="plan_type"
							>
								<Radio.Group onChange={(e) => handlePlanTypeChange(e.target.value)}>
									{
										constants.GeneralPlanOptions.map((option) => (
											<Radio
												key={option.value}
												value={option.value}
											>
												{option.label}
											</Radio>
										))
									}
								</Radio.Group>
							</Form.Item>
							: null
					}
					<Form.Item
						label={constants.AssignTo}
						name="asignto"
					>
						<Select
							style={{ width: "30%" }}
							// value={clientRegistrationTypeDetails?.asign_to}
							onChange={onChangeAsignTo}
							placeholder={constants.AssignedTo}
							showSearch
							allowClear
							filterOption={selectFilterOption}
						>{
								listAssociates?.map((associate) => <Option value={associate?.client_id} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)
							}
						</Select>
					</Form.Item>
					<div  className="float-right absolute bottom-2.5 right-5">
						<Button
							size="middle"
							className='bg-sky-800 text-white'
							// htmlType="submit"
							onClick={showModal}
						>
								Next
						</Button>
					</div>
				</Form>
			</div> */}
			{/* <Modal 
				title={ */}
			{/* // } 
				// centered 
				// style={{ top: 20 }} 
				// width={700} 
				// className="client-details"  
				// open={isModalOpen} 
				// footer={null} 
				// onCancel={handleCancel}> */}
			<div className='w-2/3 mx-auto'>
				<Spin spinning={loader} size="large">
					<Card>
						<Form
							layout="vertical"
							labelWrap
							className="client-details"
							form={form}
							onFinish={onFinishForm}
						// onFinishFailed={onFinishFailed}
						// {...layout}
						>
							<Row gutter={[24, 8]}>
								<Col xs={24} lg={12}>
									<Form.Item
										name="typeaddict"
										className='mb-3'
										label={"Workbook Type"}
										rules={[
											{
												required: true,
												message: constants.selctclientType
											},
										]}
									>
										<Radio.Group onChange={(e) => onChangeWbType(e.target.value)}>
											<Radio value={1}>{constants.addict}</Radio>
											<Radio value={0}>{constants.NotAnAddict}</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item
										className="mb-3"
										label={constants.Age}
										name={"age"}
										rules={[
											{ required: true },
										]}
									>
										<Radio.Group
											onChange={(e) => onChangeAge(e.target.value)}
											defaultValue={'0'}
										>
											<Radio value={"0"}>{constants.Adult}</Radio>
											<Radio value={"1"}>{constants.Minor}</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item
										className="mb-3"
										label={constants.Program}
										name={"duration"}
									>
										<Select
											onChange={(value) => handlePlanChange(value)}
											placeholder={constants.Program}
										>
											{filteredOptions.map((option) => (
												<Option
													key={option.value}
													value={option.value}
												// disabled={assosiatePlanType.agreement_duration.length > 0? !assosiatePlanType.agreement_duration.includes(option.value.toString()):false}
												>
													{option.label}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								{
									clientRegistrationTypeDetails.agreement_duration === 0 ?
										<Col xs={24} lg={12}>
											<Form.Item
												className="mb-3"
												label={constants.Plan}
												name={"plan_type"}
											>
												<Select
													placeholder={constants.Plan}
													onChange={(value) => handlePlanTypeChange(value)}
												>
													{
														constants.GeneralPlanOptions.map((option) => (
															<Option
																key={option.value}
																value={option.value}
															>
																{option.label}
															</Option>
														))}
												</Select>
											</Form.Item>
										</Col> :
										null
								}
								<Col xs={24} lg={12}>
									<Form.Item
										label={constants.AssignTo}
										name="asignto"
									>
										<Select
											placeholder={constants.AssignedTo}
											onChange={onChangeAsignTo}
											showSearch
											allowClear
											filterOption={selectFilterOption}
										>
											{
												listAssociates?.map((associate) => <Option value={associate?.client_id} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)
											}
										</Select>
									</Form.Item>
								</Col>

								<Divider>
									<span className="font-semibold">
										{isParentFormitemVissible ?
											constants.ParentsDetails :
											constants.clientDetailFormheader
										}
									</span>
								</Divider>
								<Col xs={24} lg={24}>
									{isParentFormitemVissible &&
										formItemNameEmailPhone(
											"parent_first_name",
											"parent_last_name",
											constants.FirstNameRegistration,
											constants.LastNameRegistration,
											"parent_email",
											constants.EmailRegistration,
											constants.emailVaidationError,
											"parentcontactNo",
											"dontcheckemail",
											constants.ParentPhoneNoRegistration
										)
									}
								</Col>
								<Col xs={24} lg={24}>
									{/* children details */}
									{isParentFormitemVissible && (<Divider><span className="font-semibold">{constants.childDetailFormheader}</span></Divider>)}
									{formItemNameEmailPhone(
										"first_name",
										"last_name",
										constants.FirstNameRegistration,
										constants.LastNameRegistration,
										"email",
										constants.EmailRegistration,
										constants.emailVaidationError,
										"contactNo",
										"checkemail",
										constants.PhoneNoRegistration
									)}
								</Col>
								{/* displaying plan only when agreement duration is equal to General Coaching(0). */}

								{
									clientRegistrationTypeDetails.agreement_duration !== 100 ?
										<Col xs={24} lg={12}>
											<Form.Item
												className="mb-3"
												name={"amount"}
												label={clientRegistrationTypeDetails.agreement_duration === 0 ? constants.SessionCost : constants.PlanCost}
											>
												<InputNumber
													min={1}
													className="w-full"
													value={inputValue}
													onChange={handleInputChange}
													addonBefore={selectAfter}
													formatter={formatNumber}
													parser={parseNumber}
												/>
											</Form.Item></Col> :
										null
								}

								{
									clientRegistrationTypeDetails.agreement_duration === 0 ?
										<Col xs={24} lg={12}>
											<Form.Item
												className="mb-3"
												name={"session"}
												label={constants.Sessions}
												rules={[
													{
														required: true,
														message: constants.RequiredField,
													},
												]}
											>
												<InputNumber
													min={1}
													className="w-full"
													value={sessionValue}
													onChange={handleSessionChange}
												/>
											</Form.Item></Col> : null
								}

								{
									clientRegistrationTypeDetails.agreement_duration === 0 ?
										<Col xs={24} lg={12}>
											<Form.Item
												className="mb-3"
												name={"totalamount"}
												label={constants.TotalCost}
											>
												<InputNumber
													min={1}
													className="w-full"
													value={totalAmount}
													disabled={true}
													addonBefore={selectAfter}
													formatter={formatNumber}
													parser={parseNumber}
												/>
											</Form.Item>
										</Col> : null
								}

								{
									clientRegistrationTypeDetails.agreement_duration !== 100 ? <Col xs={24} lg={12}>
										<div className='flex items-end h-full'>
											<Form.Item
												className="mb-3"
												name={"show_cost"}
											>
												<Checkbox checked={showCost} onChange={handleShowCost}>
													{constants.DisplayCostMsg}
												</Checkbox>
											</Form.Item>
										</div>
									</Col> : null
								}

								{
									clientRegistrationTypeDetails.agreement_duration !== 100 ?
										<Col xs={24} lg={24}>
											<Form.Item
												className="mb-3"
												name={"notes"}
												label={constants.Notes}
											>
												<TextArea
													className="w-full"
													autoFocus
													value={enquiryNotes}
													onChange={handleNotesChange}
													placeholder={constants.Notes}
													rows={2}
												/>
											</Form.Item>
										</Col> : null
								}

								<Col xs={24} lg={12}>
									<div className='flex items-end h-full'>
										<Form.Item
											name="show_family"
											className='mb-3'
										>
											<Checkbox checked={showFam} onChange={handleShowFam}>
												{constants.DisplayFamSess}
											</Checkbox>
										</Form.Item>
									</div>
								</Col>
								<Col xs={24} lg={12}>
									<div className='flex items-end h-full'>
										<Form.Item
											name="show_psych"
											className='mb-3'
										>
											<Checkbox checked={showPsych} onChange={handleShowPsych}>
												{constants.DisplayPsychAssess}
											</Checkbox>
										</Form.Item>
									</div>
								</Col>
								<Col xs={24} lg={24}>
									<Form.Item
										className="mb-3"
										label={constants.PaidBy}
										name={"paid_by"}
										rules={[
											{
												required: true,
												message: constants.SelectPaymentBy
											},
										]}
									>
										<Radio.Group onChange={(e) => onChangePayer(e.target.value)}>
											{enquiryDetails && (
												<>
													{/* Always render Client */}
													<Radio value={"0"}>{constants.Client}</Radio>

													{/* Conditionally render Parent if is_minor is 1, regardless of is_self */}
													{enquiryDetails?.is_minor === "1" && (
														<Radio value={"3"}>{constants.Parent}</Radio>
													)}

													{/* Render Other only if is_self is 0 */}
													{enquiryDetails?.is_self === "0" && (
														<Radio value={"1"}>{constants.Other}</Radio>
													)}

													{/* Always render Caller */}
													<Radio value={"2"}>{constants.Caller}</Radio>
												</>
											)}
										</Radio.Group>
									</Form.Item>
								</Col>
								{(payer === "2" || payer === "1") && (
									<Divider>
										<span className="font-semibold">
											{payer === "2" ? constants.callerDetailFormheader : constants.otherDetailFormheader}
										</span>
									</Divider>
								)}
								{/* <Col xs={24} lg={24}> */}
								{payer === "2" && (
									<>
										<Col xs={24} lg={24}>
											{formItemNameEmailPhone(
												"caller_first_name",
												"caller_last_name",
												constants.FirstNameRegistration,
												constants.LastNameRegistration,
												"caller_email",
												constants.EmailRegistration,
												constants.emailVaidationError,
												"callercontactNo",
												"dontcheckemail",
												constants.ParentPhoneNoRegistration
											)}
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item
												className="mb-3"
												label={constants.Relation}
												name={"caller_relation"}
												rules={[
													{
														required: true,
														message: constants.RequiredField,
														whitespace: true,
													},
												]}
											>
												<Input autoFocus placeholder={constants.Relation} />
											</Form.Item>
										</Col>
									</>
								)}
								{payer === "1" &&
									formItemNameEmailPhone(
										"other_first_name",
										"other_last_name",
										constants.FirstNameRegistration,
										constants.LastNameRegistration,
										"other_email",
										constants.EmailRegistration,
										constants.emailVaidationError,
										"othercontactNo",
										"dontcheckemail",
										constants.ParentPhoneNoRegistration
									)
								}
								{/* </Col> */}
								<Col xs={24} lg={24}>
									<Form.Item className='flex justify-end'>
										<Space>
											<Button
												size="middle"
												onClick={handlePreview}
											>
												{constants.Preview}
											</Button>
											{/* <Button
												size="middle"
												onClick={handleCancel}
											>
												{constants.CancelBTN}
											</Button> */}
											<Button
												size="middle"
												className='bg-sky-800 text-white'
												htmlType="submit"
											>
												{constants.SendBtn}
											</Button>
										</Space>
									</Form.Item></Col>
							</Row>
						</Form>
					</Card>
				</Spin>
			</div>
			{/* </Modal> */}
		</div>
	);
};
export default RegistrationPlan;