import React, { useEffect, useState } from 'react';
import constants from '../../../constants/constants';
import { COOKIE, getCookie } from "../../../services/cookie";
import moment from "moment";
import { Space, Form, Input, Table, Spin, Tooltip, Popconfirm, Button, message } from 'antd';
import { ShareAltOutlined, SearchOutlined, EyeOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { fetchAdminApi, fetchDownloadApi } from '../../../services/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ServerDocs = () => {
    const history = useHistory();
    const loginType = getCookie(COOKIE.LoginType);
    const [documentList, setDocumentList] = useState([]);
    const [pagination, setPagination] = useState(constants.paginationConfig);
    const [loader, setLoader] = useState(true);
    const [searchName, setSearchName] = useState("");
    const options = [
        { value: "1", label: "Medical Info Release" },
        { value: "8", label: "Age Proof" },
        { value: "6", label: "Coaching Agreement" },
        { value: "2", label: "Discharge Report" },
        { value: "3", label: "Medical Report" },
        { value: "7", label: "Other" },
        { value: "5", label: "Parent Consent" },
        { value: "4", label: "Prescription" },
        { value: "9", label: constants.ConsentForm },
    ];

    useEffect(() => {
        getFileList();
    }, [searchName]);

    const getFileList = () => {
        setLoader(true);
        let payload = {};
        if (searchName) {
            payload.name = searchName
        }
        fetchAdminApi(`/fetch-documents`, "post", payload).then((res) => {
            if (res?.data) {
                const dataWithSerialNumbers = res.data.map((record, index) => ({
                    ...record,
                    serialNumber: index + 1,
                }));
                setDocumentList(dataWithSerialNumbers);
                setLoader(false);
            }
            else {
                setDocumentList([]);
                setLoader(false);
            }
        });
    };
    const routeToEditClientEnquiry = (enquiry_id, client_id, record, enable = null) => {
        let pathname;
        let state;

        if (record?.enquiry_id) {
            if (record.status === "4" || record.status === "9") {
                sessionStorage.removeItem("enquiryDetails");
                pathname = "/edit-client";
                state = { client_id: record.client_id };
            } else {
                sessionStorage.setItem("enquiryDetails", JSON.stringify(record));
                pathname = "/edit-enquiry";
                state = { enquiry_id: enquiry_id };
            }
        } else {
            let payload = {};
            if (enable) {
                payload.client_id = client_id;
                payload.enable = true;
            } else {
                payload.client_id = client_id;
            }
            pathname = "/edit-client";
            state = payload;
        }

        history.push({
            pathname: pathname,
            state: state
        });
    };
    const handleDelete = (doc_name) => {
        fetchAdminApi(`/delete-document/${doc_name}`, "delete").then((res) => {
            if (res?.code === 200) {
                message.success(res?.message);
                getFileList();
            }
            else {
                message.error(res?.message);
            }
        });
    }
    const downloadDoc = (doc_id, file_extension, doc_name) => {
        let payload = {
            file_type: file_extension
        };
        if (doc_id) {
            payload.doc_id = doc_id;
        } else {
            payload.doc_name = doc_name;
        }
        fetchDownloadApi(`/view-document`, "post", payload, constants.ApiUrl).then(
            (res) => {
                if (res) {
                    let url, a;
                    url = window.URL.createObjectURL(
                        new Blob([res], { type: "application/msword" }),
                        { type: "application/msword" }
                    );
                    let filename = doc_name;
                    a = document.createElement("a");
                    a.download = filename;
                    a.href = url;
                    a.dispatchEvent(new MouseEvent("click"));
                    setLoader(false);
                } else {
                    message.error("Failed to download");
                    setLoader(false);
                }
            }
        );
    };
    const viewDocument = (doc_id, file_extension, doc_name) => {
        if (file_extension === "doc" || file_extension === 'docx') {
            downloadDoc(doc_id, file_extension, doc_name);
        } else {
            let documentValue = {
                file_type: file_extension,
            };
            if (doc_id) {
                documentValue.doc_id = doc_id;
            } else {
                documentValue.doc_name = doc_name;
            }
            sessionStorage.setItem("documentValue", JSON.stringify(documentValue));
            window.open(constants.BaseUrl + `/view-document`);
        }
    };
    const onSearch = (event) => {
        let search = event.target.value;
        setSearchName(search);
    };
    const getTypeLabel = (record) => {
        const option = options.find(option => option.value === record);
        return option ? option.label : "";
    };
    const columns = [
        {
            title: '#',
            align: "center",
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: "5%",
        },
        {
            title: 'Name',
            dataIndex: 'client_name',
            key: 'client_name',
            width: "10%",
            render: (name, record) => {
                return name ? <Space>
                    <a onClick={() => routeToEditClientEnquiry(record?.enquiry_id, record?.client_id, record)}>
                        <Space>
                            {loginType === "2" ? <ShareAltOutlined /> : <SearchOutlined />}{name}
                        </Space>
                    </a>
                </Space> : "";
            }
        },
        {
            title: 'Date Uploaded',
            dataIndex: 'dt_uploaded',
            key: 'dt_uploaded',
            width: "10%",
            render: (record) => {
                return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: "20%",
            render: (record, val) => <span>{getTypeLabel(record)}</span>,
        },
        {
            title: 'File',
            dataIndex: 'doc_name',
            key: 'doc_name',
            render: (record) => {
                return <span>{record || ""}</span>;
            },
        },
        {
            title: 'Document Present',
            dataIndex: 'is_present',
            key: 'is_present',
            width: '5%',
            align: "center",
            render: (is_present) => {
                return (is_present === 1 ? <Tooltip title={"File is present."}><CheckOutlined /></Tooltip> : "");
            },
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_till',
            key: 'valid_till',
            width: "10%",
            render: (record) => {
                return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
            },
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            render: (record) => {
                return <span>{record || ""}</span>;
            },
        },
        {
            title: 'Action',
            align: "center",
            render: (_, record) => {
                return <Space>
                    <Tooltip title={"View"}><Button
                        onClick={() => viewDocument(record?.doc_id, record?.file_extension, record?.doc_name)}
                        type='text'
                        icon={<EyeOutlined />}
                    /></Tooltip>
                    <Tooltip title={"Delete"}>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record?.doc_name)}>
                            <Button
                                type='text'
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>;
            }
        },
    ];

    const onChangeTable = (paginate, sort, filter) => {
        setPagination(paginate);
    };

    return (
        <>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">Server Docs</span>
            </div>
            <div className='container mx-auto mt-2'>
                <div className='flex flex-col sm:flex-row flex-wrap xl:flex-nowrap justify-between gap-2 sm:items-center'>
                    <Form.Item label="Search" className='!mb-0'>
                        <Input className='w-full lg:w-52' size="middle" onChange={onSearch} />
                    </Form.Item>
                </div>
                <Spin spinning={loader} size="large">
                    <Table
                        dataSource={documentList}
                        columns={columns}
                        pagination={pagination}
                        onChange={onChangeTable}
                        bordered
                        scroll={{ x: 900 }}
                        locale={{ emptyText: 'No Records Found' }}
                        className='mt-2'
                        size="small"
                        rowKey="doc_name"
                    />
                </Spin>
            </div>
        </>
    )
};
export default ServerDocs;