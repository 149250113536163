/*
File Name: AccountActivation.js
Author: Dhiraj Rajput
Modified On: 22/12/2022
Description:Verifying the registered user.
API's used:
1)/client/check-activation-validity/${tokenValue}
2) /client/register/resend-activation-link
*/
import React, { useEffect, useState } from "react";
import { Row, Col, Image, Card,Button } from 'antd';
import constants from '../../../constants/constants';
import logo from '../../../logo-blue-black.svg';
import { fetchApi } from "../../../services/api";
import { useHistory } from "react-router";
const AccountActivation = () => {
	const history = useHistory();
	const [clientToken, setClientToken] = useState("");
	const [displayValidationMessage, setValidationMessage] = useState("");
	const [showLoginButton, setShowLoginButton] = useState(false);
	const [showResendMessage, setresendMessage] = useState(false);
	const [apiWait, setApiWait] = useState(false);
	useEffect(() => {
		fetchUrlParameters();
	}, []);
	const fetchUrlParameters = () => {
		let queryString = window.location.href;
		let token = queryString.substring(queryString.lastIndexOf('?') + 1);
		setClientToken(token);
		checkActivationvalidity(token);
	};
	const checkActivationvalidity = (tokenValue) => {
		fetchApi(`/client/check-activation-validity/${tokenValue}`, "post").then((res) => {
			if (res && res.code) {
				setValidationMessage(res.message);
				let clientId = res.client_id ? res.client_id : null;
				setresendMessage(false);
				if ((res.code === 400 && clientId === null) || (res.code === 200 && clientId === null)) {
					setShowLoginButton(true);
				} else {
					setShowLoginButton(false);
				}
				setApiWait(true);
			} else {
				setApiWait(true);
			}
		});
	};
	const resendActivationLink = () => {
		let payload = null;
		payload = { "token": clientToken };
		fetchApi(`/client/register/resend-activation-link`, "post", payload).then((res) => {
			if (res && res.code) {
				setValidationMessage(res.message);
				setresendMessage(true);
				if (res.code == 200) {
					setShowLoginButton(false);
				} else {
					setShowLoginButton(true);
				}
			}
		});
	};
	return (
		<div>
			<Row gutter={[0, 16]}>
				<Col xs={24} sm={24} lg={9} className="bg-amber-300 text-yellow-600 capitalize text-3xl font-semibold lg:min-h-screen">
					<div className='mx-2 md:mx-6 md:mt-8'>
						<Image src={logo} alt="RoyCares & Associates" preview={false}/><br/>
						<div className='pt-6'>
							{constants.AnyoneRecover}
						</div>
						<div>
							{constants.Tagline}
						</div>
					</div>
				</Col>
				<Col xs={24} sm={24} lg={15} className="lg:min-h-screen">
					<div className='flex items-center justify-center h-full text-center'>
						{apiWait === true ?
							<Card className="border-2 border-sky-600">
								<div className='text-2xl lg:text-3xl font-semibold p-3'>{constants.AccountActivation}</div>
								<>
									{showLoginButton === true || showResendMessage === true ?
										<p dangerouslySetInnerHTML={{ __html: displayValidationMessage }}/>
										:
										<p >{constants.LinkExpired} Click <a onClick={() => resendActivationLink()}>here </a>{constants.ActivationLintGenerate}</p>
									}
								</>
								{showLoginButton === true ?
									<div className="text-right">
										<Button className="bg-sky-800 text-white mt-5" onClick={() => history.push("/login")}>Go to Login</Button>
									</div> : null}
							</Card>
							: null}
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default AccountActivation;
