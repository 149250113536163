import React, { useEffect, useState } from 'react';
import constants from '../../../constants/constants';
import { COOKIE, getCookie } from "../../../services/cookie";
import moment from "moment";
import { Button, Form, Space, Table, Input, Select, Typography, Spin, Tooltip, message } from 'antd';
import { DownloadOutlined, ShareAltOutlined, SearchOutlined } from '@ant-design/icons';
import { fetchAdminApi, fetchDownloadApi } from '../../../services/api';
import { DOB } from '../../../globalFunctions/GlobalFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ClientDocs = () => {
    const history = useHistory();
    const loginType = getCookie(COOKIE.LoginType);
    const [documentList, setDocumentList] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [pagination, setPagination] = useState(constants.paginationConfig);
    const [loader, setLoader] = useState(true);
    const [date, setDate] = useState({
        fromDate: {
            date: null,
            month: null,
            year: null
        },
        toDate: {
            date: null,
            month: null,
            year: null
        }
    });
    const [searchName, setSearchName] = useState("");
    const options = [
        { value: "1", label: "Medical Info Release" },
        { value: "8", label: "Age Proof" },
        { value: "6", label: "Coaching Agreement" },
        { value: "2", label: "Discharge Report" },
        { value: "3", label: "Medical Report" },
        { value: "7", label: "Other" },
        { value: "5", label: "Parent Consent" },
        { value: "4", label: "Prescription" },
        { value: "9", label: constants.ConsentForm },
    ];
    const sortedOptions = options.slice().toSorted((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        getFileList();
    }, [date.fromDate.date && date.fromDate.month && date.fromDate.year, date.toDate.date && date.toDate.month && date.toDate.year, searchName, selectedType]);

    const getFileList = () => {
        setLoader(true);
        let payload = {};
        if (date.fromDate.date && date.fromDate.month && date.fromDate.year) {
            payload.start_date = `${date.fromDate.year}-${date.fromDate.month}-${date.fromDate.date}`
        }
        if (date.toDate.date && date.toDate.month && date.toDate.year) {
            payload.end_date = `${date.toDate.year}-${date.toDate.month}-${date.toDate.date}`
        }
        if (searchName) {
            payload.name = searchName
        }
        if (selectedType !== null) {
            payload.type = selectedType;
        }
        fetchAdminApi(`/view-all-documents`, "post", payload).then((res) => {
            if (res?.data) {
                const dataWithSerialNumbers = res.data.map((record, index) => ({
                    ...record,
                    serialNumber: index + 1,
                }));
                setDocumentList(dataWithSerialNumbers);
                setLoader(false);
            }
            else {
                setDocumentList([]);
                setLoader(false);
            }
        });
    };
    const downloadDoc = (doc_id, file_extension, doc_name) => {
        let payload = {
            doc_id: doc_id,
            file_type: file_extension
        };
        fetchDownloadApi(`/view-document`, "post", payload, constants.ApiUrl).then(
            (res) => {
                if (res) {
                    let url, a;
                    url = window.URL.createObjectURL(
                        new Blob([res], { type: "application/msword" }),
                        { type: "application/msword" }
                    );
                    let filename = doc_name;
                    a = document.createElement("a");
                    a.download = filename;
                    a.href = url;
                    a.dispatchEvent(new MouseEvent("click"));
                    setLoader(false);
                } else {
                    message.error("Failed to download");
                    setLoader(false);
                }
            }
        );
    };
    const viewDocument = (doc_id, file_extension, doc_name) => {
        if (file_extension === "doc" || file_extension === 'docx') {
            downloadDoc(doc_id, file_extension, doc_name);
        } else {
            let documentValue = {
                doc_id: doc_id,
                file_type: file_extension,
            };
            sessionStorage.setItem("documentValue", JSON.stringify(documentValue));
            window.open(constants.BaseUrl + `/view-document`);
        }
    };
    const routeToEditClientEnquiry = (enquiry_id, client_id, record, enable = null) => {
        let pathname;
        let state;

        if (record?.enquiry_id) {
            if (record.status === "4" || record.status === "9") {
                sessionStorage.removeItem("enquiryDetails");
                pathname = "/edit-client";
                state = { client_id: record.client_id };
            } else {
                sessionStorage.setItem("enquiryDetails", JSON.stringify(record));
                pathname = "/edit-enquiry";
                state = { enquiry_id: enquiry_id };
            }
        } else {
            let payload = {};
            if (enable) {
                payload.client_id = client_id;
                payload.enable = true;
            } else {
                payload.client_id = client_id;
            }
            pathname = "/edit-client";
            state = payload;
        }

        history.push({
            pathname: pathname,
            state: state
        });
    };
    const onSearch = (event) => {
        let search = event.target.value;
        setSearchName(search);
    };
    const handleTypeChange = (value) => {
        setSelectedType(value);
    };
    //from date onchange
    const handleDayChangeFD = (value) => {
        setDate((prevDate) => ({
            ...prevDate,
            fromDate: {
                ...prevDate.fromDate,
                date: value,
            },
        }));
    };
    const handleMonthChangeFD = (value) => {
        setDate((prevDate) => ({
            ...prevDate,
            fromDate: {
                ...prevDate.fromDate,
                month: value,
            },
        }));
    };
    const handleYearChangeFD = (value) => {
        setDate((prevDate) => ({
            ...prevDate,
            fromDate: {
                ...prevDate.fromDate,
                year: value,
            },
        }));
    };
    //to date onchange
    const handleDayChangeTD = (value) => {
        setDate((prevDate) => ({
            ...prevDate,
            toDate: {
                ...prevDate.toDate,
                date: value,
            },
        }));
    };
    const handleMonthChangeTD = (value) => {
        setDate((prevDate) => ({
            ...prevDate,
            toDate: {
                ...prevDate.toDate,
                month: value,
            },
        }));
    };
    const handleYearChangeTD = (value) => {
        setDate((prevDate) => ({
            ...prevDate,
            toDate: {
                ...prevDate.toDate,
                year: value,
            },
        }));
    };
    const getTypeLabel = (record) => {
        const option = options.find(option => option.value === record);
        return option ? option.label : "";
    };
    const columns = [
        {
            title: '#',
            align: "center",
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: "5%",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "10%",
            render: (name, record) => {
                return name ? <Space>
                    <a onClick={() => routeToEditClientEnquiry(record?.enquiry_id, record?.client_id, record)}>
                        <Space>
                            {loginType === "2" ? <ShareAltOutlined /> : <SearchOutlined />}{name}
                        </Space>
                    </a>
                </Space> : "";
            }
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Date Uploaded',
            dataIndex: 'dt_uploaded',
            key: 'dt_uploaded',
            width: "10%",
            render: (record) => {
                return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
            },
            // sorter: (a, b) => moment(a.dt_uploaded).unix() - moment(b.dt_uploaded).unix(),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: "20%",
            render: (record, val) => <span>{getTypeLabel(record)}</span>,
            // sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Details',
            dataIndex: 'doc_details',
            key: 'doc_details	',
            render: (record) => {
                return <span>{record || ""}</span>;
            },
            // sorter: (a, b) => {
            //     const detailsA = a.doc_details || '';
            //     const detailsB = b.doc_details || '';
            //     return detailsA.localeCompare(detailsB);
            // },
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_till',
            key: 'valid_till',
            width: "10%",
            render: (record) => {
                return <span>{record ? moment(record).format(constants.dateformat) : ""}</span>;
            },
        },
        {
            title: 'File',
            dataIndex: 'file_extension',
            key: 'file_extension',
            width: "10%",
            render: (file_extension, record) => {
                return (
                    <Space>
                        <Typography.Text>{file_extension.toUpperCase()}</Typography.Text>
                        {record?.download === "1" ?
                            <Tooltip title={"Download"}><Button
                                onClick={() => viewDocument(record?.doc_id, record?.file_extension, record?.doc_name)}
                                type='text'
                                icon={<DownloadOutlined />}
                            /></Tooltip> :
                            null}
                    </Space>
                );
            },
            // sorter: (a, b) => a.file_extension.localeCompare(b.file_extension),
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by_name',
            key: 'uploaded_by_name',
            render: (record) => {
                return <span>{record || ""}</span>;
            },
            // sorter: (a, b) => a.uploaded_by_name.localeCompare(b.uploaded_by_name),
        }
    ].filter(item => !item.hidden);

    const onChangeTable = (paginate, sort, filter) => {
        setPagination(paginate);
    };

    return (
        <>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ClientDocs}</span>
            </div>
            <div className='container mx-auto mt-2'>
                <div className='flex flex-col sm:flex-row flex-wrap xl:flex-nowrap justify-between gap-2 sm:items-center'>
                    <Form.Item label="Search" className='!mb-0'>
                        <Input className='w-full lg:w-52' size="middle" onChange={onSearch} />
                    </Form.Item>
                    <Form.Item label="Type" className='!mb-0'>
                        <Select
                            className='!w-full sm:!w-52'
                            placeholder="Select type"
                            value={selectedType}
                            onChange={handleTypeChange}
                            allowClear
                        >
                            {sortedOptions.map(option => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Space className='flex flex-col sm:flex-row items-start	'>
                        <div className='dob-mb-0'>{DOB(date.fromDate.month, handleMonthChangeFD, date.fromDate.date, handleDayChangeFD, date.fromDate.year, handleYearChangeFD, "From", null, null, null, false, true)}</div>
                        <div className='dob-mb-0'>{DOB(date.toDate.month, handleMonthChangeTD, date.toDate.date, handleDayChangeTD, date.toDate.year, handleYearChangeTD, "To", null, null, null, false, true)}</div>
                    </Space>
                </div>
                <div className=' flex justify-end my-2'>
                    <Tooltip title="Server Docs"><Button style={{ visibility: "hidden" }} className="float-right bg-sky-800 text-white" onClick={() => { window.open(`${constants.BaseUrl}/server-docs`, '_blank'); }}>Server Docs</Button></Tooltip>
                </div>
                <Spin spinning={loader} size="large">
                    <Table
                        dataSource={documentList}
                        columns={columns}
                        pagination={pagination}
                        onChange={onChangeTable}
                        bordered
                        scroll={{ x: 900 }}
                        locale={{ emptyText: 'No Records Found' }}
                        className='mt-2'
                        size="small"
                        rowKey="doc_id"
                    />
                </Spin>
            </div>
        </>
    )
};
export default ClientDocs;