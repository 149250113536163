// import constants from '../constants/constants';
/* eslint-disable no-unused-vars */
import { Row, Col, Image, Form, Input, Button, InputNumber, Select, Progress, Card, Table, Space, Modal, Upload,Tooltip, Radio, Popconfirm, Checkbox, Spin } from 'antd';
import { EllipsisOutlined, UploadOutlined, CloseOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import GaugeChart from 'react-gauge-chart';
import constants from '../constants/constants';
import { COOKIE, getCookie } from "../../src/services/cookie";
import ReactModal from 'react-modal-resizable-draggable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React from 'react';
const { TextArea } = Input;
const { Option } = Select;
const loginType = getCookie(COOKIE.LoginType);
const chartStyle = {
	height: 250,
	width: 540,
};
const dataSource = [
	{
		key: '1',
		name: 'My dad',
		reason: '',
		affected: '',
		reaction: '',
	},
	{
		key: '2',
		name: 'Math teacher',
		reason: '',
		affected: '',
		reaction: '',
	},
];
const columns = [
	{
		title: 'The Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'What they did to me?',
		dataIndex: 'reason',
		key: 'reason',
	},
	{
		title: 'Which instinct was affected?',
		dataIndex: 'affected',
		key: 'affected',
	},
	{
		title: 'How did I react',
		dataIndex: 'reaction',
		key: 'reaction',
	},
];
export function myTrim(myString) {
	return myString.replace(/^\s+|\s+$/gm, "");
}
export function removeExtraSpaces(myString) {
	let tempStr = myTrim(myString);
	return tempStr.replace(/\s\s+/g, " ");
}
export function spacesNotAllowed(myString) {
	return myString.replace(/\s/g, "");
}
export const template0 = (title, option_message, scale_msg) => {
	let value = null;
	if (title) {
		value = title;
	} else if (option_message) {
		value = option_message;
	} else if (scale_msg) {
		value = scale_msg;
	}
	return (
		<div>
			<div className='font-bold mb-3 text-center' dangerouslySetInnerHTML={{ __html: value }}/>
		</div>
	);
};
export const template1 = (src, type) => {
	if (type === "I") {
		return (
			<div className="grid place-content-center place-items-center py-3.5">
				<Image
					alt="img"
					preview={false}
					src={src}
				/>
			</div>
		);
	} else {
		return (
			<div className="grid place-content-center place-items-center py-3.5">
				<Image
					className="max-w-5xl"
					src={src}
					preview={{ src }}
				/>
			</div>
		);
	}
};
//pop up for instruction message and esign message;
export const popupMessage = (title, open, onOk, message1, sectionId, message2, message3) => {
	return (
		<>
			<Modal
				title={
					<div className="text-2xl font-bold mb-2.5 text-yellow-500">
						{title}
					</div>
				}
				open={open}
				onOk={onOk}
				okButtonProps={{
					className: 'bg-sky-800 text-white uppercase',
					type: "default"
				}}
				cancelButtonProps={{ hidden: true }}
				closable={false}
				className="text-center"
				width={600}
			>
				<div className="text-lg font-semibold text-slate-500">
					{message1} {sectionId}
					{message2}
					{message3}
				</div>
			</Modal>
		</>
	);
};
export const template2 = (title, data, option_message, scale_msg) => {
	if (option_message) {
		return <div className="font-semibold" dangerouslySetInnerHTML={{ __html: option_message }}/>;
	} else if (scale_msg) {
		return <div className="font-semibold" dangerouslySetInnerHTML={{ __html: scale_msg }}/>;
	}
	else {
		return (
			<div>
				<div className='font-semibold mb-3 text-center' dangerouslySetInnerHTML={{ __html: title }}/>
				<div dangerouslySetInnerHTML={{ __html: data }}/>
			</div>
		);
	}
};
export const template4 = (src, contentright, type) => {
	return (
		<div>
			<Row gutter={[24, 16]} className="py-3.5">
				<Col xs={24} lg={8} className="text-center">
					{type === "I" ? <Image
						className='border-2 rounded-md border-gray-300 bg-white overflow-hidden mt-1.5'
						alt="img"
						preview={false}
						src={src}
					/> : <Image
						width={400}
						height={300}
						className='mt-1.5'
						src={src}
						preview={{ src: src }}
					/>}
				</Col>
				<Col xs={24} lg={16}>
					<div dangerouslySetInnerHTML={{ __html: contentright }}/>
				</Col>
			</Row>
		</div>
	);
};
export const template6 = (contentLeft, title,url) => {
	return (
		<div className='text-center py-2'>
			{contentLeft !== "" && contentLeft !== null && (
				<a href={contentLeft} target="_blank" rel="noreferrer">
					<Image
						alt="Video thumbnail"
						preview={false}
						src={url}
					/>
				</a>
			)}
			<div>
				<Button type="link" className='text-lg font-semibold' href={contentLeft} target="_blank">{title}</Button>
			</div>
		</div>
	);
};
export const template9 = (persent) => {
	let val = persent / 7;
	return (
		<div className="text-center">
			<div className='flex justify-center items-center stress-chart'>
				<GaugeChart
					id="gauge-chart2"
					style={chartStyle}
					nrOfLevels={7}
					percent={val}
					formatTextValue={_value => persent}
				/>
			</div>
		</div>
	);
};
export const template9card = () => {
	return (
		<div className="stress-crds">
			<Card className='border-l-25'>
				If your Score between 2 & 3, you are doing well. The only suggestion we make is that you go over your score sheet to be sure you have been honest in your responses.
			</Card>
		</div>
	);
};
export const template10 = (content_left) => {
	return (
		<div className="text-center">
			<div className="font-semibold mb-3">What is your score?</div>
			<Progress type="circle" percent={content_left}/>
		</div>
	);
};
export const template11 = (contentleft, contentright, typeleft, typeright) => {
	return (
		<Row gutter={[8, 8]} className="py-3.5">
			<Col xs={24} lg={12} className="grid place-content-center place-items-center">
				{typeleft === "I" ? <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					alt="img"
					src={contentleft}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					src={contentleft}
					preview={{ src: contentleft }}
				/>}
			</Col>
			<Col xs={24} lg={12} className="grid place-content-center place-items-center">
				{typeright === "I" ? <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					alt="img"
					src={contentright}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 overflow-hidden'
					src={contentright}
					preview={{ src: contentright }}
				/>}
			</Col>
		</Row>
	);
};
// Label with input & textarea field inside a Card.
export const template12 = (content_left) => {
	return (
		<div className='grid place-content-center place-items-center'>
			<Card className='border-2 border-black p-3 my-4 w-9/12'>
				<div dangerouslySetInnerHTML={{ __html: content_left }}/>
			</Card>
		</div>
	);
};
// Table with blue header
export const template14 = () => {
	return (
		<div className='py-2'>
			<Table className='blue-header' dataSource={dataSource} columns={columns} bordered/>
		</div>
	);
};
// Text inupt with Ellipsis button to open popup.
export const template15 = () => {
	return (
		<div className='py-2'>
			<Space>
				<Input className='w-96'/>
				<Button type='text' icon={<EllipsisOutlined/>}/>
			</Space>
			{/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}> */}
			<Form.Item>
				<Input/>
			</Form.Item>
			<Form.Item
				label="What they did to me?"
			>
				<TextArea rows={2}/>
			</Form.Item>
			<Form.Item
				label="Which instict was affected?"
			>
				<Select
					defaultValue="relationships"
					options={[
						{
							label: 'Sex',
							options: [
								{
									label: 'Connections',
									value: 'connections',
								},
								{
									label: 'Relationships',
									value: 'relationships',
								},
							],
						},
						{
							label: 'Social',
							options: [
								{
									label: 'Fame',
									value: 'fame',
								},
								{
									label: 'Popularity',
									value: 'relationships',
								},
								{
									label: 'Self-esteem',
									value: 'selfesteem',
								},
							],
						},
						{
							label: 'Survival',
							options: [
								{
									label: 'Jobs',
									value: 'jobs',
								},
								{
									label: 'Money',
									value: 'money',
								},
								{
									label: 'Power',
									value: 'power',
								},
							],
						},
					]}
				/>
			</Form.Item>
			<Form.Item
				label="How did I react?"
			>
				<TextArea rows={2}/>
			</Form.Item>
			{/* </Modal> */}
		</div>
	);
};
// Image on the left and text with textbox on the right
export const template16 = (content_left, type_left) => {
	return (
		<Row gutter={[24, 16]}>
			<Col xs={24} lg={8} className="text-center">
				{type_left === "I" ? <Image
					className='border-2 rounded-md border-gray-300 bg-white overflow-hidden'
					alt="img"
					src={ content_left}
					preview={false}
				/> : <Image
					className='border-2 rounded-md border-gray-300 bg-white overflow-hidden'
					alt="img"
					src={ content_left}
					preview={{ src: content_left }}
				/>}
			</Col>
			<Col xs={24} lg={16}>
				<div>Question text...</div>
				<TextArea rows={3}/>
			</Col>
		</Row>
	);
};
// Curved card with background color & shadow having image on the left & text on the right.
export const template17 = (content_left, content_right, type_left) => {
	return (
		<Card className='rounded-2xl border border-black shadow-xl bg-amber-300'>
			<Row gutter={[24, 16]}>
				<Col xs={24} lg={8} className="text-center">
					{type_left === "I" ? <Image
						className='border-2 rounded-md border-gray-300 bg-white overflow-hidden'
						alt="img"
						src={ content_left}
						preview={false}
					/> : <Image
						className='border-2 rounded-md border-gray-300 bg-white overflow-hidden'
						alt="img"
						src={ content_left}
						preview={{ src: content_left }}
					/>}
				</Col>
				<Col xs={24} lg={16}>
					<div dangerouslySetInnerHTML={{ __html: content_right }}/>
				</Col>
			</Row>
		</Card>
	);
};
export const luscherColorTest = (boxes,handleBoxClick,item_id,testTaken,clientName,resetLusherColor,downloadResult) => {
	let spanValue = "";
	if(loginType === "0"){
		spanValue = "You have already taken the Color Personality Test.";
	}else{
		spanValue =`${clientName} has already taken the Color Personality Test.`;
	}
	return (
		<>
			<Row 
				gutter={[48, 48]} 
				className = "border-2 border-solid border-black p-8"
			>
				{testTaken !== null?
					<>
						<Space>
							<span className="font-bold">{spanValue}</span>
							{loginType === "1" && <div><Button className='bg-sky-800 text-white' onClick={resetLusherColor}>Reset</Button></div>}
							{loginType === "1" && <div><Button className='bg-sky-800 text-white' onClick={downloadResult}>Download</Button></div>}
						</Space>
					</>
					:	boxes.map((box) => (
						<Col xs={24} md={12} lg={6} key={box.id}>
							{loginType === "1" ? 
								<div 
									className="h-36 w-40 rounded-md cursor-pointer m-auto" 
									style={{ 
										backgroundColor: box.color,
										display: box.visible ? "block" : "none"
									}}
								>
								</div>:
								<div 
									className="h-36 w-40 rounded-md cursor-pointer m-auto" 
									style={{ 
										backgroundColor: box.color,
										display: box.visible ? "block" : "none"
									}}
									onClick={() => handleBoxClick(box.id,box.color,item_id)}
								>
								</div>
							}
						</Col>
					))}
			</Row>
		</>
	);
};
export const uploadFile = (fileList, handleFileChange, handleRemove, beforeUpload,type) => {
	let label = null;
	let tt_title1;
	let type_file_accept = null;
	if(type === "upload-image"){
		label = "Upload Image";
		tt_title1 = "Valid filetype : JPG, JPEG, PNG.";
		type_file_accept = ".jpeg,.png,.jpg";
	}else if(type === "upload-image-signature"){
		label = "Upload Signature";
		tt_title1 = "Valid filetype : JPG, JPEG, PNG.";
		type_file_accept = ".jpeg,.png,.jpg";
	}else{
		if(type==="document"){
			label = "Upload Document";
			tt_title1 = "Valid file type : JPG, JPEG, PNG, PDF, DOC, DOCX.";
			type_file_accept=".pdf,.jpeg,.png,.jpg,.doc,.docx";
		}else{
			label = "Upload Document";
			tt_title1 = "Valid file type : JPG, JPEG, PNG, PDF.";
			type_file_accept=".pdf,.jpeg,.png,.jpg";
		}
	}
	return <Form.Item
		label={
			<span>
				{label}
			</span>
		}
	>
		<Space className="items-start">
			<Upload
				beforeUpload={beforeUpload}
				accept={type_file_accept}
				listType="picture"
				fileList={fileList}
				onChange={handleFileChange}
				onRemove={handleRemove}
				maxCount={1}
			>
				<Tooltip
					title={
						<div>
							<p>
								{tt_title1}
							</p>
						</div>
					}
				><Button className="bg-sky-800 text-white" icon={<UploadOutlined/>}>Click to Upload</Button></Tooltip>
			</Upload>
		</Space>
	</Form.Item >;
};

//currency dropdown with InputNumber
export const template23 = (label, inputValue) => {
	const formatNumber = (value, currency) => {
		if (!value) return '';
		if (currency === 'INR') {
			return `${value}`.replace(/(\d)(?=(\d\d)+\d(\.\d{0,2})?$)/g, '$1,');
		} else {
			return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
	};
	const parseNumber = (value) => {
		if (!value) return '';
		return value.replace(/,|\s/g, '');
	};

	let amount_split = inputValue ? inputValue.split(" ") : [];
	let currency = amount_split.slice(0, 2).join(" ");
	let amount = amount_split.slice(2).join(" ");
	if (amount) {
		const labelParts = label.split(':');
		if (labelParts.length === 2) {
			label = labelParts[0] + ':';
		}
	}
	return <div className='py-2 labelInput labelCenter' id="commit-to-pay">
		<Form.Item
			className="mb-0 customForm"
			label={<div dangerouslySetInnerHTML={{ __html: label }} />}
		>
			<InputNumber
				min={1}
				value={amount}
				className="w-6/12 inputNumber"
				readOnly={true}
				addonBefore={<span className="font-bold">{currency}</span>}
				formatter={(value) => formatNumber(value, currency.split(' ')[0])}
				parser={(value) => parseNumber(value)}
			/>
		</Form.Item>
	</div>;
};

//currency dropdown with Input
export const template27 = (label, inputValue) => {
	const formatNumber = (value, currency) => {
		if (!value) return '';
		if (currency === 'INR') {
			return `${value}`.replace(/(\d)(?=(\d\d)+\d(\.\d{0,2})?$)/g, '$1,');
		} else {
			return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
	};
	let amount_split = inputValue ? inputValue.split(" ") : [];
	let currency = amount_split.slice(0, 2).join(" ");
	let text = amount_split.slice(2).join(" ");
	const numberMatch = text.match(/\d+([.,]\d+)?/);
	let amount = numberMatch ? numberMatch[0] : '';
	let formattedAmount = formatNumber(amount, currency.split(' ')[0]);
	let formattedRest = text.replace(amount, formattedAmount);
	if (formattedRest) {
		const labelParts = label.split(':');
		if (labelParts.length === 2) {
			label = labelParts[0] + ':';
		}
	}
	return (
		<div className='py-2 labelInput labelCenter' id="commit-to-pay-text">
			<Form.Item
				className="mb-0 customForm"
				label={<div dangerouslySetInnerHTML={{ __html: label }} />}
			>
				<Input
					value={formattedRest}
					className="w-6/12 inputNumber"
					readOnly={true}
					addonBefore={<span className="font-bold">{currency}</span>}
				/>
			</Form.Item>
		</div>
	);
};

// Notes component present in Coaching agreement
export const template28 = (content_left) => {
	content_left = content_left?.replace(/\n/g, '<br />');
	return (
		<div className='py-2' id="commit-notes">
			<div className='text-cyan-600 font-bold' dangerouslySetInnerHTML={{ __html: content_left }} />
		</div>
	);
};

//date of birth component
export const DOB = (month,handleMonthChange,day,handleDayChange,year,handleYearChange,label,disable = null,validateStatus = null,handleEndYearChange,showEndYearChange = false, allowClear) => {
	const selectedHandler = showEndYearChange ? handleEndYearChange : handleYearChange;
	return <Form.Item
		label={label}
		className={label ==="Date of Birth" || label ===  "Valid Till" ? 'custom-form-label' : '' }
		validateStatus={validateStatus}
	>
		<Space>
			<Select value={month} onChange={handleMonthChange} className="!w-28" placeholder="Month" disabled={disable} allowClear={allowClear}>
				{[
					{ 
						label: "January",
						value: "01" 
					},
					{ 
						label: "February", 
						value: "02" 
					},
					{ 
						label: "March", 
						value: "03" 
					},
					{ 
						label: "April", 
						value: "04" 
					},
					{ 
						label: "May", 
						value: "05" 
					},
					{ 
						label: "June", 
						value: "06" 
					},
					{ 
						label: "July", 
						value: "07" 
					},
					{ 
						label: "August", 
						value: "08" 
					},
					{ 
						label: "September",
						value: "09" 
					},
					{ 
						label: "October", 
						value: "10" 
					},
					{ 
						label: "November", 
						value: "11"
					},
					{ 
						label: "December", 
						value: "12" 
					},
				].map((month) => (
					<Option key={month.value} value={month.value}>
						{month.label}
					</Option>
				))}
			</Select>
			<Select value={day} onChange={handleDayChange} className="!w-20" placeholder="Date" disabled={disable} allowClear={allowClear}>
				{Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
					<Option key={day} value={day}>
						{day}
					</Option>
				))}
			</Select>
			<Select value={year} onChange={selectedHandler} className="!w-28" placeholder="Year" disabled={disable} allowClear={allowClear}>
				{showEndYearChange ?
					Array.from({ length: 31 }, (_, i) => {
						const currentYear = new Date().getFullYear();
						const optionYear = currentYear + i;
						return (<Option key={optionYear} value={optionYear}>{optionYear}</Option>);
					})
					: Array.from({ length: 100 }, (_, i) =>
						new Date().getFullYear() - i).map((year) =>
							(<Option key={year} value={year}>{year}</Option>))}
			</Select>
		</Space>
	</Form.Item>;
};
//add notes model
export const notesModel = (hideModal, notesVisible, onchangeNoteValue, createNote, getForm, noteValue, noteTypeValue, disable, date, onSessionChange, sessionChecked, isSessionPresent, nextPreviousButton = null, isEdit = null) => {
	const noteValueRefs = noteValue.map(() => React.createRef());
	const notesHeader = [
		"DATA:",
		"ASSESSMENT:",
		"PLAN:"
	];
	const handleFocus = (index) => {
		const quill = noteValueRefs[index].current.getEditor();
		const selection = quill.getSelection();
		if (selection === null || selection.length === 0) {
			// No text selected, apply Header 3
			quill.format('header', 3);
		}
	};
	const initWidth = window.innerWidth <= 768 ? 330 : 800;
	return <ReactModal 
		initWidth={initWidth} 
		initHeight={500}
		disableKeystroke 
		className={"notes-modal"}
		onRequestClose={hideModal} 
		isOpen={notesVisible}
		disableResize
	>
		<div className='notes-header flex justify-between items-center'>
			<h3><strong>Notes</strong></h3>
			<div className='flex'>
				{isEdit ? <div>{nextPreviousButton()}</div> : null}
				<Button type='text' icon={<CloseOutlined/>} onClick={hideModal}/>
			</div>
		</div>
		<div className='flex justify-between items-center pl-3 py-2 pr-7'>
			<Radio.Group onChange={(e)=>getForm(e.target.value)} value={noteTypeValue}>
				<Radio disabled={disable === false ? false : noteTypeValue === "2" ? false : true} value={"2"}>Family Member</Radio>
				<Radio disabled={disable === false ? false : noteTypeValue === "1" ? false : true} value={"1"}>Addicts/Gamblers</Radio>
				<Radio disabled={disable === false ? false : noteTypeValue === "3" ? false : true} value={"3"}>Emotional</Radio>
				<Radio disabled={disable === false ? false : noteTypeValue === "0" ? false : true} value={"0"}>General</Radio>
			</Radio.Group>
			<Tooltip title="Replace the fill-in-the blank with your text and underline the same."><InfoCircleOutlined className='text-blue-500'/></Tooltip>
		</div>
		<div className="px-2 pb-2">
			<div className='h-80 overflow-y-auto'>
				<div><b className='text-sm'>Initial Assessment General</b></div>
				<div className='flex gap-5 flex-wrap'>
					{date}
					<Checkbox onChange={(e) => onSessionChange(e.target.checked)} className='mb-6' checked={sessionChecked} disabled={isSessionPresent}>Session</Checkbox>
				</div>
				{
					noteValue && noteValue.map((value,index) => {
						return <div>
							<div className='text-lg'><b><u>{notesHeader[index]}</u></b></div>
							<ReactQuill
								key={index}
								name={index}
								ref={noteValueRefs[index]}
								className='admin-notes mb-2'
								theme="snow"
								onChange={(val) => onchangeNoteValue(val, index)}
								value={value}
								placeholder="Write your note here"
								onFocus={() => handleFocus(index)}
								onKeyPress={() => handleFocus(index)}
							/></div>;
					})
				}
			</div>
			<div className='absolute left-0 bottom-0 pl-5 pb-4'>
				<Space>
					<Button className='bg-sky-800 text-white uppercase' onClick={hideModal}>{constants.Cancel}</Button>
					<Button className='bg-sky-800 text-white uppercase' onClick={()=>createNote("add")}>{constants.Save}</Button>
					<Popconfirm title="You will not be able to Edit after you Esign the Notes" onConfirm={() => createNote("esign")}>
						<Button className='bg-sky-800 text-white uppercase'>{constants.Esign}</Button>
					</Popconfirm>
				</Space>
			</div>
		</div>
	</ReactModal>;
};
export const video = (src) => {
	return (
		<video className='!w-full' controls>
			<source src= {src}type="video/mp4" />
			Your browser does not support the video tag.
		</video>
	);
};

export const welcomeEmailPopup = (isEmailChangeModalOpen,handleCancel,loader,emailchangeForm,onFinishEmailChange,onCheckboxChange,checkEmailPsdChange,layout2,showPasswordField) => {
	return (
		<Modal
			title={<div className="text-center">Welcome Email</div>}
			open={isEmailChangeModalOpen}
			footer={null}
			centered
			style={{ top: 20 }}
			width={700}
			className="client-details"
			onCancel={(e) => handleCancel(e, "emailchangepopup")}
		>
			<Spin spinning={loader} size="large">
				<Form
					labelWrap
					form={emailchangeForm}
					onFinish={onFinishEmailChange}
				>
					<Form.Item>
						<Checkbox checked={checkEmailPsdChange.email} onChange={(e) => onCheckboxChange(e, "email")}>
							Do you want us to send Welcome Email on this Email Address?
						</Checkbox>
					</Form.Item>
					{
						showPasswordField ===true ?
							<Form.Item>
								<Checkbox checked={checkEmailPsdChange.password} onChange={(e) => onCheckboxChange(e, "password")}>
									Do you want us to change the Password?
								</Checkbox>
							</Form.Item> :
							null
					}
					{
						checkEmailPsdChange.password === true ?
							<><Form.Item
								{...layout2}
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
								]}
							// hasFeedback
							>
								<Input.Password />
							</Form.Item>
								<Form.Item
									className='whitespace-nowrap	'
									{...layout2}
									name="confirm"
									label="Confirm Password"
									dependencies={['password']}
									// hasFeedback
									rules={[
										{
											required: true,
											message: 'Please confirm your password!',
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue('password') === value) {
													return Promise.resolve();
												}
												return Promise.reject(new Error('The new password that you entered do not match!'));
											},
										}),
									]}
								>
									<Input.Password />
								</Form.Item></> : null
					}
					<Form.Item className='mb-0'>
						<Space className="float-right">
							<Button
								size="middle"
								onClick={(e) => handleCancel(e, "emailchangepopup")}
							>
								{constants.CancelBTN}
							</Button>
							<Button
								size="middle"
								className='bg-sky-800 text-white'
								htmlType="submit"
							>
								{constants.Save}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
}

