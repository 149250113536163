/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { COOKIE, getCookie } from "../services/cookie";
/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export function RouteWithLayout({ layout, auth, component, ...rest }) {
	let clientId = getCookie(COOKIE.ClientId);
	let loginType = getCookie(COOKIE.LoginType);
	let queryString = window.location.href;
	let token = queryString.substring(queryString.lastIndexOf('/') + 1);
	let is_parent_concent = token.includes("parent-consent");
	let is_payment_agreement = token.includes("payment-agreement");
	return (
		<Route
			{...rest}
			render={(props) => {
				// If loginType is 3, restrict access to all routes except /image-list and /my-profile
				if (loginType === "3" && !["/image-list", "/my-profile"].includes(props.location.pathname)) {
					return <Redirect to="/image-list" />;
				}

				// Proceed with normal rendering for other users
				return clientId || is_parent_concent || is_payment_agreement ? (
					React.createElement(
						layout,
						{
							...props,
							...rest,
						},
						React.createElement(component, {
							...props,
							...rest,
						})
					)
				) : (
					<Redirect to="/" />
				);
			}}
		/>
	);
}