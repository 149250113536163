import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Form, Space, Select, Spin, Table, Tooltip, message, Input } from 'antd';
import { DownloadOutlined, DeleteOutlined, EyeOutlined, CheckOutlined, ShareAltOutlined, SearchOutlined, ReadOutlined, ReloadOutlined } from '@ant-design/icons';
import { fetchAdminApi } from '../../../services/api';
import { COOKIE, getCookie } from "../../../services/cookie";
import constants from '../../../constants/constants';
import moment from "moment";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DOB } from '../../../globalFunctions/GlobalFunctions';

const ClientNotes = () => {
	const history = useHistory();
	const loginType = getCookie(COOKIE.LoginType);
	const [pagination, setPagination] = useState(constants.paginationConfig);
	const [loader, setLoader] = useState(true);
	const [selectedType, setSelectedType] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [searchName, setSearchName] = useState("");
	const [date, setDate] = useState({
		fromDate: {
			date: null,
			month: null,
			year: null
		},
		toDate: {
			date: null,
			month: null,
			year: null
		}
	});
	const options = [
		{ value: "0", label: "General" },
		{ value: "1", label: "Addicts/Gamblers" },
		{ value: "2", label: "Family Member" },
		{ value: "3", label: "Emotional" },
	];
	const sortedOptions = options.slice().toSorted((a, b) => a.label.localeCompare(b.label));

	useEffect(() => {
		fetchNotes();
	}, [date.fromDate.date && date.fromDate.month && date.fromDate.year, date.toDate.date && date.toDate.month && date.toDate.year, searchName, selectedType]);

	const fetchNotes = (type = null) => {
		setLoader(true);
		let payload = {};
		if (loginType == 1) {
			payload = {};
		} else if (loginType == 2) {
			payload = { login_id: getCookie(COOKIE.ClientId) };
		}
		if (date.fromDate.date && date.fromDate.month && date.fromDate.year) {
			payload.start_date = `${date.fromDate.year}-${date.fromDate.month}-${date.fromDate.date}`
		}
		if (date.toDate.date && date.toDate.month && date.toDate.year) {
			payload.end_date = `${date.toDate.year}-${date.toDate.month}-${date.toDate.date}`
		}
		if (searchName) {
			payload.name = searchName
		}
		if (selectedType !== null) {
			payload.note_type = selectedType;
		}
		fetchAdminApi(`/view-notes`, "post", payload).then((res) => {
			if (res?.code && res.code === 200) {
				setTableData(res?.data.map(item => ({ ...item, key: item.notes_id })));
				setLoader(false);
			} else {
				setTableData([]);
				setLoader(false);
			}
		});
	};
	const downloadNotes = (notesValue) => {
		let notesPayload = {
			notes_id: notesValue?.notes_id,
			notes_assesment: 1
		};
		if (notesValue?.enquiry_id) {
			notesPayload.enquiry_id = notesValue.enquiry_id;
		} else {
			notesPayload.client_id = notesValue.client_id;
		}
		let documentValue = {
			file_type: "notes-pdf",
			notes_payload: notesPayload
		};
		sessionStorage.setItem("documentValue", JSON.stringify(documentValue));
		window.open(constants.BaseUrl + `/view-document`);
	};
	const handleDelete = (notes_id) => {
		fetchAdminApi(`/delete-note/${notes_id}`, "delete").then((res) => {
			if (res?.code === 200) {
				message.success(res?.message);
				fetchNotes("delete");
			}
			else {
				message.error(res?.message);
			}
		});
	}
	const viewNotes = (record) => {
		let payload;
		if (record?.enquiry_id) {
			payload = {
				enquiryId: record?.enquiry_id,
				client_serial_number: record?.enquiry_id,
				client_name: record?.name,
			};
		} else {
			payload = {
				clientId: record?.client_id,
				client_serial_number: record?.client_serial_number,
				client_name: record?.name,
			};
		}
		let noteViewValue = {
			noteView: 'bookView',
			noteId: record?.notes_id
		}
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		sessionStorage.setItem("noteViewValue", JSON.stringify(noteViewValue));
		window.open(constants.BaseUrl + "/notes");
		sessionStorage.removeItem("adminClientValue");
		sessionStorage.removeItem("noteViewValue");
	}
	const routeToEditClientEnquiry = (enquiry_id, client_id, record, enable = null) => {
		let pathname;
		let state;

		if (record?.enquiry_id) {
			if (record.status === "4" || record.status === "9") {
				sessionStorage.removeItem("enquiryDetails");
				pathname = "/edit-client";
				state = { client_id: record.client_id };
			} else {
				sessionStorage.setItem("enquiryDetails", JSON.stringify(record));
				pathname = "/edit-enquiry";
				state = { enquiry_id: enquiry_id };
			}
		} else {
			let payload = {};
			if (enable) {
				payload.client_id = client_id;
				payload.enable = true;
			} else {
				payload.client_id = client_id;
			}
			pathname = "/edit-client";
			state = payload;
		}

		history.push({
			pathname: pathname,
			state: state
		});
	};
	const getNameInitail = (fullName) => {
		let namesArray = fullName?.split(' ');
		let initialFN = namesArray?.[0]?.charAt(0);
		let initialLN = namesArray?.[1]?.charAt(0);
		let name = {
			firstname: initialFN,
			lastname: initialLN
		}
		return name;
	};
	const handleTypeChange = (value) => {
		setSelectedType(value);
	};
	const getTypeLabel = (record) => {
		const option = options.find(option => option.value === record);
		return option ? option.label : "";
	};
	const onSearch = (event) => {
		let search = event.target.value;
		setSearchName(search);
	};
	//from date onchange
	const handleDayChangeFD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			fromDate: {
				...prevDate.fromDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeFD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			fromDate: {
				...prevDate.fromDate,
				month: value,
			},
		}));
	};
	const handleYearChangeFD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			fromDate: {
				...prevDate.fromDate,
				year: value,
			},
		}));
	};
	//to date onchange
	const handleDayChangeTD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			toDate: {
				...prevDate.toDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeTD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			toDate: {
				...prevDate.toDate,
				month: value,
			},
		}));
	};
	const handleYearChangeTD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			toDate: {
				...prevDate.toDate,
				year: value,
			},
		}));
	};
	const columns = [
		{
			title: 'Action',
			align: "center",
			render: (_, record) => {
				return <Space>
					<Tooltip title={"Delete"}>
						<Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record?.notes_id)}>
							<Button
								type='text'
								icon={<DeleteOutlined />}
							/>
						</Popconfirm>
					</Tooltip>
					<Tooltip title={"Download"}><Button
						onClick={() => downloadNotes(record)}
						type='text'
						icon={<DownloadOutlined />}
					/></Tooltip>
					<Tooltip title={"View"}><Button
						onClick={() => viewNotes(record)}
						type='text'
						icon={<EyeOutlined />}
					/></Tooltip>
				</Space>;
			}
		},
		{
			title: 'Date',
			dataIndex: 'dt_created',
			key: 'dt_created',
			render: (date) => {
				return <div>
					<span>{moment(date).format(constants.dateformat)}</span>
				</div>;
			},
		},
		{
			title: 'E-sign',
			align: "center",
			render: (record) => {
				return (
					<div>
						{record?.signed_by ? (
							<Tooltip title={`Signed by ${record?.note_by_name}`}>
								<CheckOutlined />
							</Tooltip>
						) : (
							<span></span>
						)}
					</div>
				);
			},
		},
		{
			title: 'Client Name',
			dataIndex: 'name',
			key: 'firstname',
			render: (firstname, record) => {
				let associateFullName = record?.associate_name;
				let sharedWithFullName = record?.shared_name;
				let getAssosiateNameInitial = getNameInitail(associateFullName);
				let getSharedWithNameInitial = getNameInitail(sharedWithFullName);
				return firstname ? <Space>
					<a onClick={() => routeToEditClientEnquiry(record?.enquiry_id, record?.client_id, record)}>
						<Space>
							{loginType === "2" ? sharedWithFullName ? <ShareAltOutlined /> : <SearchOutlined /> : <SearchOutlined />}{firstname}
							{record?.is_refresher === 1 && <ReloadOutlined />}
						</Space>
					</a>
					<>
						{record?.status === "Active" && record?.section >= 1 ?
							<a onClick={() => history.push({
								pathname: "/dashboard",
								state: {
									isAdmin: true,
									clientId: record?.client_id,
									client_name: firstname,
									client_serial_number: record?.client_serial_number
								}
							})}><ReadOutlined />
							</a>
							: ""
						}
						{loginType === "1" && associateFullName && record?.associate_role !== "1" && (<Tooltip title={constants.AssignedTo + " " + associateFullName}><span>{"->" + " " + getAssosiateNameInitial.firstname + getAssosiateNameInitial.lastname}</span></Tooltip>)}
						{sharedWithFullName && (<Tooltip title={constants.SharedWith + " " + sharedWithFullName}><span>{">>" + " " + getSharedWithNameInitial.firstname + getSharedWithNameInitial.lastname}</span></Tooltip>)}
					</>
				</Space> : "";
			}
		},
		{
			title: 'Intervention',
			dataIndex: 'intervention',
			key: 'intervention',
			render: (intervention) => {
				return <span className="m-auto">{intervention}</span>;
			},
		},
		{
			title: 'Note Type',
			dataIndex: 'notes_type',
			key: 'notes_type',
			render: (_, record) => {
				return <a><span onClick={() => viewNotes(record)}>{getTypeLabel(record?.notes_type)}</span></a>
			},
		},
		{
			title: 'Section',
			dataIndex: 'section_id',
			key: 'section_id',
			render: (section) => {
				return <span className="text-right w-6 block m-auto">{section}</span>;
			}
		},
		{
			title: 'Entered By',
			dataIndex: 'note_by_name',
			key: 'note_by_name',
			render: (name) => {
				return <span className="m-auto">{name}</span>;
			}
		},
	];
	const onChangeTable = (paginate, sort, filter) => {
		setPagination(paginate);
	};

	return (
		<>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ClientNotes}</span>
			</div>
			<div className='container mx-auto mt-2'>
				<div className='mt-2 flex flex-col sm:flex-row flex-wrap xl:flex-nowrap justify-between gap-2 sm:items-center'>
					<Form.Item label="Search" className='!mb-0'>
						<Input className='w-full lg:w-52' size="middle" onChange={onSearch} />
					</Form.Item>
					<Form.Item label="Note Type" className='!mb-0'>
						<Select
							className='!w-full sm:!w-52'
							placeholder="Select Note type"
							value={selectedType}
							onChange={handleTypeChange}
							allowClear
						>
							{sortedOptions.map(option => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Space className='flex flex-col sm:flex-row items-start	'>
						<div className='dob-mb-0'>{DOB(date.fromDate.month, handleMonthChangeFD, date.fromDate.date, handleDayChangeFD, date.fromDate.year, handleYearChangeFD, "From", null, null, null, false, true)}</div>
						<div className='dob-mb-0'>{DOB(date.toDate.month, handleMonthChangeTD, date.toDate.date, handleDayChangeTD, date.toDate.year, handleYearChangeTD, "To", null, null, null, false, true)}</div>
					</Space>
				</div>
				<Spin spinning={loader} size="large">
					<Table
						dataSource={tableData}
						columns={columns}
						pagination={pagination}
						onChange={onChangeTable}
						bordered
						scroll={{ x: 900 }}
						locale={{ emptyText: 'No Records Found' }}
						className='mt-2'
						size="small"
						rowKey="notes_id"
					/>
				</Spin>
			</div>
		</>
	);
};

export default ClientNotes;
