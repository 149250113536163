import React, { useEffect, useState, useRef } from 'react';
import { COOKIE, getCookie } from '../../../services/cookie';
import { message, Spin } from 'antd';
import constants from '../../../constants/constants';

const VideoComponent = () => {
	const [loading, setLoading] = useState(true);
	let videoId = sessionStorage.getItem('video_id');
	useEffect(() => {
		fetchVideoStream();
  }, []);

	const fetchVideoStream = async () => {
		try {
			const response = await fetch(`${constants.AdminApiUrl}/video-stream/${videoId}`, {
				method:'get',
				headers: {
					'Content-Type': 'video/mp4',
					'Authorization': `Bearer ${getCookie(COOKIE.Token)}`,
				},
			});

			if (response.ok) {
				const blob = await response.blob();
        const video = document.getElementById('streamedVideo');
        video.src = URL.createObjectURL(blob);
        video.onloadeddata = () => {
          setLoading(false);
        };
        setLoading(false);
			} else {
				setLoading(false);
				message.error('Something went wrong');
			}
		} catch (error) {
			setLoading(false);
			message.error('Error fetching video:', error);
		}
	};

	return (
		<Spin spinning={loading} size="large">
			<div className='text-center mt-1 font-bold text-lg'>{constants.sec} {videoId}</div>
			<div className='my-3 flex justify-center items-center text-center'>
				<video id="streamedVideo" controls width="80%" className='border-solid border border-slate-700 p-3'>
					<source type="video/mp4" />
					Your video.
				</video>
			</div>
		</Spin>
		
	);
};

export default VideoComponent;
