import React, { useEffect, useState } from "react";
import { Input, Form, Row, Col, Image, Button, Space, message } from 'antd';
import constants from '../../../constants/constants';
import logo from '../../../logo-blue-black.svg';
import { useHistory } from "react-router";
import { fetchApi } from "../../../services/api";
const ResetPassword = () => {
	const [userToken,setUserToken]=useState("");
	const history = useHistory();
	const [form] = Form.useForm();
	useEffect(() => {
		fetchUrlParameters();
	}, []);
	const fetchUrlParameters=()=>{
		let queryString = window.location.href;
		let token = queryString.substring(queryString.lastIndexOf('?') + 1);
		setUserToken(token);
	};
	const onFinalSubmit=(values)=>{
		console.log("values",values);
		let payload=null;
		payload={
			password:values.password,
			confirm_password:values.confirmPassword,
			token:userToken,
			type:"client",
		};
		fetchApi("/client/reset-password", "post", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				message.success(res.message);
				history.push("/login");
			} else {
				message.error(res.message);
			}
		});
	};
	return (
		<div>
			<Row gutter={[0, 16]}>
				<Col xs={24} sm={24} lg={9} className="bg-amber-300 text-yellow-600 capitalize text-3xl font-semibold lg:min-h-screen">
					<div className='mx-2 md:mx-6 md:mt-8'>
						<Image src={logo} alt="RoyCares & Associates" preview={false}/><br/>
						<div className='pt-6'>
							{constants.AnyoneRecover}
						</div>
						<div>
							{constants.Tagline}
						</div>
					</div>
				</Col>
				<Col xs={24} sm={24} lg={15} className="lg:min-h-screen">
					<div className='mx-2 md:mx-5 md:mt-8'>
						<span className='text-2xl lg:text-3xl font-semibold'>{constants.ResetPsd}</span><br/><br/>
						<Form layout="vertical" className='label-style lg:w-96'form={form}  onFinish={onFinalSubmit}>
							<Form.Item
								name="password"
								label={constants.NewPsd}
								// validateStatus={passwordStatus}
								rules={[
									{
										required: true,
										message: " ",
									},
									// { validator: handlePasswordValidation },
								]}
								hasFeedback
								tooltip={constants.PsdTip}
							>
								<Input.Password placeholder={constants.NewPsd}/>
							</Form.Item>
							<Form.Item
								name="confirmPassword"
								label={constants.ConfirmNewPsd}
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: constants.ConfirmNewPsd,
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													constants.PasswordNotMatch
												)
											);
										},
									}),
								]}
							>
								<Input.Password placeholder={constants.ConfirmNewPsd}/>
							</Form.Item>
							<Space>
								<Form.Item>
									<Button size="large" className='bg-sky-800 text-white uppercase' onClick={()=>history.push("/login")}>
										{constants.Cancel}
									</Button>
								</Form.Item>
								<Form.Item>
									<Button size="large" htmlType="submit" className='bg-sky-800 text-white uppercase'>
										{constants.ChangePsd}
									</Button>
								</Form.Item>
							</Space>
						</Form>
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default ResetPassword;
