import React, { useEffect, useState } from 'react';
import { fetchAdminApi, fetchDownloadApi } from '../../../services/api';
import constants from '../../../constants/constants';
import { Button, Form, Input, Space, Spin, Table, Tooltip, message } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DOB } from '../../../globalFunctions/GlobalFunctions';

const PaymentStatement =()=> {

	const [tableData, setTableData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [date, setDate] = useState({
		fromDate:{
			date: null,
			month: null,
			year: null
		},
		toDate:{
			date: null,
			month: null,
			year: null
		}
	});
	const [searchName, setSearchName] = useState("");
	const [pagination, setPagination] = useState(constants.paginationConfig);
	const [tablePageConfig, setTablePageConfig] = useState({
		currentPage:1,
		pageSize:25
	});
	
	useEffect(() => {
		getPaymentStatement();
	}, [date.fromDate.date && date.fromDate.month && date.fromDate.year,date.toDate.date && date.toDate.month && date.toDate.year,searchName]);

	const formatNumber = (value, currency) => {
		if (currency === '1') { // INR
			return `${value}`.replace(/(\d)(?=(\d\d)+\d(\.\d{0,2})?$)/g, '$1,');
		} else if (currency === '0') { // USD
			return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
		return value;
	};

	const columns = [
		{
			title: '#',
			align: "center",
			dataIndex: 'serial_no',
			key: 'serial_no',
			width: "5%",
			render: (text, record, index) => (tablePageConfig.pageSize * (tablePageConfig.currentPage - 1)) + index + 1,
		},
		{
			title: 'Date',
			key: 'dt_payment_received',
			dataIndex: 'dt_payment_received',
			render:(dt_payment_received)=>{
				return <span>{dt_payment_received ? moment(dt_payment_received).format(constants.dateformat) : ""}</span>;
			}
		},
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: 'Program',
			key: 'program',
			dataIndex: 'program_type',
		},
		{
			title: 'Payment Details',
			key: 'payment_details',
			dataIndex: 'payment_details',
		},
		{
			title: 'Amount(₹)',
			key: 'amount',
			align: "center",
			width: "5%",
			render: (val) => (<span className="text-right w-15 block m-auto">{val?.currency === "1" ? formatNumber(val?.amount, val?.currency) : ""}</span>)
		},
		{
			title: 'Amount($)',
			key: 'amount',
			align: "center",
			width: "5%",
			render: (val) => (<span className="text-right w-15 block m-auto">{val?.currency === "0" ? formatNumber(val?.amount, val?.currency) : ""}</span>)
		},
		{
			title: 'Paid By',
			key: 'paid',
			dataIndex: 'paid',
		},
	];

	const downloadPayment = () => {
		let payload={};
		if(date.fromDate.date && date.fromDate.month && date.fromDate.year){
			payload.start_date = `${date.fromDate.year}-${date.fromDate.month}-${date.fromDate.date}`
		}
		if(date.toDate.date && date.toDate.month && date.toDate.year){
			payload.end_date = `${date.toDate.year}-${date.toDate.month}-${date.toDate.date}`
		}
		if(searchName){
			payload.name = searchName
		}
		fetchDownloadApi(`/export-payment`, "post", payload, constants.AdminApiUrl).then(
			(res) => {
				if(res){
					let url,a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application/vnd.ms-excel" }),
						{ type: "data:attachment/xls" }
					);
					let date= moment().format(constants.dateformat);
					let filename="Payment Statement("+date+").xls";
					a = document.createElement("a");
					a.download = filename;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
					setLoader(false);
				}else{
					message.error("Failed to download");
					setLoader(false);
				}
			}
		);
	};

	const getPaymentStatement = () => {
		setLoader(true);
		let payload={};
		if(date.fromDate.date && date.fromDate.month && date.fromDate.year){
			payload.start_date = `${date.fromDate.year}-${date.fromDate.month}-${date.fromDate.date}`
		}
		if(date.toDate.date && date.toDate.month && date.toDate.year){
			payload.end_date = `${date.toDate.year}-${date.toDate.month}-${date.toDate.date}`
		}
		if(searchName){
			payload.name = searchName
		}
		fetchAdminApi(`/view-payment-details`, "post",payload).then((res) => {
			if (res?.code===200) {
				setLoader(false);
				setTableData(res?.data);
			}else {
				setLoader(false);
				setTableData([]);
			}
		});
	};

	const onSearch = (event) => {
		let search = event.target.value;
		setSearchName(search);
	};

	//from date onchange
	const handleDayChangeFD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			fromDate: {
				...prevDate.fromDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeFD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			fromDate:{
				...prevDate.fromDate,
				month:value,
			},
		}));
	};
	const handleYearChangeFD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			fromDate:{
				...prevDate.fromDate,
				year:value,
			},
		}));
	};
	//to date onchange
	const handleDayChangeTD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			toDate: {
				...prevDate.toDate,
				date: value,
			},
		}));
	};
	const handleMonthChangeTD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			toDate:{
				...prevDate.toDate,
				month:value,
			},
		}));
	};
	const handleYearChangeTD = (value) => {
		setDate((prevDate) => ({
			...prevDate,
			toDate:{
				...prevDate.toDate,
				year:value,
			},
		}));
	};

	const onChangeTable = (paginate, sort, filter) => {
		setTablePageConfig({
			pageSize:paginate.pageSize,
			currentPage:paginate.current
		});
		setPagination(paginate);
	};
	
	return (
		<div>
			<div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
				<span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.PaymentStatement}</span>
			</div>
			<div className='container mx-auto'>
				<div className='mt-2 flex justify-between items-center'>
					<Form.Item label="Search" className='!mb-0'>
						<Input className='w-full lg:w-52' size="middle" onChange={onSearch} />
					</Form.Item>
					<Space>
						<div className='dob-mb-0'>{DOB(date.fromDate.month, handleMonthChangeFD, date.fromDate.date, handleDayChangeFD, date.fromDate.year, handleYearChangeFD, "From", null)}</div>
						<div className='dob-mb-0'>{DOB(date.toDate.month, handleMonthChangeTD, date.toDate.date, handleDayChangeTD, date.toDate.year, handleYearChangeTD, "To", null)}</div>
						<Tooltip title={constants.ExportToWord}><Button onClick={downloadPayment} className="float-right bg-sky-800 text-white" icon={<FileExcelOutlined />}>{constants.ExportToWord}</Button></Tooltip>
					</Space>
				</div>
				<Spin spinning={loader} size="large">
					<Table
						pagination={pagination}
						onChange={onChangeTable}
						dataSource={tableData}
						columns={columns}
						bordered
						locale={{ emptyText: 'No Records Found' }}
						className='mt-2'
						size="small"
						// summary={(pageData) => {
						// 	let rupeeTotal = 0;
						// 	let dolarTotal = 0;
						// 	pageData.forEach(({ amount,currency }) => { currency === "1" ?  rupeeTotal += Number(amount) : 0; });
						// 	pageData.forEach(({ amount,currency }) => { currency !== "1" ?  dolarTotal += Number(amount) : 0; });
						// 	return (
						// 		<>
						// 			<Table.Summary.Row className='sum-row'>
						// 				<Table.Summary.Cell index={0} align='center' className='font-semibold'>Total</Table.Summary.Cell>
						// 				<Table.Summary.Cell index={1}>
						// 				</Table.Summary.Cell>
						// 				<Table.Summary.Cell index={2}>
						// 				</Table.Summary.Cell>
						// 				<Table.Summary.Cell index={3}>
						// 				</Table.Summary.Cell>
						// 				<Table.Summary.Cell index={4}>
						// 				</Table.Summary.Cell>
						// 				<Table.Summary.Cell 
						// 					index={5}
						// 					width ="20%"
						// 					align ="center"
						// 				>
						// 					<span className="text-right w-20 block m-auto font-semibold">{rupeeTotal}</span>
						// 				</Table.Summary.Cell>
						// 				<Table.Summary.Cell 
						// 					index={6}
						// 					width ="20%"
						// 					align ="center"
						// 				>
						// 					<span className="text-right w-20 block m-auto font-semibold">{dolarTotal}</span>
						// 				</Table.Summary.Cell>
						// 				<Table.Summary.Cell index={7}>
						// 				</Table.Summary.Cell>
						// 			</Table.Summary.Row>
						// 		</>
						// 	);
						// }}
					/>
				</Spin>
			</div>
		</div>
	)
}

export default PaymentStatement