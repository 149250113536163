import React,{ useEffect,useState } from 'react';
import { message, Spin } from 'antd';
import { fetchApi } from '../../../services/api';
const ViewDocument = () => {
	const documentValue = JSON.parse(sessionStorage.getItem("documentValue"));
	const [documentUrl, setDocumentUrl] = useState("");
	const [loader, setLoader] = useState(false);
	useEffect(()=>{
		viewDocument();
		return () => {
			sessionStorage.removeItem("documentValue");
		};
	},[]);
	const viewDocument = ()=>{
		setLoader(true);
		let payload ={};
		if(documentValue?.file_type ==="result-pdf"){
			payload.client_id = documentValue?.client_id;
		}
		else if (documentValue?.file_type === "notes-pdf"){
			payload = documentValue?.notes_payload;
		}
		else if (documentValue?.file_type === "payment-pdf") {
			payload = documentValue?.payment_payload;
		}
		else{
			payload.doc_id = documentValue?.doc_id;
			payload.doc_name = documentValue?.doc_name;
		}
		fetchApi(`/view-document`, "post", payload).then((res) => {
			if (res && res.data) {
				setLoader(false);
				setDocumentUrl(res?.data);
			}
			else {
				setLoader(false);
				message.error(res.data.message);
			}
		});
	};
	return (
		<Spin spinning={loader} size="large">
			{
				documentValue ?
					documentValue?.file_type === "pdf" || documentValue?.file_type === "result-pdf" || documentValue?.file_type === "notes-pdf" || documentValue?.file_type === "payment-pdf" ? 
						<div className="iframe-container">
							<iframe src={documentUrl} title='title'></iframe>
						</div> : 
						<div className="min-h-screen grid place-content-center place-items-center">
							<img
								src={documentUrl}
								alt={'Report'}
								style={{ 
									maxWidth: '100%', 
									maxHeight: '100%' 
								}}
							/>
						</div>:
					<div></div>}
		</Spin>
	);
};
export default ViewDocument;